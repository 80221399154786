import React from "react";
import Explore from "../components/Explore/Explore";

const ExplorePage = () => {
	return (
		<div>
			<Explore />
		</div>
	);
};

export default ExplorePage;
