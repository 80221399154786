import { Col, Form, message, Row } from "antd";
import Input from "antd/es/input/Input";
import axios from "axios";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BIO } from "../../../../app/config";
import { setPartnerExpectationFromData } from "../../../../app/slices/multiForm";
import "./PartnerExpectation.css";

const { TextArea } = Input;

const partnerExpectationInitData = {
	partner_expectation: "",
	about_me: "",
};
const PartnerExpectation = () => {
	const [messageApi, contextHolder] = message.useMessage();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.auth);
	const { partnerExpectationFromData } = useSelector(
		(state) => state.multiForm
	);
	useEffect(() => {
		axios
			.get(BIO.GET_BIO_DATA, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				// dispatch(setEducationFormData(res.data?.education));
				if (res.data.partner_expectation) {
					dispatch(
						setPartnerExpectationFromData(res.data?.partner_expectation)
					);
				} else {
					dispatch(setPartnerExpectationFromData(partnerExpectationInitData));
				}
			})
			.catch((err) => {
				console.log(err);
				navigate("/login", { replace: true });
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleChangeData = (key, value) => {
		dispatch(
			setPartnerExpectationFromData({
				...partnerExpectationFromData,
				[key]: value,
			})
		);
	};

	//notification:::
	const notificationErrMgs = (data) => {
		messageApi.open({
			type: "error",
			content: data,
		});
	};
	// console.log(
	// 	partnerExpectationFromData?.partnerExpectation.split(" ").length <= 150
	// );
	return (
		<Fragment>
			{contextHolder}
			<Row gutter={[20, 5]} className="row partner_expectation">
				<Col md={12} sm={24} xs={24} className="col-md-6">
					<div className="expectation">
						<h5
							className="mb-4"
							style={{
								textAlign: "start",
								fontSize: "18px",
								color: "#c12860",
								margin: "0",
								padding: "0",
								marginBottom: "10px",
								paddingBottom: "15px",
								borderBottom: "1px dashed gray",
							}}
						>
							Partner Expectation :{" "}
							<small style={{ fontSize: "13px" }}>Maximum: 150 word</small>
						</h5>
						<Form.Item style={{ margin: "20px 0" }}>
							<TextArea
								// maxLength={
								// 	partnerExpectationFromData?.partnerExpectation.split(" ")
								// 		.length <= 150
								// }
								rows={8}
								placeholder="Tell us about your expectations from your better half ...!"
								value={partnerExpectationFromData?.partner_expectation}
								onChange={(e) => {
									e.target.value.split(" ").length <= 150
										? handleChangeData("partner_expectation", e.target.value)
										: notificationErrMgs("Content Length Maximum 150");
								}}
							/>
						</Form.Item>
					</div>
				</Col>
				<Col md={12} sm={24} xs={24} className="col-md-6">
					<div className="about_me">
						<h5
							className="mb-4"
							style={{
								textAlign: "start",
								fontSize: "18px",
								color: "#c12860",
								margin: "0",
								padding: "0",
								marginBottom: "10px",
								paddingBottom: "15px",
								borderBottom: "1px dashed gray",
							}}
						>
							About Me :{" "}
							<small style={{ fontSize: "13px" }}>Maximum: 150 word</small>
						</h5>
						<Form.Item style={{ margin: "20px 0" }}>
							<TextArea
								maxLength={(e) =>
									e.target.value.trim().split(" ").length <= 150
								}
								value={partnerExpectationFromData?.about_me}
								onChange={(e) => {
									e.target.value.split(" ").length <= 150
										? handleChangeData("about_me", e.target.value)
										: notificationErrMgs("Content Length Maximum 150");
								}}
								rows={8}
								placeholder="Write something about your self"
							/>
						</Form.Item>
					</div>
				</Col>
			</Row>
		</Fragment>
	);
};

export default PartnerExpectation;
