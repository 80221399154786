export const interests = [
	{
		id: 1,
		name: "Family Time",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 2,
		name: "Movies & Tv Shows",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 3,
		name: "Hanging Out With Friends",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 4,
		name: "Traveling",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 5,
		name: "Cooking",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 6,
		name: "Foodie",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 7,
		name: "Shopping",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 8,
		name: "Sports",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 9,
		name: "Social Media",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 10,
		name: "Home Decor",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 11,
		name: "Health & Fitness",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 12,
		name: "Gaming",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 13,
		name: "Music",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 14,
		name: "Reading Books",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 15,
		name: "Photography",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 16,
		name: "Nature Lover",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 17,
		name: "pet Lover",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 18,
		name: "Gardening",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 19,
		name: "Painting",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 20,
		name: "Religion Study",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
];

export const personalities = [
	{
		id: 1,
		name: "Easy Going",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 2,
		name: "Loyal",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 3,
		name: "Honest",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 4,
		name: "Responsible",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 5,
		name: "Intelligent",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 6,
		name: "Helpful",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 7,
		name: "Hard Working",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 8,
		name: "Ambitious",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 9,
		name: "Understanding",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 10,
		name: "Adaptable",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 11,
		name: "Romantic",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 12,
		name: "Patient",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 13,
		name: "Extrovert",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 14,
		name: "Introvert",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 15,
		name: "Optimistic",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 16,
		name: "Practical",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 17,
		name: "Humorous",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 18,
		name: "Disciplined",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 19,
		name: "Sincere",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 20,
		name: "Straight Forward",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 21,
		name: "Passionate",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
	{
		id: 22,
		name: "Religious",
		created_at: null,
		updated_at: null,
		deleted_at: null,
	},
];
