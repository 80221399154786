// export const BASE_URL = "https://ghotok.khelahobe.com.bd/api";
// export const BASE_URL = "https://api-beta.digitalghotok.com/api";
export const BASE_URL = "https://api.digitalghotok.com/api";
// export const BASE_URL = "http://3.108.214.176/api";
export const TUTORIAL_URL = "https://youtu.be/UruqEEBESCM";
export const AUTH = {
  REGISTER_API: `${BASE_URL}/auth/register`,
  LOGIN_API: `${BASE_URL}/auth/login`,
  GET_USER_API: `${BASE_URL}/me`,
  LOGOUT_API: `${BASE_URL}/auth/logout`,
  FORGET_PASSWORD: `${BASE_URL}/auth/forget-password`,
  RESET_PASSWORD: ` ${BASE_URL}/auth/reset-password`,
};
export const QUIZ = {
  POST_QUIZ: `${BASE_URL}/bio-data`,
};

export const BIO = {
  GET_BIO_DATA: `${BASE_URL}/get-biodata`,
  SET_PROFILE_PICTURE: `${BASE_URL}/set-profile-picture`,
  VERIFY: `${BASE_URL}/request-verify-email`,
};

export const MULTI_FORM = {
  POST_PERSONAL: `${BASE_URL}/bio-data-personal`,
  POST_EDUCATION: `${BASE_URL}/bio-data-education`,
  POST_PROFESSIONAL: `${BASE_URL}/bio-data-professional`,
  POST_INTEREST_PERSONALITY: `${BASE_URL}/interest-and-personalities`,
  POST_PARTNER_EXPECTATION: `${BASE_URL}/bio-data-partner`,
  POST_SIBLINGS: `${BASE_URL}/bio-data-siblings`,
  POST_RELATIVES: `${BASE_URL}/bio-data-relative`,
};

export const BIODATA_REQUEST = {
  CONNECTED_BIODATA_LIST: `${BASE_URL}/connected-to-me`,
  SEND_REQ_BIODATA_LIST: `${BASE_URL}/requested-by-me`,
  GET_REQ_BIODATA_LIST: `${BASE_URL}/requested-to-me`,
  SEND_REQUEST: `${BASE_URL}/send-bio-data-request`,
  GET_ANALYTICS_BIODATA: (params) =>
    `${BASE_URL}/connected-to-me?show_only=${params}`,
  ACCEPT_REQUEST: (requestId) =>
    `${BASE_URL}/bio-data-request/${requestId}/accept`,
  REJECT_REQUEST: (requestId) =>
    `${BASE_URL}/bio-data-request/${requestId}/reject`,
  CANCEL_SEND_REQUEST: (requestId) =>
    `${BASE_URL}/bio-data-request/${requestId}/cancel`,
};

export const PAYMENT = {
  SUBMIT_PAYMENT: `${BASE_URL}/payment`,
  PACKAGES: `${BASE_URL}/get-package-list`,
};

export const NOTFICATION = {
  GET_NOTIFICATION: `${BASE_URL}/get-notifications-list?offset=0&limit=10`,
  MAKE_NOTIFICATION_SEEN: (notification_id) =>
    `${BASE_URL}/make-notification-seen/${notification_id}`,
};
