import React from "react";
import TermsCondition from "../components/TermsCondition/TermsCondition";

const TermsConditionPage = () => {
	return (
		<section>
			<TermsCondition />
		</section>
	);
};

export default TermsConditionPage;
