import { Button, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "../components/Faq/Faq.css";

const ContactPage = () => {
  return (
    <Row align="top" justify="center" className="faq">
      <Col
        xl={12}
        lg={14}
        md={14}
        xs={22}
        className="container py-5"
        style={{ paddingTop: "100px" }}
      >
        <h1
          className="text-center text-white mt-5"
          style={{ textAlign: "center", color: "#fff", fontSize: "40px" }}
        >
          Contact Us
        </h1>
        <div
          style={{
            backgroundColor: "#fff",
            margin: "40px 0",
            borderRadius: "8px",
            padding: "15px 8px",
          }}
          className="collapse-root"
        >
          <div style={{ padding: "40px 0 10px 10px" }}>
            <Link to="/">
              <Button type="primary" size="large">
                Back to Home
              </Button>
            </Link>
          </div>
          <div style={{ padding: "20px 15px" }}>
            <h4 style={{ paddingBottom: "10px" }}>
              <span style={{ color: "#e2286d" }}>Email:</span>{" "}
              digitalghotok22@gmail.com
            </h4>
            <h4>
              <span style={{ color: "#e2286d" }}>Address:</span> Dhaka,
              Bangladesh
            </h4>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ContactPage;
