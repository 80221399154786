import React from "react";
import Dashboard from "../components/Dashboard/Dashboard";
import EditBioData from "../components/Dashboard/EditBioData";

const DashboardPage = () => {
	return (
		<div>
			{/*<Dashboard /> */}
			<EditBioData />
		</div>
	);
};

export default DashboardPage;
