import { Col, Form, Row, Select } from "antd";
import React, { Fragment, useState } from "react";
import "../Input/InputCommon.css";

export const ftList = [
	{ key: 3, value: 3 },
	{ key: 4, value: 4 },
	{ key: 5, value: 5 },
	{ key: 6, value: 6 },
	{ key: 7, value: 7 },
];
export const inchList = [
	{ key: 0, value: 0 },
	{ key: 1, value: 1 },
	{ key: 2, value: 2 },
	{ key: 3, value: 3 },
	{ key: 4, value: 4 },
	{ key: 5, value: 5 },
	{ key: 6, value: 6 },
	{ key: 7, value: 7 },
	{ key: 8, value: 8 },
	{ key: 9, value: 9 },
	{ key: 10, value: 10 },
	{ key: 11, value: 11 },
];
const HeightInput = ({
	isDisabled=false,
	nameOne,
	nameTwo,
	valOne,
	valTwo,
	label,
	type,
	levelIcon,
	handleChangeFunc,
}) => {
	const [focus, setFocus] = useState(false);
	const labelClass =
		focus || (valOne && valOne.length !== 0) ? "label label-float" : "label";
	return (
		<Form.Item
			required
			name={nameOne}
			className="input-wrapper"
			onBlur={() => setFocus(false)}
			onFocus={() => setFocus(true)}
			style={{ width: "100%", margin: "15px auto" }}
		>
			<Fragment>
				<Row gutter={[30, 0]}>
					<Col span={12}>
						<Select
							disabled={isDisabled}
							showSearch
							placeholder="Feet"
							optionFilterProp="children"
							className="select-root"
							filterOption={(input, option) =>
								(option?.value ?? "").includes(input)
							}
							// filterSort={(optionA, optionB) =>
							// 	(optionA?.value ?? "")
							// 		?.toLowerCase()
							// 		?.localeCompare((optionB?.value ?? "")?.toLowerCase())
							// }
							onChange={(val) => handleChangeFunc(nameOne, val)}
							size="large"
							value={valOne}
							// style={{ width: 200 }}
							options={ftList.map((item) => {
								return {
									key: item.key.toString(),
									value: item.value.toString(),
								};
							})}
						/>
					</Col>
					<Col span={12}>
						<Select
							disabled={isDisabled}
							showSearch
							placeholder="Inch"
							optionFilterProp="children"
							className="select-root"
							filterOption={(input, option) =>
								(option?.value ?? "").includes(input)
							}
							// filterSort={(optionA, optionB) =>
							// 	(optionA?.value ?? "")
							// 		.toLowerCase()
							// 		.localeCompare((optionB?.value ?? "").toLowerCase())
							// }
							onChange={(val) => handleChangeFunc(nameTwo, val)}
							size="large"
							value={valTwo}
							// style={{ width: 200 }}
							options={inchList.map((item) => {
								return {
									key: item.key.toString(),
									value: item.value.toString(),
								};
							})}
						/>
					</Col>
				</Row>
				<label htmlFor={nameOne} className="input-label">
					<label
						className={labelClass}
						style={{
							fontSize: "17px",
							color: valOne && valTwo ? "rgb(117, 117, 117" : "#ea6689",
						}}
					>
						{label} {levelIcon}
					</label>
				</label>
			</Fragment>
		</Form.Item>
	);
};

export default HeightInput;
