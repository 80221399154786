import React from "react";
import "./PaymentSuccess.css";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import { BsFillCheckCircleFill } from "react-icons/bs";

const PaymentSuccess = () => {
  return (
    <div className="payment-full">
      <Row
        align="middle"
        justify="center"
        style={{
          height: "100vh",
          // margin: "auto",
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
          // flexDirection: "column",
        }}
      >
        <Col xxl={6} xl={8} lg={10} md={14} sm={18} xs={20}>
          <div
            style={{
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: "40px 20px",
              borderRadius: "12px",
              marginLeft: "15px",
              marginRight: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <BsFillCheckCircleFill
                style={{ color: "#e1136e", fontSize: "30px" }}
              />
              <h2 style={{ padding: "20px 0", color: "#e1136e" }}>
                Payment Successful
              </h2>
              <Link to="/biodata/explore">Back to Explore</Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PaymentSuccess;
