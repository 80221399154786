import React from "react";
import "./Footer.css";
import footerImg from "../../assets/footer/footer_img.png";
import imgPaymentMethod from "../../assets/footer/FOOTER-Updated-01.png";
import { Link } from "react-router-dom";
import { AiFillFacebook, AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { Col, Row } from "antd";

const Footer = () => {
  return (
    <section className="footer">
      <div className="footer_img">
        <img
          className="img-fluid"
          src={footerImg}
          alt=""
          style={{ maxWidth: "100%", height: "auto", width: "100%" }}
        />
      </div>
      <div className="footer_area bg-primary">
        <Row>
          <Col
            xl={18}
            lg={20}
            xs={24}
            style={{ margin: "0 auto" }}
            className="container-fluid footer_content"
          >
            <Row className="row" gutter={[10, 25]}>
              <Col md={10} xs={24} className="col-md-4">
                <h5
                  className="fw-bold"
                  style={{ fontSize: "22px", paddingBottom: "5px" }}
                >
                  About Us
                </h5>
                <div className="mt-3 about_text" style={{ width: "100%" }}>
                  <small className="" style={{ fontSize: "16px" }}>
                    Bangladesh’s first AI-Based Matchmaking & Matrimony platform
                    based on candidates’ personal choices, preferences and more.
                  </small>
                </div>
                <div style={{ paddingTop: "20px" }}>
                  <Link to="/faq" className="text-decoration-none text-white">
                    {" "}
                    <p style={{ color: "#fff", fontSize: "16px" }}>
                      {" "}
                      Frequently asked questions (FAQs){" "}
                    </p>
                  </Link>
                </div>
                <div style={{ padding: "15px 0" }}>
                  <Link
                    to="/pricing"
                    className="text-decoration-none text-white"
                  >
                    {" "}
                    <p style={{ color: "#fff", fontSize: "16px" }}>Pricing</p>
                  </Link>
                </div>
                <div>
                  <Link
                    to="/refund-policy"
                    className="text-decoration-none text-white"
                  >
                    {" "}
                    <p style={{ color: "#fff", fontSize: "16px" }}>
                      Refund Policy
                    </p>
                  </Link>
                </div>
                <div style={{ paddingTop: "15px" }}>
                  <Link
                    to="/contact"
                    className="text-decoration-none text-white"
                  >
                    {" "}
                    <p style={{ color: "#fff", fontSize: "16px" }}>
                      Contact Us
                    </p>
                  </Link>
                </div>
              </Col>
              <Col
                md={4}
                xs={24}
                className="col-md-4"
                // style={{ paddingTop: "5px" }}
              >
                {/* <Link to="/faq" className="text-decoration-none text-white">
                {" "}
                <p style={{ color: "#fff", fontSize: "16px" }}>
                  {" "}
                  Frequently asked questions (FAQs){" "}
                </p>
              </Link> */}
              </Col>
              <Col
                md={10}
                xs={24}
                className="col-md-4"
                style={{ paddingTop: "5px" }}
              >
                <p> Email : digitalghotok22@gmail.com</p>
                <div
                  className="mt-3 d-flex"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    paddingTop: "20px",
                    fontSize: "30px",
                    color: "#fff",
                  }}
                >
                  <a
                    href="https://www.facebook.com/digitalghotok.com.bd"
                    rel="noreferrer"
                    target="_blank"
                    className=""
                    style={{ color: "#fff" }}
                  >
                    <AiFillFacebook />
                  </a>
                  <a
                    href="https://www.instagram.com/digital_ghotok/"
                    rel="noreferrer"
                    target="_blank"
                    className="mx-3"
                    style={{ color: "#fff" }}
                  >
                    <AiFillInstagram />
                  </a>
                  <Link to="/" className="" style={{ color: "#fff" }}>
                    <AiFillYoutube />
                  </Link>
                </div>
                <div>
                  <p>Dhaka, Bangladesh</p>
                </div>
                <div style={{ padding: "50px 0" }}>
                  <img
                    src={imgPaymentMethod}
                    alt="Payments"
                    style={{ width: "100%" }}
                  />
                </div>
                <div
                  style={{
                    // paddingTop: "100px",
                    textAlign: "left",
                    paddingBottom: "10px",
                  }}
                >
                  <p style={{ color: "#f8f8f8" }}>
                    Copyrights &copy; {new Date().getFullYear()} Digital Ghotok.
                    All rights reserved.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          {/* <Row className="row">
          <Col>
            <p>Copyright</p>
          </Col>
        </Row> */}
        </Row>
        <Row>
          <Col xl={18} lg={20} xs={24} style={{ margin: "0 auto" }}>
            {/* <div
              style={{
                paddingTop: "100px",
                textAlign: "right",
                paddingBottom: "10px",
              }}
            >
              <p style={{ color: "#f8f8f8" }}>
                Copyrights &copy; {new Date().getFullYear()} Digital Ghotok. All
                rights reserved.
              </p>
            </div> */}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Footer;
