import { Button } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

const OptionBtn = ({ value, activeValue, disPatchAction }) => {
	const dispatch = useDispatch();
	return (
		<Button
			size="large"
			onClick={() => {
				dispatch(disPatchAction(value));
			}}
			style={{
				maxWidth: "590px",
				width: "100%",
				display: "flex",
				justifyContent: "start",
				color:
					value?.toLowerCase() === activeValue?.toLowerCase()
						? "#fff"
						: "#4f4f4f",
				backgroundColor:
					value?.toLowerCase() === activeValue?.toLowerCase()
						? "#E22B6D"
						: "#fff",
				// outline: "1px solid #4f4f4f",
				fontSize: "21px",
				lineHeight: "26px",
				padding: "15px",
				height: "auto",
				// border: "1px solid white !important",

				textTransform: "capitalize",
				"&:hover": {
					backgroundColor: "#dd5d8a",
					color: "#fff",
					outline: "1px solid #E22B6D",
				},
			}}
		>
			{value}
		</Button>
	);
};

export default OptionBtn;
