import axios from "axios";
import { AUTH } from "../config";

const UseAuthHeader = (token) => {
	return {
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	};
};

const register = (name, email, password) => {
	return axios.post(AUTH.REGISTER_API, { name, email, password });
};

const login = (email, password) => {
	return axios
		.post(AUTH.LOGIN_API, { email, password })
		.then((response) => {
			// console.log(response);
			return response.data;
		})
		.catch((err) => {
			return err.response.data;
		});
};

const logout = () => {
	return axios.post(AUTH.LOGOUT_API).then((response) => {
		return response.data;
	});
};

const getCurrentUser = (token) => {
	return axios.get(AUTH.GET_USER_API, UseAuthHeader(token)).then((response) => {
		return response.data;
	});
};

const AuthService = {
	register,
	login,
	logout,
	getCurrentUser,
};

export default AuthService;
