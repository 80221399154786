import { Form, Input, message } from "antd";
import React, { Fragment, useState } from "react";
import "./InputCommon.css";
import axios from "axios";
import { BIO } from "../../../../app/config";
import { useSelector } from "react-redux";
const InputCommon = ({
  name,
  value,
  label,
  parentName,
  type,
  levelIcon,
  handleChangeFunc,
  isDisabled = false,
  isRequired = true,
  maxLength,
  isEmailVerified = true,
}) => {
  const [focus, setFocus] = useState(false);
  const [verifyClicked, setVerifyClicked] = useState(false);
  const [verifyMessage, setVerifyMessage] = useState("");
  const [verifyLoading, setVerifyLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const labelClass =
    focus || (value && value.length !== 0) ? "label label-float" : "label";

  const handleVerifyEmail = (e) => {
    e.preventDefault();
    setVerifyLoading(true);
    axios(`${BIO.VERIFY}`, {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        // console.log(res.data);
        setVerifyLoading(false);
        setVerifyClicked(true);
        setVerifyMessage(res.data.message);
        message.success(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Form.Item
      name={name}
      className="input-wrapper"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      style={{ width: "100%", margin: "15px auto" }}
    >
      <Fragment>
        <Input
          style={{ background: "transparent" }}
          name={name}
          className="input-field"
          type={type}
          onChange={(e) => handleChangeFunc(name, e.target.value, parentName)}
          value={value}
          // required={parentName !== "post_graduation" || }
          disabled={isDisabled}
          required={isRequired}
          maxLength={maxLength}
        />
        <label htmlFor={name} className="input-label">
          <label
            className={labelClass}
            style={{ color: value ? "rgb(117, 117, 117" : "#ea6689" }}
          >
            {label} {levelIcon}
            {!isEmailVerified && name === "email" && !verifyClicked && (
              <button
                style={{
                  padding: "3px 6px",
                  borderRadius: "6px",
                  border: "none",
                  backgroundColor: "#E2286D",
                  color: "#fff",
                  fontWeight: "bold",
                  marginLeft: "20px",
                  cursor: "pointer",
                  pointerEvents: "all",
                }}
                onClick={handleVerifyEmail}
              >
                Verify
              </button>
            )}
          </label>
        </label>
      </Fragment>
    </Form.Item>
  );
};

export default InputCommon;
