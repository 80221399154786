import { DatePicker, Form } from "antd";
import dayjs from "dayjs";
import React, { Fragment, useState } from "react";
import "./YearPickerCommon.css";

const YearPickerCommon = ({
	name,
	value,
	label,
	handleChangeFunc,
	parentName,
	isDisabled=false
}) => {
	const [focus, setFocus] = useState(false);
	const labelClass =
		focus || (value && value.length !== 0) ? "label label-float" : "label";

	// console.log(value);

	const onChange = (date, dateString) => {
		// console.log(dateString);
		if (dateString.length > 3) {
			// console.log({ dateString });
			handleChangeFunc(name, dateString, parentName);
		}
	};
	return (
		<Form.Item
			name={name}
			className="input-wrapper"
			onBlur={() => setFocus(false)}
			onFocus={() => setFocus(true)}
			style={{ width: "100%", margin: "15px auto" }}
		>
			<Fragment>
				<DatePicker
					disabled={isDisabled}
					className="date-root"
					picker="year"
					variant="outlined"
					bordered={false}
					format="YYYY"
					onChange={onChange}
					// defaultValue={(dayjs(value), "YYYY")}
					value={value ? dayjs(value + "", "YYYY") : null}
				/>
				{/*<input type="number" placeholder="YYYY" min="1999" max="2020"></input> */}
				<label htmlFor={name} className="input-label">
					<label
						className={labelClass}
						style={{
							fontSize: "17px",
							color: value ? "rgb(117, 117, 117" : "#ea6689",
						}}
					>
						{label}
					</label>
				</label>
			</Fragment>
		</Form.Item>
	);
};

export default YearPickerCommon;
