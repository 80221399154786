import { Button, Form, Input, notification } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AUTH } from "../../app/config";
import { setLogin } from "../../app/slices/auth";
import "./Register.css";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const { user } = useSelector((state) => state.auth);

  // const redirectDashboard = (user) => {
  // 	if (user !== null) {
  // 		axios
  // 			.get(BIO.GET_BIO_DATA, {
  // 				headers: {
  // 					Authorization: `Bearer ${user?.access_token}`,
  // 					"Content-Type": "application/json",
  // 				},
  // 			})
  // 			.then((response) => {
  // 				// dispatch(setBioDataDetails(response.data));
  // 				navigate("/dashboard/explore", { replace: true });
  // 			})
  // 			.catch((err) => {
  // 				navigate("/login", { replace: true });
  // 				console.log(err);
  // 			});
  // 	}
  // };

  // useEffect(() => {
  // 	user?.access_token && redirectDashboard(user);
  // 	// eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);

  //notification:::
  const openNotification = (data) => {
    api.error({
      message: `${data}`,
      // description: <h2>description</h2>,
      placement: "topRight",
    });
  };
  const onFinish = async (values) => {
    try {
      axios
        .post(AUTH.REGISTER_API, { ...values, name: "User Name" })
        .then((res) => {
          console.log(res);
          axios.post(AUTH.LOGIN_API, values).then((result) => {
            console.log(result);
            dispatch(setLogin({ user: result.data }));
            if (res.data.status_code === 200) {
              navigate("/biodata/quiz", { replace: true });
            } else {
              console.log(res);
              navigate("/register", { replace: true });
            }
          });
        })
        .catch((err) => {
          const errMgs = err.response.data.message;
          Object.values(errMgs).map((errItem) => openNotification(errItem[0]));
          console.log(errMgs);
          navigate("/register", { replace: true });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div style={{ margin: "0 auto" }}>
      {contextHolder}
      <h2
        className="register-heading"
        style={{ marginBottom: "30px", lineHeight: "40px" }}
      >
        Create Your Marriage Profile
      </h2>

      <Form
        name="basic"
        className="register_form"
        // labelCol={{
        // 	span: 8,
        // }}
        wrapperCol={{
          span: 24,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="email"
          style={{ marginBottom: "30px" }}
          rules={[
            {
              required: true,
              message: "Please input your Email",
            },
            {
              type: "email",
              message:
                "Provide a valid email since you need to verify with you email",
            },
          ]}
        >
          <Input
            type="email"
            placeholder="Email"
            className="input"
            // onClick={() => setShowEmailHelpText(true)}
            // onBlur={() => setShowEmailHelpText(false)}
            style={{ background: "#ffdde1" }}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          style={{ marginBottom: "30px" }}
        >
          <Input.Password placeholder="Password" className=" input" />
        </Form.Item>

        <Form.Item
          // wrapperCol={{
          // 	offset: 8,
          // 	span: 16,
          // }}
          style={{ textAlign: "center" }}
        >
          <Button
            type="primary"
            htmlType="submit"
            className="submit-btn"
            style={{ backgroundColor: "#4096ff" }}
          >
            REGISTER
          </Button>
        </Form.Item>
      </Form>

      <p style={{ margin: "0", paddingBottom: "5px" }}>
        By clicking register , I agree to the{" "}
        <Link to="/terms" style={{ color: "#f7b4bc" }}>
          T&C and Privacy Policy
        </Link>
      </p>
      <p style={{ margin: "0", paddingBottom: "20px" }}>
        Already Signed In?{" "}
        <Link to="/login" style={{ color: "#f7b4bc" }}>
          Login
        </Link>
      </p>
    </div>
  );
};

export default Register;
