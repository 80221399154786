import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import authReducer from "./slices/auth.js";
import messageReducer from "./slices/message.js";
import quizReducer from "./slices/quiz.js";
import multiFormReducer from "./slices/multiForm.js";
import uploadReducer from "./slices/upload.js";
import activeFormReducer from "./slices/activeFormTab";

const persistConfig = {
	key: "root",
	storage,
	blacklist: ["quiz"],
};

const reducers = combineReducers({
	auth: authReducer,
	message: messageReducer,
	multiForm: multiFormReducer,
	quiz: quizReducer,
	upload: uploadReducer,
	activeFormTab: activeFormReducer,
	// workflow: workflowReducer,
	// trigger: triggerReducer,
	// action: actionReducer,
	// loading: loadingReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== "production",
	middleware: [thunk],
});

export const persistor = persistStore(store);
