import React from "react";
import Preview from "../components/Preview/Preview";

const PreviewPage = () => {
	return (
		<section>
			<Preview />
		</section>
	);
};

export default PreviewPage;
