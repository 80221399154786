import React from "react";
import PaymentFailed from "../components/Payment/PaymentFailed";

const PaymentFailedPage = () => {
  return (
    <div>
      <PaymentFailed />
    </div>
  );
};

export default PaymentFailedPage;
