import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	bioDataDetails: null,
	personalFormData: null,
	educationFormData: {},
	professionalFormData: null,
	interestPersonalityFormData: null,
	partnerExpectationFromData: null,
	siblingFormData: null,
	relativesFormData: null,
	uploadFormPhotos: null,
};

const multiFormSlice = createSlice({
	name: "multiForm",
	initialState,
	reducers: {
		setClearMultiForm: (state, action) => {
			state.bioDataDetails = null;
			state.personalFormData = null;
			state.educationFormData = {};
			state.professionalFormData = null;
			state.interestPersonalityFormData = null;
			state.partnerExpectationFromData = null;
			state.siblingFormData = null;
			state.relativesFormData = null;
			state.uploadFormPhotos = null;
		},
		setBioDataDetails: (state, action) => {
			state.bioDataDetails = action.payload;
		},
		setPersonalFormData: (state, action) => {
			state.personalFormData = action.payload;
		},
		setEducationFormData: (state, action) => {
			state.educationFormData = action.payload;
		},
		setProfessionalFormData: (state, action) => {
			state.professionalFormData = action.payload;
		},
		setInterestPersonalityFormData: (state, action) => {
			state.interestPersonalityFormData = action.payload;
		},
		setPartnerExpectationFromData: (state, action) => {
			state.partnerExpectationFromData = action.payload;
		},
		setSiblingFormData: (state, action) => {
			state.siblingFormData = action.payload;
		},

		setRelativesFormData: (state, action) => {
			state.relativesFormData = action.payload;
		},
		setUploadFormPhotos: (state, action) => {
			state.uploadFormPhotos = action.payload;
		},
	},
});

const { reducer, actions } = multiFormSlice;

export const {
	setBioDataDetails,
	setPersonalFormData,
	setEducationFormData,
	setProfessionalFormData,
	setInterestPersonalityFormData,
	setPartnerExpectationFromData,
	setSiblingFormData,
	setRelativesFormData,
	setUploadFormPhotos,
	setClearMultiForm,
} = actions;
export default reducer;
