import React from "react";
import DraftQuiz from "../components/DraftQuiz/DraftQuiz";

const QuizPage = () => {
	return (
		<div>
			<DraftQuiz />
		</div>
	);
};

export default QuizPage;
