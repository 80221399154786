import { Col, Form, Input, Row, Select } from "antd";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { BASE_URL } from "../../../../app/config";
import { countryCodeList } from "../../../../utils/countries";
import "../Input/InputCommon.css";
import "./style.css";
const { Option } = Select;
const PhoneInput = ({
	name,
	value,
	label,
	phoneCode,
	type,
	handleChangeFunc,
	isDisabled=false
}) => {
	const [focus, setFocus] = useState(false);
	const labelClass =
		focus || (value && value.length !== 0) ? "label label-float" : "label";

	// const [flagList, setFlagList] = useState([]);
	// const [countriesData, setCountriesData] = useState([]);
	// useEffect(() => {
	// 	axios(`${BASE_URL}/countries`)
	// 		.then((res) => setCountriesData(res.data))
	// 		.catch((err) => console.log(err));
	// }, []);

	// const [fullInput, setFullInput] = useState(null);
	const handleChange = (val) => {
		// (val) => handleChangeFunc(name, val)
		console.log(val);
		handleChangeFunc("mobile_code", val);
	};

	// const prefixSelector = (
	// 	<Form.Item name="prefix" noStyle>
	// 		<Select
	// 			style={{
	// 				width: 100,
	// 			}}
	// 			defaultValue={phoneCode}
	// 			onChange={handleChange}
	// 			// defaultValue={
	// 			// 	flagList.filter((flagItem) => flagItem.code === "BD")[0].image
	// 			// }
	// 		>
	// 			{countriesData.map((country, index) => (
	// 				<Option
	// 					key={index}
	// 					value={country.dial_code}
	// 					selected={country.dial_code === phoneCode}
	// 					style={{
	// 						display: "flex",
	// 						alignItems: "center",
	// 						justifyContent: "space-between",
	// 						// width: "400px",
	// 						gap: "2px",
	// 					}}
	// 				>
	// 					{<img src={country.flag} alt="img" width={15} />}
	// 					<small>{country.dial_code}</small>
	// 				</Option>
	// 			))}
	// 		</Select>
	// 	</Form.Item>
	// );

	return (
		<Form.Item
			required
			name={name}
			className="input-wrapper"
			onBlur={() => setFocus(false)}
			onFocus={() => setFocus(true)}
			style={{ width: "100%", margin: "15px auto" }}
		>
			{/*<Fragment>
				<Input
					size="large"
					// addonBefore={prefixSelector}
					name={name}
					className="input-fields"
					type="text"
					onChange={(e) => handleChangeFunc(name, e.target.value)}
					value={value}
					// disabled={fullInput === null}
				/>
				<label htmlFor={name} className="input-label">
					<label className={labelClass} style={{ fontSize: "17px" }}>
						{label} {levelIcon}
					</label>
				</label>
			</Fragment> */}
			<Fragment>
				<Row gutter={[10, 0]}>
					{/*<Col span={8}>
						<Select
							showSearch
							placeholder="Code"
							optionFilterProp="children"
							className="select-root select-root-phone"
							filterOption={(input, option) =>
								(option?.value?.toLowerCase() ?? "").includes(input)
							}
							onChange={(val) => handleChangeFunc("mobile_code", val)}
							size="large"
							value={phoneCode}
						>
							{countriesData.map((country, index) => (
								<Option
									key={index}
									value={`${country?.code} ${country?.dial_code}`}
									selected={country.dial_code === phoneCode}
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										// width: "400px",
										gap: "5px",
									}}
								>
									{<img src={country?.flag} alt="img" width={20} />}
									<small>{country?.dial_code}</small>
								</Option>
							))}
						</Select>
					</Col> */}
					<Col span={24}>
						<Input
						style={{backgroundColor: "transparent"}}
						disabled={isDisabled}
							type="number"
							className="input-field"
							value={value}
							onChange={(e) => handleChangeFunc(name, e.target.value, name)}
						/>
					</Col>
				</Row>
				<label htmlFor={name} className="input-label">
					<label
						className={labelClass}
						style={{
							fontSize: "17px",
							color: value ? "rgb(117, 117, 117" : "#ea6689",
							// color: value && phoneCode ? "rgb(117, 117, 117" : "#ea6689",
						}}
					>
						{label}
					</label>
				</label>
			</Fragment>
		</Form.Item>
	);
};

export default PhoneInput;
