// import {
// 	Box,
// 	Button,
// 	Container,
// 	CssBaseline,
// 	IconButton,
// 	Input,
// 	List,
// 	ListItem,
// 	Typography,
// } from "@mui/material";
// import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
// import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./DraftQuiz.css";
import {
  setAge,
  setClearQuiz,
  setDateOfBirth,
  setFullName,
  setGender,
  setMangedBy,
  setQuizStep,
  setRegisterFrom,
} from "../../app/slices/quiz";
import bioDataService from "../../app/services/bioDataService";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Input,
  List,
  message,
  Row,
  Typography,
  Upload,
} from "antd";
import OptionBtn from "./OptionBtn";
import dayjs from "dayjs";
import axios from "axios";
import { BASE_URL } from "../../app/config";
import { setClearAuth } from "../../app/slices/auth";
import {
  setBioDataDetails,
  setClearMultiForm,
} from "../../app/slices/multiForm";
import { setActiveTab } from "../../app/slices/activeFormTab";
import InputCommon from "../Dashboard/Common/Input/InputCommon";
import HeightInput from "../Dashboard/Common/HeightInput/HeightInput";
import SelectCommon from "../Dashboard/Common/Select/SelectCommon";
import districtList from "../../utils/districts";
import { countryCodeList } from "../../utils/countries";
import { interests, personalities } from "../../utils/interestsPersonalityList";
import PhoneInput from "../Dashboard/Common/PhoneInput/PhoneInput";
import DateCommon from "../Dashboard/Common/Date/DateCommon";
import TimerPopup from "../TimerPopup/TimerPopup";

const quizQuestions = [
  {
    id: 1,
    title: "Profile Managed By?",
    helpLine: "Choose only one answer",
    options: ["Myself", "Parents", "Relatives"],
  },
  {
    id: 2,
    title: "What is the gender of the bride/groom ?",
    helpLine: "Choose only one answer",
    options: ["Male", "Female"],
  },
  {
    id: 3,
    title: "What is the full name of the bride/groom ? ",
  },
  {
    id: 4,
    title: "What is the date of birth of the bride/groom ?",
  },
  {
    id: 5,
    title: "Where is the bride/groom registering from ?",
    helpLine: "Choose only one answer",
    options: ["Bangladesh", "Abroad"],
  },
];

const DraftQuiz = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isQuizSubmitted, setIsQuizSubmitted] = useState(false);
  const { quizStep, quizData } = useSelector((state) => state.quiz);
  const { user } = useSelector((state) => state.auth);

  // console.log({ quizData });

  useEffect(() => {
    axios
      .get(`${BASE_URL}/me`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // console.log("Quiz Compiled");
        dispatch(setQuizStep(1));
        dispatch(setDateOfBirth(dayjs().format("YYYY-MM-DD")));
      })
      .catch((err) => {
        dispatch(setClearAuth());
        dispatch(setQuizStep(1));
        dispatch(setClearQuiz());
        dispatch(setClearMultiForm());
        dispatch(setClearQuiz());
        dispatch(setBioDataDetails({}));
        dispatch(setActiveTab(0));
        navigate("/login", { replace: true });
      });
  }, []);

  const handleQuizSubmit = async (data) => {
    console.log(data);
    dispatch(setClearMultiForm());
    dispatch(setBioDataDetails({}));
    const result = await bioDataService.submitQuiz(user.access_token, {
      managed_by: data.managedBy,
      gender: data.gender,
      full_name: data.fullName,
      dob: data.dateOfBirth,
      stay_in: data.registerFrom,
      display_name: data.fullName.split(" ")[0],
      age: data.age,
    });

    if (result) {
      navigate("/biodata", { replace: true });
    }
  };
  return (
    <div
      style={{
        backgroundColor: "#fff",
        position: "relative",
        height: "100vh",
      }}
    >
      <TimerPopup isTutorial="tutorial" />
      <Row
        align="middle"
        justify="center"
        style={{ margin: "0 auto", padding: "100px 10px" }}
      >
        <Col
          xl={18}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (
                (quizStep === 1 && quizData.managedBy !== null) ||
                (quizStep === 2 && quizData.gender !== null) ||
                (quizStep === 3 && quizData.fullName !== null) ||
                (quizStep === 4 && quizData.dateOfBirth !== null) ||
                (quizStep === 5 && quizData.registerFrom !== null)
              ) {
                dispatch(setQuizStep(quizStep + 1));
                console.log(quizData);
              }
            }
          }}
        >
          {quizStep === 1 && (
            <div>
              <Typography variant="h4" component="h2" style={styleTitle}>
                {quizQuestions[0].title}
              </Typography>
              <Typography variant="p" component="p" style={styleHelpLine}>
                {quizQuestions[0].helpLine}
              </Typography>
              <List>
                {quizQuestions[0].options.map((i, index) => (
                  <List.Item
                    key={index}
                    style={{ padding: "10px 0", border: "none" }}
                  >
                    <OptionBtn
                      value={i}
                      activeValue={quizData.managedBy}
                      disPatchAction={setMangedBy}
                    />
                  </List.Item>
                ))}
              </List>
            </div>
          )}
          {quizStep === 2 && (
            <div>
              <Typography variant="h4" component="h2" style={styleTitle}>
                {quizQuestions[1].title}
              </Typography>
              <Typography variant="p" component="p" style={styleHelpLine}>
                {quizQuestions[1].helpLine}
              </Typography>
              <List>
                {quizQuestions[1].options.map((i, index) => (
                  <List.Item key={index} style={{ padding: "10px 0" }}>
                    <OptionBtn
                      value={i}
                      activeValue={quizData.gender}
                      disPatchAction={setGender}
                    />
                  </List.Item>
                ))}
              </List>
            </div>
          )}
          {quizStep === 3 && (
            <div>
              <Typography variant="h4" component="h2" style={styleTitle}>
                {quizQuestions[2].title}
              </Typography>
              <Input
                style={{
                  maxWidth: "800px",
                  width: "100%",
                  marginTop: "50px",
                  marginBottom: "20px",
                  fontSize: "21px",
                  lineHeight: "26px",
                  padding: "15px",
                  height: "auto",
                }}
                inputProps={{ style: { fontSize: 30 } }}
                id="standard-basic"
                placeholder="Type your answer here..."
                variant="standard"
                value={quizData?.fullName}
                maxLength={30}
                onChange={(e) => {
                  e.target.value?.length === 30 &&
                    message.warning("Full Name Maximum Length 30.");
                  dispatch(setFullName(e.target.value));
                }}
              />
            </div>
          )}
          {quizStep === 4 && (
            <div>
              <Typography variant="h4" component="h2" style={styleTitle}>
                {quizQuestions[3].title}
              </Typography>
              <input
                style={{
                  maxWidth: "800px",
                  width: "100%",
                  marginTop: "50px",
                  marginBottom: "20px",
                  fontSize: "21px",
                  lineHeight: "26px",
                  padding: "15px",
                  height: "auto",
                }}
                inputprops={{ style: { fontSize: 30 } }}
                type="date"
                label="Date desktop"
                inputformat="MM/DD/YYYY"
                variant="outlined"
                // defaultValue={dayjs().format("DD-MM-YYYY")}
                value={quizData.dateOfBirth}
                onChange={(e) => {
                  console.log(e.target.value);
                  dispatch(setDateOfBirth(e.target.value));
                  dispatch(
                    setAge(
                      Math.floor(
                        dayjs().diff(dayjs(e.target.value), "year", true)
                      )
                    )
                  );
                  // console.log(
                  // 	Math.floor(
                  // 		dayjs().diff(dayjs(e.target.value), "year", true)
                  // 	)
                  // );
                }}
                max={new Date(
                  new Date().getFullYear() - 18,
                  new Date().getMonth(),
                  new Date().getDate()
                )
                  .toISOString()
                  .substr(0, 10)}
              />
              {/*<DatePicker
							className="date-root"
							style={{
								maxWidth: "800px",
								width: "100%",
								marginTop: "50px",
								marginBottom: "20px",
								fontSize: "21px",
								lineHeight: "26px",
								padding: "15px",
								height: "auto",
							}}
							// value={quizData.dateOfBirth}
							value={
								quizData.dateOfBirth
									? dayjs(quizData?.dateOfBirth, "YYYY-MM-DD")
									: ""
							}
							onChange={(date, dateString) => {
								// console.log(date, dateString);
								dispatch(setDateOfBirth(dateString));
								dispatch(
									setAge(
										Number(dayjs(dateString).fromNow(true).split(" ")[0])
									)
								);
							}}
						/> */}
            </div>
          )}
          {quizStep === 5 && (
            <div>
              <Typography variant="h4" component="h2" style={styleTitle}>
                {quizQuestions[4].title}
              </Typography>
              <Typography variant="p" component="p" style={styleHelpLine}>
                {quizQuestions[4].helpLine}
              </Typography>
              <List>
                {quizQuestions[4].options.map((i, index) => (
                  <List.Item key={index} style={{ padding: "10px 0" }}>
                    <OptionBtn
                      value={i}
                      activeValue={quizData.registerFrom}
                      disPatchAction={setRegisterFrom}
                    />
                  </List.Item>
                ))}
              </List>
            </div>
          )}
          {quizStep > 5 && (
            <div>
              {/*<Typography
								variant="h2"
								component="h2"
								style={{
									fontSize: "58px",
									lineHeight: "77px",
									color: "#4f4f4f",
									fontWeight: "bold",
								}}
							>
								Thank you. 
							</Typography> */}
              {/*<h2
								style={{
									fontSize: "30px",
									lineHeight: "35px",
									color: "#4f4f4f",
									fontWeight: "bold",
								}}
							>
								Thank you for creating Biodata.
							</h2>
							<p
								style={{
									fontSize: "22px",
									lineHeight: "28px",
									color: "#4f4f4f",
									marginTop: "10px",
								}}
							>
								For Searching your Life Partner, Go Next step to Complete Your
								Biodata.
							</p> */}
            </div>
          )}
          <div>
            {(quizStep === 1 && quizData.managedBy !== null) ||
            (quizStep === 2 && quizData.gender !== null) ||
            (quizStep === 3 && quizData.fullName !== null) ||
            (quizStep === 4 && quizData.dateOfBirth !== null) ||
            (quizStep === 5 && quizData.registerFrom !== null) ||
            quizStep === 6 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: quizStep > 5 ? "center" : "start",
                  marginTop: quizStep > 5 ? "145px" : "30px",
                }}
              >
                <Button
                  style={{
                    heigh: "100%",
                    // color: "#000",
                    marginTop: quizStep === 6 ? "100px" : "10px",
                    color: quizStep === 6 ? "#fff" : "#000",
                    // backgroundColor: "#f0f0f0",
                    backgroundColor: quizStep === 6 ? "#E22B6D" : "#f0f0f0",

                    padding: quizStep === 6 ? "30px 75px" : "20px 25px",
                    fontSize: quizStep === 6 ? "35px" : "21px",
                    lineHeight: "26px",
                    height: "auto",
                  }}
                  onClick={() => {
                    if (quizStep === 4 && quizData?.age < 17) {
                      message.error("Hey, Kid, You are under 18");
                    } else {
                      dispatch(setQuizStep(quizStep + 1));
                    }

                    if (quizStep === 6) {
                      handleQuizSubmit(quizData);
                    }
                  }}
                >
                  {quizStep === 6 ? "Next" : "Continue"}
                </Button>
                {/*<Button style={{ textTransform: "none", fontSize: "18px" }}>
								Press ENTER
							</Button> */}
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
      {quizStep < 6 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "25px",
            position: "absolute",
            bottom: "15px",
            right: "15px",
          }}
        >
          {/*<Typography variant="p" component="p" style={{ fontSize: "20px" }}>
						{(quizStep - 1) * 20 > 100
							? 100
							: (quizStep - 1) * 20 < 0
							? 0
							: (quizStep - 1) * 20}
						% Completed
					</Typography> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              style={{
                heigh: "100%",
                color: "#000",
                backgroundColor: "#f0f0f0",
                padding: "12px",
                fontSize: "21px",
                lineHeight: "26px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              color="primary"
              aria-label="Back"
              disabled={quizStep === 1 && true}
              onClick={(e) => {
                if (
                  (quizStep - 1 === 1 && quizData.managedBy !== null) ||
                  (quizStep - 1 === 2 && quizData.gender !== null) ||
                  (quizStep - 1 === 3 && quizData.fullName !== null) ||
                  (quizStep - 1 === 4 && quizData.dateOfBirth !== null) ||
                  (quizStep === 5 && quizData.registerFrom !== null) ||
                  quizStep === 6
                ) {
                  dispatch(setQuizStep(quizStep - 1));
                }
              }}
            >
              <MdKeyboardArrowUp />
            </Button>
            <Button
              style={{
                heigh: "100%",
                color: "#000",
                backgroundColor: "#f0f0f0",
                padding: "12px",
                fontSize: "21px",
                lineHeight: "26px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              color="primary"
              aria-label="add to shopping cart"
              disabled={quizStep === 6 && true}
              onClick={(e) => {
                if (
                  (quizStep + 1 === 1 && quizData.managedBy !== null) ||
                  (quizStep + 1 === 2 && quizData.gender !== null) ||
                  (quizStep + 1 === 3 && quizData.fullName !== null) ||
                  (quizStep + 1 === 4 && quizData.dateOfBirth !== null) ||
                  (quizStep + 1 === 5 && quizData.registerFrom !== null) ||
                  quizStep === 5
                ) {
                  dispatch(setQuizStep(quizStep + 1));
                }
              }}
            >
              <MdKeyboardArrowDown />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DraftQuiz;

const styleTitle = {
  color: "#E22B6D",
  fontSize: "35px",
  marginBottom: "10px",
  lineHeight: "40px",
  fontWeight: "600",
};
const styleHelpLine = {
  color: "#808080",
  fontSize: "22px",
  marginBottom: "8px",
  lineHeight: "25px",
  fontWeight: "500",
};
