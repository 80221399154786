import { Checkbox, Col, message, Row } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import districtList from "../../../../utils/districts.js";

import React, { Fragment, useEffect, useState } from "react";
// import { FaSmokingBan, FaUserCircle } from "react-icons/fa";
// import { MdFamilyRestroom, MdNoDrinks } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setPersonalFormData } from "../../../../app/slices/multiForm";
import DateCommon from "../../Common/Date/DateCommon";
import InputCommon from "../../Common/Input/InputCommon";
import SelectCommon from "../../Common/Select/SelectCommon";
import HeightInput from "../../Common/HeightInput/HeightInput.jsx";
import PhoneInput from "../../Common/PhoneInput/PhoneInput.jsx";
import axios from "axios";
import { BIO } from "../../../../app/config.js";
import { useNavigate } from "react-router-dom";
const personalInitData = {
	full_name: "",
	display_name: "",
	dob: "",
	age: "",
	height_ft: "",
	height_inch: "",
	blood_group: "",
	religion: "",
	marital_status: "",
	home_district: "",
	open_to_move_abroad: "",
	smoking_habit: "",
	drinking_habit: "",
	email: "",
	mobile_number: "",
	country_living_in: "",
	citizenship_status: "",
	father_name: "",
	father_occupation: "",
	mother_name: "",
	mother_occupation: "",
	present_address: "",
	present_area: "",
	present_city: "",
	present_postal_code: "",
	permanent_area: "",
	permanent_city: "",
	permanent_postal_code: "",
	professional_organization: "",
	organization_type: "",
	designation: "",
	occupation: "",
};
dayjs.extend(relativeTime);
const Personal = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { bioDataDetails, personalFormData } = useSelector(
		(state) => state.multiForm
	);

	const [countryList, setCountryList] = useState([]);
	useEffect(() => {
		axios
			.get(BIO.GET_BIO_DATA, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				// dispatch(setEducationFormData(res.data?.education));
				if (res.data.personal) {
					dispatch(setPersonalFormData(res.data.personal));
				} else {
					dispatch(setPersonalFormData(personalInitData));
				}
			})
			.catch((err) => {
				console.log(err);
				navigate("/login", { replace: true });
			});
		// bioDataDetails?.personal?.is_same_as_present_address
		// 	? setIsPermanentHide(false)
		// 	: setIsPermanentHide(true);
		fetch(
			"https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/index.json"
		)
			.then((res) => res.json())
			.then((data) => setCountryList(data));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const handlePermAddress = () => {
	// 	if (setIsPermanentHide === true) {
	// 		dispatch(
	// 			setPersonalFormData({
	// 				...personalFormData,
	// 				permanent_address: personalFormData?.present_address,
	// 				permanent_area: personalFormData?.present_area,
	// 				permanent_city: personalFormData?.present_city,
	// 				permanent_postal_code: personalFormData?.present_postal_code,
	// 			})
	// 		);
	// 		// setIsPermanentHide(true);
	// 	} else {
	// 		dispatch(
	// 			setPersonalFormData({
	// 				...personalFormData,
	// 				permanent_address: personalFormData?.present_address,
	// 				permanent_area: personalFormData?.permanent_area,
	// 				permanent_city: personalFormData?.permanent_city,
	// 				permanent_postal_code: personalFormData?.permanent_postal_code,
	// 			})
	// 		);
	// 		// setIsPermanentHide(false);
	// 	}
	// };
	const handleChangeData = (key, value) => {
		// console.log(key, value);
		if (key === "dob") {
			console.log(dayjs(value).fromNow(true));
			if (Math.floor(dayjs().diff(dayjs(value), "year", true)) < 18) {
				message.error("Hey, Kid, You are under 18");
			} else {
				dispatch(
					setPersonalFormData({
						...personalFormData,
						[key]: value,
						age: Math.floor(dayjs().diff(dayjs(value), "year", true)),
					})
				);
			}
		} else {
			dispatch(setPersonalFormData({ ...personalFormData, [key]: value }));
		}
	};
	// console.log(
	// 	personalFormData?.full_name?.split(" ").map((nameItem) => nameItem)
	// );
	// JSON.parse(districtList).map((item) => {
	// 	return {
	// 		key: item.name,
	// 		value: item.name,
	// 	};
	// })
	// console.log(bioDataDetails?.personal?.is_same_as_present_address);
	return (
		<Fragment>
			<Row gutter={[8, 40]} style={{ paddingTop: "20px" }}>
				<Col md={12} sm={24} xs={24}>
					<InputCommon
						name="full_name"
						label="Full Name"
						value={personalFormData?.full_name}
						handleChangeFunc={handleChangeData}
						type="text"
					/>
				</Col>
				<Col md={12} sm={24} xs={24}>
					<SelectCommon
						name="display_name"
						label="Display Name"
						handleChangeFunc={handleChangeData}
						value={personalFormData?.display_name}
						// levelIcon={<FaUserCircle style={{ color: "#e2286d" }} />}
						levelIcon={
							bioDataDetails?.personal?.gender?.toLowerCase() === "male"
								? "👨🏻"
								: "👩🏻"
						}
						options={personalFormData?.full_name?.split(" ").map((nameItem) => {
							return {
								key: nameItem,
								value: nameItem,
							};
						})}
					/>
				</Col>
				<Col md={8} sm={24} xs={24}>
					<DateCommon
						name="dob"
						label="Date Of Birth"
						value={personalFormData?.dob}
						handleChangeFunc={handleChangeData}
					/>
				</Col>
				<Col md={8} sm={24} xs={24}>
					<InputCommon
						value={personalFormData?.age}
						handleChangeFunc={handleChangeData}
						name="age"
						label="Age"
						type="text"
					/>
				</Col>
				<Col md={8} sm={24} xs={24}>
					<HeightInput
						nameOne="height_ft"
						nameTwo="height_inch"
						label="Height"
						valOne={personalFormData?.height_ft}
						valTwo={personalFormData?.height_inch}
						type="text"
						handleChangeFunc={handleChangeData}
					/>
				</Col>
				<Col md={8} sm={24} xs={24}>
					<SelectCommon
						name="blood_group"
						label="Blood Group"
						handleChangeFunc={handleChangeData}
						value={personalFormData?.blood_group}
						// levelIcon={<FaUserCircle style={{ color: "#e2286d" }} />}
						options={[
							{ key: "A+", value: "A+" },
							{ key: "A-", value: "A-" },
							{ key: "B+", value: "B+" },
							{ key: "B-", value: "B-" },
							{ key: "O+", value: "O+" },
							{ key: "O-", value: "O-" },
							{ key: "AB+", value: "AB+" },
							{ key: "AB-", value: "AB-" },
						]}
					/>
				</Col>
				<Col md={8} sm={24} xs={24}>
					<SelectCommon
						name="religion"
						label="Religion"
						handleChangeFunc={handleChangeData}
						value={personalFormData?.religion}
						// levelIcon={<FaUserCircle style={{ color: "#e2286d" }} />}
						options={[
							{ key: "Islam", value: "Islam" },
							{ key: "Hindu", value: "Hindu" },
							{ key: "Christian", value: "Christian" },
							{ key: "Buddhist", value: "Buddhist" },
							{ key: "Other", value: "Other" },
						]}
					/>
				</Col>
				<Col md={8} sm={24} xs={24}>
					<SelectCommon
						name="marital_status"
						label="Marital Status"
						handleChangeFunc={handleChangeData}
						value={personalFormData?.marital_status}
						// levelIcon={<FaUserCircle style={{ color: "#e2286d" }} />}
						options={[
							// { key: "Unmarried", value: "Unmarried" },
							{ key: "Never married", value: "Never married" },
							{ key: "Divorced", value: "Divorced" },
							{ key: "Widowed", value: "Widowed" },
						]}
					/>
				</Col>
				<Col md={8} sm={24} xs={24}>
					<SelectCommon
						name="home_district"
						label="Home District"
						handleChangeFunc={handleChangeData}
						value={personalFormData?.home_district}
						// levelIcon={<FaUserCircle style={{ color: "#e2286d" }} />}
						options={districtList.map((item) => {
							return {
								key: item.name,
								value: item.name,
							};
						})}
					/>
				</Col>
				<Col md={8} sm={24} xs={24}>
					<SelectCommon
						name="open_to_move_abroad"
						label="Open To Move Abroad"
						handleChangeFunc={handleChangeData}
						value={personalFormData?.open_to_move_abroad}
						// levelIcon={<FaUserCircle style={{ color: "#e2286d" }} />}
						// options={countryList.map((item) => {
						// 	return {
						// 		key: item.name,
						// 		value: item.name,
						// 	};
						// })}
						options={[
							{ key: "Yes", value: "Yes" },
							{ key: "No", value: "No" },
							{ key: "Maybe", value: "Maybe" },
						]}
					/>
				</Col>
				<Col md={8} sm={24} xs={24}>
					<SelectCommon
						name="smoking_habit"
						label="Smoking Habit"
						handleChangeFunc={handleChangeData}
						value={personalFormData?.smoking_habit}
						levelIcon={"🚭"}
						options={[
							{ key: "Smoker", value: "Smoker" },
							{ key: "Non-smoker", value: "Non-smoker" },
							{ key: "Social smoker", value: "Social smoker" },
						]}
					/>
				</Col>
				<Col md={8} sm={24} xs={24}>
					<SelectCommon
						name="drinking_habit"
						label="Drinking Habit"
						handleChangeFunc={handleChangeData}
						value={personalFormData?.drinking_habit}
						options={[
							{ key: "Drinker", value: "Drinker" },
							{ key: "Non-Drinker", value: "Non-Drinker" },
							{ key: "Social Drinker", value: "Social Drinker" },
						]}
						levelIcon={"🚱"}
					/>
				</Col>
				<Col md={8} sm={24} xs={24}>
					<InputCommon
						name="email"
						label="Email"
						type="email"
						value={personalFormData?.email}
						handleChangeFunc={handleChangeData}
					/>
				</Col>
				<Col md={8} sm={24} xs={24}>
					<PhoneInput
						name="mobile_number"
						label="Phone"
						phoneCode={personalFormData?.mobile_code}
						value={personalFormData?.mobile_number}
						handleChangeFunc={handleChangeData}
					/>
				</Col>
				{bioDataDetails?.personal?.stay_in?.toLowerCase() !== "bangladesh" && (
					<Fragment>
						<Col md={8} sm={24} xs={24}>
							<SelectCommon
								name="country_living_in"
								label="Country Living In"
								value={personalFormData?.country_living_in}
								handleChangeFunc={handleChangeData}
								// levelIcon={<MdNoDrinks style={{ color: "#e2286d" }} />}
								options={countryList.map((item) => {
									return {
										key: item.name,
										value: item.name,
									};
								})}
							/>
						</Col>
						<Col md={8} sm={24} xs={24}>
							<SelectCommon
								name="citizenship_status"
								label="Citizenship Status"
								value={personalFormData?.citizenship_status}
								handleChangeFunc={handleChangeData}
								// levelIcon={<MdNoDrinks style={{ color: "#e2286d" }} />}
								options={[
									{ key: "Citizen", value: "Citizen" },
									{ key: "Permanent resident", value: "Permanent resident" },
									{ key: "Non-citizen", value: "Non-citizen" },
								]}
							/>
						</Col>
					</Fragment>
				)}
			</Row>
			<div style={{ padding: "30px 0" }}>
				<Row>
					<Col span={24} xs={24}>
						<h4
							style={{
								textAlign: "start",
								fontSize: "20px",
								color: "#e2286d",
								margin: "0",
								padding: "0",
								marginBottom: "10px",
								paddingBottom: "15px",
								borderBottom: "1px dashed gray",
							}}
						>
							Parent's Information{" "}
							<span style={{ color: "#e2286d" }}>👨🏻‍👩🏻‍👦🏻‍👦🏻</span>
						</h4>
					</Col>
				</Row>
				<Row gutter={[8, 40]} style={{ marginTop: "35px" }}>
					<Col md={12} sm={24} xs={24}>
						<InputCommon
							name="father_name"
							label="Father Name"
							handleChangeFunc={handleChangeData}
							value={personalFormData?.father_name}
							type="text"
						/>
					</Col>
					<Col md={12} sm={24} xs={24}>
						<InputCommon
							name="father_occupation"
							label="Occupation"
							handleChangeFunc={handleChangeData}
							value={personalFormData?.father_occupation}
							type="text"
						/>
					</Col>
					<Col md={12} sm={24} xs={24}>
						<InputCommon
							name="mother_name"
							label="Mother Name"
							handleChangeFunc={handleChangeData}
							value={personalFormData?.mother_name}
							type="text"
						/>
					</Col>
					<Col md={12} sm={24} xs={24}>
						<InputCommon
							name="mother_occupation"
							label="Occupation"
							handleChangeFunc={handleChangeData}
							value={personalFormData?.mother_occupation}
							type="text"
						/>
					</Col>
				</Row>
			</div>
			<div style={{ padding: "30px 0" }}>
				<Row>
					<Col span={24}>
						<h4
							style={{
								textAlign: "start",
								fontSize: "20px",
								color: "#e2286d",
								margin: "0",
								padding: "0",
								marginBottom: "10px",
								paddingBottom: "15px",
								borderBottom: "1px dashed gray",
							}}
						>
							Present Address <span style={{ color: "#e2286d" }}>🏘️</span>
						</h4>
					</Col>
				</Row>
				<Row gutter={[8, 40]} style={{ marginTop: "35px" }}>
					<Col md={12} sm={24} xs={24}>
						<InputCommon
							name="present_address"
							label="Address"
							handleChangeFunc={handleChangeData}
							value={personalFormData?.present_address}
							type="text"
						/>
					</Col>
					{/*<Col md={8} sm={24} xs={24}>
						<InputCommon
							name="present_area"
							label="Area / Town"
							handleChangeFunc={handleChangeData}
							value={personalFormData?.present_area}
							type="text"
						/>
					</Col> */}
					<Col md={12} sm={24} xs={24}>
						<InputCommon
							name="present_city"
							label="City"
							handleChangeFunc={handleChangeData}
							value={personalFormData?.present_city}
							type="text"
						/>
					</Col>
					{/*<Col md={8} sm={24} xs={24}>
						<InputCommon
							name="present_postal_code"
							label="Postal code"
							handleChangeFunc={handleChangeData}
							value={personalFormData?.present_postal_code}
							type="text"
						/>
					</Col> */}
				</Row>
			</div>
			<div
				style={{
					padding: "30px 8px 30px 8px",
					textAlign: "left",
				}}
			>
				<Checkbox
					// onChange={handlePermAddress}
					onChange={(e) => {
						console.log(e.target.checked);
						if (e.target.checked === true) {
							dispatch(
								setPersonalFormData({
									...personalFormData,
									is_same_as_present_address: true,
									permanent_address: personalFormData?.present_address,
									permanent_area: "n/a",
									permanent_city: personalFormData?.present_city,
									permanent_postal_code: "n/a",
								})
							);
						} else {
							dispatch(
								setPersonalFormData({
									...personalFormData,
									is_same_as_present_address: false,
									permanent_address: null,
									permanent_area: "n/a",
									permanent_city: null,
									permanent_postal_code: "n/a",
								})
							);
						}
						console.log(personalFormData?.is_same_as_present_address);
					}}
					defaultChecked={personalFormData?.is_same_as_present_address}
					style={{ fontSize: "18px" }}
				>
					Same as present address
				</Checkbox>
			</div>

			<div style={{ padding: "30px 8px 0" }}>
				<Row>
					<Col span={24}>
						<h4
							style={{
								textAlign: "start",
								fontSize: "20px",
								color: "#e2286d",
								margin: "0",
								padding: "0",
								marginBottom: "10px",
								paddingBottom: "15px",
								borderBottom: "1px dashed gray",
							}}
						>
							Permanent Address <span style={{ color: "#e2286d" }}>🏘️</span>
						</h4>
					</Col>
				</Row>
				<Row gutter={[8, 40]} style={{ marginTop: "35px" }}>
					<Col md={12} sm={24} xs={24}>
						<InputCommon
							name="permanent_address"
							label="Address"
							handleChangeFunc={handleChangeData}
							value={personalFormData?.permanent_address}
							type="text"
						/>
					</Col>
					{/*<Col md={8} sm={24} xs={24}>
							<InputCommon
								name="permanent_area"
								label="Area / Town"
								handleChangeFunc={handleChangeData}
								value={personalFormData?.permanent_area}
								type="text"
							/>
						</Col> */}
					<Col md={12} sm={24} xs={24}>
						<InputCommon
							name="permanent_city"
							label="City"
							handleChangeFunc={handleChangeData}
							value={personalFormData?.permanent_city}
							type="text"
						/>
					</Col>
					{/*<Col md={8} sm={24} xs={24}>
							<InputCommon
								name="permanent_postal_code"
								label="Postal code"
								handleChangeFunc={handleChangeData}
								value={personalFormData?.permanent_postal_code}
								type="text"
							/>
						</Col> */}
				</Row>
			</div>

			{/*<h2>Personal data</h2>
			<InputCommon name="text" label="Text" value="" type="text" />
			<br />
			<br />
			<InputCommon name="number" label="Number" value="" type="number" />
			<br />
			<br />
			<InputCommon name="email" label="Email" value="" type="email" />
			<br />
			<br />
			<SelectCommon name="select" label="Select" value="" />
			<br />
			<br />
			<SelectCommon name="select" label="" value="" />
			<br />
			<br />
			<DateCommon name="date" label="Date" value="" /> */}
		</Fragment>
	);
};

export default Personal;
