import { Button, Col, Form, Input, notification, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import "./Login.css";
import loginInLogo from "../../assets/img/digiGhotok.png";
import AuthService from "../../app/services/authService";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BIO } from "../../app/config";
import axios from "axios";
import { setLogin } from "../../app/slices/auth";
import { MdClose } from "react-icons/md";
import imgSignInPopup from "../../assets/signInPopup.jpg";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const { user } = useSelector((state) => state.auth);

  const redirectDashboard = (user) => {
    if (user !== null) {
      axios
        .get(BIO.GET_BIO_DATA, {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // dispatch(setBioDataDetails(response.data));
          if (response.data?.personal.is_editable === true) {
            navigate("/biodata", { replace: true });
          } else {
            navigate("/biodata/explore", { replace: true });
          }
        })
        .catch((err) => {
          navigate("/biodata/quiz", { replace: true });
          // navigate("/biodata/explore", { replace: true });
          console.log(err);
        });
    }
  };

  useEffect(() => {
    user?.access_token && redirectDashboard(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  //notification:::
  const openNotification = (placement, data) => {
    console.log(placement, data);
    api.error({
      message: `${data}`,
      // description: <h2>description</h2>,
      placement,
    });
  };
  const onFinish = async (values) => {
    const { email, password } = values;
    const data = await AuthService.login(email, password);
    if (data?.access_token) {
      dispatch(setLogin({ user: data }));
    } else {
      // console.log(data.message?.password);
      Object.values(data.message).map((pType) => {
        pType.map((nType) => openNotification("topRight", nType));
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [popup, setPopup] = useState(true);

  return (
    <div className="login">
      {contextHolder}
      {popup && (
        <div className="modal-wrapper">
          <div className="modal-main">
            <button className="reg-close-btn" onClick={() => setPopup(false)}>
              <MdClose />
            </button>
            <div>
              <img
                src={imgSignInPopup}
                alt="Mega Offer"
                style={{ maxWidth: "500px", width: "100%" }}
              />
            </div>
          </div>
        </div>
      )}
      <Row
        align="middle"
        justify="center"
        style={{
          height: "100vh",
          // margin: "auto",
          // display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
          // flexDirection: "column",
        }}
      >
        <Col xxl={6} xl={8} lg={10} md={14} sm={18} xs={20}>
          <div
            style={{
              backgroundColor: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: "40px 20px",
              borderRadius: "12px",
              marginLeft: "15px",
              marginRight: "15px",
            }}
            // className="translate-wrapper"
          >
            <div
              className="col-md-12 text-center"
              style={{ maxWidth: "185px", maxHeight: "185px" }}
            >
              <img
                className="img-fluid"
                src={loginInLogo}
                alt=""
                width="100%"
                height="100%"
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography.Title
                style={{
                  color: "#e2236d",
                  textAlign: "center",
                  padding: "15px 0",
                  fontSize: "35px",
                }}
              >
                Sign In
              </Typography.Title>
              <Form
                name="basic"
                className="login_form"
                // labelCol={{
                // 	span: 8,
                // }}
                wrapperCol={{
                  span: 24,
                }}
                style={{
                  maxWidth: 600,
                  width: "100%",
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  // flexDirection: "column",
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="email"
                  style={{ marginBottom: "25px" }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email",
                    },
                  ]}
                >
                  <Input
                    type="email"
                    placeholder="Email"
                    className="input"
                    // style={{ background: "#ffdde1" }}
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  style={{ marginBottom: "25px" }}
                >
                  <Input.Password
                    placeholder="Password"
                    className="pass input"
                  />
                </Form.Item>

                <div style={{ paddingBottom: "15px" }}>
                  <Link
                    to="/forgot"
                    style={{ color: "gray", lineHeight: "24px" }}
                  >
                    Forgot password?
                  </Link>
                </div>

                <Form.Item
                  // wrapperCol={{
                  // 	offset: 8,
                  // 	span: 16,
                  // }}
                  style={{ textAlign: "center" }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-btn"
                    style={{ backgroundColor: "#4096ff" }}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
              <p
                style={{
                  margin: "0",
                  paddingBottom: "2px",
                  textAlign: "center",
                }}
              >
                Don't have account ?{" "}
                <Link to="/register" style={{ color: "#f7b4bc" }}>
                  Register
                </Link>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
