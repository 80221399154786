export const postGraduationOptions = [
    { key: "FCPS", value: "FCPS" },
    { key: "Masters", value: "Masters" },
    { key: "PHD", value: "PHD" },
    { key: "Postdoctorate", value: "Postdoctorate" },
    { key: "MBA", value: "MBA" },
    { key: "Kamil", value: "Kamil" },
]
export const universityOptions = [{ key: "Bachelors", value: "Bachelors" },
{ key: "MBBS", value: "MBBS" },
{ key: "LLB", value: "LLB" },
{ key: "BBA", value: "BBA" },
{ key: "Honours", value: "Honours" },
{ key: "Fazil", value: "Fazil" },
{ key: "Diploma", value: "Diploma" }]
export const collegeOptions = [{ key: "HSC", value: "HSC" },
{ key: "A Level", value: "A Level" },
{ key: "Alim", value: "Alim" },]
export const schoolOptions = [{ key: "SSC", value: "SSC" },
{ key: "O Level", value: "O Level" },
{ key: "Dakhil", value: "Dakhil" },]