import React from "react";
import PaymentSuccess from "../components/Payment/PaymentSuccess";

const PaymentSuccessPage = () => {
  return (
    <div>
      <PaymentSuccess />
    </div>
  );
};

export default PaymentSuccessPage;
