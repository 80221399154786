import React, { useEffect, useState } from "react";
import "./PaymentModal.css";
import { useSelector } from "react-redux";
import { PAYMENT } from "../../app/config";
import axios from "axios";
import { message } from "antd";

const PaymentModal = () => {
  const { user } = useSelector((state) => state.auth);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    axios(`${PAYMENT.PACKAGES}`)
      .then((res) => {
        // console.log(res.data);
        setPackages(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handlePaymentGateway = (paymentInfo, token) => {
    // console.log({ packageInfo });
    axios
      .post(
        `${PAYMENT.SUBMIT_PAYMENT}`,
        {
          amount: paymentInfo.amount,
          service_type: paymentInfo.type,
          service_id: paymentInfo.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        // return res.data;
        window.location.replace(res.data.payment_gateway_redirect_url);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.error);
        return err;
      });
  };

  return (
    <>
      {/* <div className="heading">
        <h2 style={{ fontSize: "25px" }}>Special offer</h2>
        <h4 style={{ fontSize: "20px", padding: "10px 0" }}>
          ending soon for first <span>100 customers</span>
        </h4>
      </div> */}
      <div
        style={{
          paddingTop: "20px",
        }}
        className="package-wrapper"
      >
        {packages?.map((packageItem, packageIndex) => (
          <div
            className="package-item"
            key={packageItem.key}
            style={{
              // maxWidth: "500px",
              // width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h4 className="title">{packageItem.title}</h4>
              <p style={{ textAlign: "center" }}>
                <span
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    backgroundColor: "#FFE81C",
                    borderRadius: "5px",
                    padding: "2px",
                  }}
                >
                  {packageItem.duration}
                </span>
              </p>
              <div
                style={{
                  padding: "20px 0",
                  borderBottom: "1px solid #e1136e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    color: "#104EEF",
                    fontSize: "18px",
                  }}
                >
                  <strike style={{ color: "#e1136e" }}>
                    <span style={{ color: "#104EEF" }}>
                      $ {packageItem.actualPrice.inDollar}
                    </span>
                  </strike>{" "}
                  <span>|</span>
                  <strike style={{ color: "#e1136e" }}>
                    <span style={{ color: "#104EEF" }}>
                      ৳ {packageItem.actualPrice.inTaka}
                    </span>
                  </strike>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    color: "#104EEF",
                    fontSize: "17px",
                    fontWeight: "bold",
                  }}
                >
                  <span style={{ color: "#104EEF" }}>
                    $ {packageItem.offeredPrice.inDollar}
                  </span>
                  <span>|</span>
                  <span style={{ color: "#104EEF" }}>
                    ৳ {packageItem.offeredPrice.inTaka}
                  </span>
                </div>
              </div>
              <ul style={{ padding: "8px 15px" }}>
                {packageItem.details.map((delItem, index) =>
                  // (
                  //   <li
                  //     key={index}
                  //     style={{
                  //       fontSize: "13px",
                  //       fontWeight: "400",
                  //       lineHeight: "15px",
                  //     }}
                  //   >
                  //     {delItem}
                  //   </li>
                  // )
                  {
                    if (/^“Send Request” /.test(delItem)) {
                      let strArr = delItem.split(" up to");
                      return (
                        <li
                          key={index}
                          style={{
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "20px",
                          }}
                        >
                          <span>{strArr[0]} </span>
                          <span style={{ backgroundColor: "yellow" }}>
                            {" "}
                            up to {strArr[1]}
                          </span>
                        </li>
                      );
                    } else {
                      return (
                        <li
                          key={index}
                          style={{
                            fontSize: "13px",
                            fontWeight: "400",
                            lineHeight: "20px",
                          }}
                        >
                          {delItem}
                        </li>
                      );
                    }
                  }
                )}
              </ul>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "15px 0",
              }}
            >
              <button
                className="buy-btn"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handlePaymentGateway(
                    {
                      amount: packageItem.offeredPrice.inTaka,
                      type: 1,
                      id: packageItem.id,
                    },
                    user.access_token
                  )
                }
              >
                Buy
              </button>
            </div>
          </div>
        ))}
      </div>
      <div>
        <h2
          style={{
            color: "#e2286d",
            textAlign: "center",
            paddingTop: "20px",
          }}
        >
          ** Please note that there is no refund policy once the payment is
          completed.
        </h2>
      </div>
    </>
  );
};

export default PaymentModal;
