const organization = {
	"@context": "https://schema.org",
	"@type": "Organization",
	name: "Digital Ghotok",
	url: "https://digitalghotok.com/",
	logo: "https://ghootok.com/img/digiGhotok.png",
	sameAs: [
		"https://www.facebook.com/digitalghotok.com.bd",
		"https://www.instagram.com/digital_ghotok/",
		"https://www.youtube.com/@digitalghotoknetwork",
		"https://www.linkedin.com/company/digitalghotok/",
	],
};

const localBusiness = {
	"@context": "https://schema.org",
	"@type": "LocalBusiness",
	name: "Digital Ghotok",
	image: "https://ghootok.com/img/digiGhotok.png",
	"@id": "",
	url: "https://digitalghotok.com/",
	telephone: "",
	address: {
		"@type": "PostalAddress",
		streetAddress: "",
		addressLocality: "Dhaka",
		postalCode: "",
		addressCountry: "BD",
	},
	openingHoursSpecification: {
		"@type": "OpeningHoursSpecification",
		dayOfWeek: [
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
			"Sunday",
		],
		opens: "00:00",
		closes: "23:59",
	},
	sameAs: [
		"https://www.facebook.com/digitalghotok.com.bd",
		"https://www.instagram.com/digital_ghotok/",
		"https://www.youtube.com/@digitalghotoknetwork",
		"https://www.linkedin.com/company/digitalghotok/",
	],
};

const videoObjects = {
	"@context": "https://schema.org",
	"@type": "VideoObject",
	name: "Digital Ghotok Intro | বিয়ে এখন আপনার হাতে",
	description: `Bangladesh’s 1st AI-Based Matchmaking 👫 & Matrimony 💕 platform based on candidates’ preferences & more for all Bangladeshis and Bangladeshis living abroad. 

  👉Free Sign Up & Registration Link: https://digitalghotok.com/
  ➡️Follow us on Facebook: https://www.facebook.com/digitalghoto...
  ➡️Follow us on Instagram: https://www.instagram.com/digital_gho...
  
  #WeMatchBetter #digitalghotok #digitalghotoknetwork #wedding  #marriage  #matchmaking  #bangladeshiwedding  #probashi  #bangladeshiwedding  #ghotok  #matrimony  #dhakawedding  #dhaka  #dhakagraam  #chittagong  #chottogram  #khulna  #rajshahi  #barishal  #sylhet  #bangladeshi  #bangladesh  #probashibangali  #dhakawedding `,
	thumbnailUrl: "https://digitalghotok.com/img/thumbnail-video-1.jpg",
	uploadDate: "2023-03-22",
	duration: "PT0M51S",
	contentUrl: "https://youtu.be/lrkeZrEnhk0",
	embedUrl: "https://www.youtube.com/embed/lrkeZrEnhk0",
};

const faqPage = {
	"@context": "https://schema.org",
	"@type": "FAQPage",
	mainEntity: [
		{
			"@type": "Question",
			name: "What is Digitalghotok.com?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "Digitalghotok is a revolution in the matchmaking & matrimony industry. It is here to break the old approach of matchmaking (with matchmakers) in Bangladesh with its advanced compatibility algorithm powered by AI. We cater our services in all major districts of Bangladesh and to all Bangladeshis living abroad.",
			},
		},
		{
			"@type": "Question",
			name: "What is the age group of users that we serve at Digitalghotok.com?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "Civil laws dictate that the legal age of consent and minimum age for marriage is 18 for women and 21 for men. However, the maximum age limit is 50.",
			},
		},
		{
			"@type": "Question",
			name: "How to create a profile on Digitalghotok.com?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "It takes less than a few minutes to create your profile. All you need to do is go to our website and create your profile with your email & password. You can edit your profile whenever you want!",
			},
		},
		{
			"@type": "Question",
			name: "Can Users Create More than One Profile on Digitalghotok.com?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "No. Users cannot create multiple profiles on Digitalghotok. A user with multiple profiles isn’t serious about finding his/her partner and might temper with the safe environment of our platform. So, we don’t encourage such behavior. And even if an individual tries to create more than one profile, it will immediately be unverified.",
			},
		},
		{
			"@type": "Question",
			name: "How to change my personal details like email id & phone number?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "To keep the community safe from fraud users, we do not allow users to change their personal details like email id and mobile number. However, you can write to us at digitalghotok22@gmail.com with the changes and government id as a proof of identity.",
			},
		},
		{
			"@type": "Question",
			name: "How to add/remove/edit photos?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "After the successful Login, if you wish to do any changes to your pictures, please click on your profile preview. Under “profile preview” you will find the edit button and change any details (including pictures).",
			},
		},
		{
			"@type": "Question",
			name: "What are “About Me” and “Partner Expectations” sections?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "“About Me” is a great opportunity to introduce yourself, to the person you like, by showing them a straight-from-the-heart message. The “Partner Expectations” doubles up your chances of making a meaningful connection.",
			},
		},
		{
			"@type": "Question",
			name: "What is “Open to Move Abroad”?",
			acceptedAnswer: {
				"@type": "Answer",
				text: "This section helps prospects to understand whether he/she is interested in settling in Bangladesh or Abroad.",
			},
		},
	],
};

export const jsonLdData = {
	organization,
	localBusiness,
	videoObjects,
	faqPage,
};
