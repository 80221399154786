import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	quizStep: 1,
	quizData: {
		managedBy: null,
		gender: null,
		fullName: null,
		dateOfBirth: null,
		registerFrom: null,
		age: null,
	},
};
const authSlice = createSlice({
	name: "quiz",
	initialState,
	reducers: {
		setClearQuiz: (state, action) => {
			state.quizStep = 1;
			state.quizData = {
				managedBy: null,
				gender: null,
				fullName: null,
				dateOfBirth: null,
				registerFrom: null,
				age: null,
			};
		},
		setQuizStep: (state, action) => {
			state.quizStep = action.payload;
		},
		setMangedBy: (state, action) => {
			state.quizData = { ...state.quizData, managedBy: action.payload };
		},
		setGender: (state, action) => {
			state.quizData = { ...state.quizData, gender: action.payload };
		},
		setFullName: (state, action) => {
			state.quizData = { ...state.quizData, fullName: action.payload };
		},
		setDateOfBirth: (state, action) => {
			state.quizData = { ...state.quizData, dateOfBirth: action.payload };
		},
		setAge: (state, action) => {
			state.quizData = { ...state.quizData, age: action.payload };
		},
		setRegisterFrom: (state, action) => {
			state.quizData = { ...state.quizData, registerFrom: action.payload };
		},
	},
});

const { reducer, actions } = authSlice;
export const {
	setQuizStep,
	setMangedBy,
	setGender,
	setFullName,
	setDateOfBirth,
	setRegisterFrom,
	setClearQuiz,
	setAge,
} = actions;
export default reducer;
