import React from "react";
import Faq from "../components/Faq/Faq";

const FaqPage = () => {
	return (
		<section>
			<Faq />
		</section>
	);
};

export default FaqPage;
