import { Button, Col, Collapse, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "./Faq.css";
const { Panel } = Collapse;

const Faq = () => {
	const onChange = () => {};
	return (
		<Row align="top" justify="center" className="faq">
			<Col
				xl={11}
				lg={12}
				md={14}
				xs={22}
				className="container py-5"
				style={{ paddingTop: "100px" }}
			>
				<h1
					className="text-center text-white mt-5"
					style={{ textAlign: "center", color: "#fff", fontSize: "40px" }}
				>
					FAQs
				</h1>
				<Collapse
					defaultActiveKey={["1"]}
					onChange={onChange}
					style={{ backgroundColor: "#fff", margin: "40px 0" }}
					className="collapse-root"
				>
					<div style={{ padding: "40px 0 10px 10px" }}>
						<Link to="/">
							<Button type="primary" size="largne">
								Back to Home
							</Button>
						</Link>
					</div>
					<Panel header="What is Digitalghotok.com?" key="1">
						<p>
							<strong>Digitalghotok</strong> is a revolution in the matchmaking
							& matrimony industry. It is here to break the old approach of
							matchmaking (with matchmakers) in Bangladesh with its advanced
							compatibility algorithm powered by AI. We cater our services in
							all major districts of Bangladesh and to all Bangladeshis living
							abroad.
						</p>
					</Panel>
					<Panel
						header="What is the age group of users that we serve at
          Digitalghotok.com?"
						key="2"
					>
						<p>
							Civil laws dictate that the legal age of consent and minimum age
							for marriage is 18 for women and 21 for men. However, the maximum
							age limit is 50.
						</p>
					</Panel>
					<Panel header="How to create a profile on Digitalghotok.com?" key="3">
						<p>
							It takes less than a few minutes to create your profile. All you
							need to do is go to our website and create your profile with your
							email & password. You can edit your profile whenever you want!
						</p>
					</Panel>
					<Panel
						header="Can Users Create More than One Profile on Digitalghotok.com?"
						key="4"
					>
						<p>
							No. Users cannot create multiple profiles on Digitalghotok. A user
							with multiple profiles isn’t serious about finding his/her partner
							and might temper with the safe environment of our platform. So, we
							don’t encourage such behavior. And even if an individual tries to
							create more than one profile, it will immediately be unverified.
						</p>
					</Panel>
					<Panel
						header="How to change my personal details like email id & phone
            number?"
						key="5"
					>
						<p>
							To keep the community safe from fraud users, we do not allow users
							to change their personal details like email id and mobile number.
							However, you can write to us at digitalghotok22@gmail.com with the
							changes and government id as a proof of identity.
						</p>
					</Panel>
					<Panel header="How to add/remove/edit photos?" key="6">
						<p>
							After the successful Login, if you wish to do any changes to your
							pictures, please click on your profile preview. Under “profile
							preview” you will find the edit button and change any details
							(including pictures).
						</p>
					</Panel>
					<Panel
						header="What are “About Me” and “Partner Expectations” sections?"
						key="7"
					>
						<p>
							“About Me” is a great opportunity to introduce yourself, to the
							person you like, by showing them a straight-from-the-heart
							message. The “Partner Expectations” doubles up your chances of
							making a meaningful connection.
						</p>
					</Panel>
					<Panel header="What is “Open to Move Abroad”?" key="8">
						<p>
							This section helps prospects to understand whether he/she is
							interested in settling in Bangladesh or Abroad.
						</p>
					</Panel>
				</Collapse>
			</Col>
		</Row>
	);
};

export default Faq;
