import { Form, Select } from "antd";
import "../Select/SelectCommon";
import React, { Fragment, useState } from "react";
const SelectMultiple = ({
	name,
	parentName,
	value,
	label,
	levelIcon,
	options,
	handleChangeFunc,
}) => {
	const [focus, setFocus] = useState(false);
	const labelClass =
		focus || (value && value.length !== 0) ? "label label-float" : "label";

	//Select related :::
	const handleChange = (val) => handleChangeFunc(name, val, parentName);
	// const handleChange = (key,val) => {
	//     console.log({key, val});
	// }
	return (
		<Form.Item
			required
			name={name}
			className="input-wrapper"
			onBlur={() => setFocus(false)}
			onFocus={() => setFocus(true)}
			style={{ width: "100%", margin: "15px auto", background: "transparent" }}
		>
			<Fragment>
				{/*<Input className="input-field" /> */}

				<Select
					style={{ background: "transparent" }}
					mode="multiple"
					required
					showSearch
					placeholder="Search to Select"
					optionFilterProp="children"
					className="select-root"
					filterOption={(input, option) =>
						(option?.children?.toLowerCase() ?? "").includes(input?.toLowerCase())
					}
					// filterSort={(optionA, optionB) =>
					// 	(optionA?.value ?? "")
					// 		.toLowerCase()
					// 		.localeCompare((optionB?.value ?? "").toLowerCase())
					// }
					onChange={handleChange}
					size="large"
					value={value}
					// style={{ width: 200 }}
					// options={options}
				>
					{options.map((opt) => (
						<Select.Option value={opt.key} key={opt.key}>
							{opt.value}
						</Select.Option>
					))}
				</Select>
				<label
					htmlFor={name}
					className={labelClass}
					style={{
						fontSize: "17px",
						color: value ? "rgb(117, 117, 117" : "#ea6689",
					}}
				>
					{label}
					{levelIcon}
				</label>
			</Fragment>
		</Form.Item>
	);
};

export default SelectMultiple;
