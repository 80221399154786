import { Button, Col, Modal, Row, Spin, message } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { BsFillCheckCircleFill, BsGrid } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../Navbar/Navbar";
import imgHeight from "../../assets/dash/height.png";
import imgProfession from "../../assets/dash/profession.png";
import imgYears from "../../assets/dash/year.png";
import imgLocation from "../../assets/dash/location.png";
import imgDigitalGotok from "../../assets/img/digiGhotok.png";
import "../Explore/Explore.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { BIO, BIODATA_REQUEST, PAYMENT } from "../../app/config";
import PaymentModal from "../Payment/PaymentModal";

const Analytics = () => {
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bioDataList, setBioDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { bioDataDetails } = useSelector((state) => state.multiForm);

  const [showAnalytics, setShowAnalytics] = useState(true);
  const [filteringOption, setFilteringOption] = useState("");
  const [analyticsCount, setAnalyticsCount] = useState({
    total_sent_requests: 0,
    total_received_requests: 0,
    total_connected_requests: 0,
    total_rejected_requests: 0,
  });

  useEffect(() => {
    setIsLoading(true);
    const axiosHeader = {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
        "Content-Type": "application/json",
      },
      params: {
        limit: currentPage * 5,
        offset: (currentPage - 1) * 5,
      },
    };
    if (showTabRender === 2) {
      axios
        .get(
          BIODATA_REQUEST.GET_ANALYTICS_BIODATA(filteringOption),
          axiosHeader
        )
        .then((res) => {
          setAnalyticsCount({
            total_sent_requests: res.data.total_sent_requests,
            total_received_requests: res.data.total_received_requests,
            total_connected_requests: res.data.total_connected_requests,
            total_rejected_requests: res.data.total_rejected_requests,
          });
          setTotalPage(res.data.total_page);
          setBioDataList(res.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteringOption, currentPage]);

  const handleSendRequest = (user_id) => {
    axios
      .post(
        BIODATA_REQUEST.SEND_REQUEST,
        { user_id },
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        fetchBioDataList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCancelRequest = (req_id) => {
    axios
      .post(
        BIODATA_REQUEST.CANCEL_SEND_REQUEST(req_id),
        {},
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        fetchBioDataList();
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.error);
      });
  };
  const [sendRequestPopup, setSendRequestPopup] = useState(false);
  const [packages, setPackages] = useState([]);
  useEffect(() => {
    axios(`${PAYMENT.PACKAGES}`)
      .then((res) => {
        setPackages(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [sendRequestPopup]);

  const handleAcceptRequest = (req_id) => {
    axios
      .post(
        BIODATA_REQUEST.ACCEPT_REQUEST(req_id),
        {},
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        fetchBioDataList();
      })
      .catch((err) => {
        setSendRequestPopup(true);
        console.log(err);
        message.error(err.response.data.error);
        // navigate("/login", { replace: true });
      });
  };
  const handleRejectRequest = (req_id) => {
    axios
      .post(
        BIODATA_REQUEST.REJECT_REQUEST(req_id),
        {},
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        fetchBioDataList();
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.error);
        // navigate("/login", { replace: true });
      });
  };
  const handleViewBiodata = (user_id) => {
    axios
      .get(`${BIO.GET_BIO_DATA}/?user_id=${user_id}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        fetchBioDataList();
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.error);
        // navigate("/login", { replace: true });
      });
  };

  const fetchBioDataList = () => {
    axios
      .get(BIODATA_REQUEST.GET_ANALYTICS_BIODATA(filteringOption), {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setAnalyticsCount({
          total_sent_requests: res.data.total_sent_requests,
          total_received_requests: res.data.total_received_requests,
          total_connected_requests: res.data.total_connected_requests,
        });
        setBioDataList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [showTabRender, setShowTabRender] = useState(2);

  return (
    <section>
      <Navbar />
      <div
        style={{
          paddingTop: "80px",
          backgroundColor: "#F4F0F0",
          minHeight: "100vh",
          height: "100%",
        }}
      >
        <Modal
          open={sendRequestPopup}
          onOk={() => setSendRequestPopup(false)}
          onCancel={() => setSendRequestPopup(false)}
          style={{ width: "80%" }}
          className="send-request-modal"
          width="100%"
        >
          <PaymentModal />
        </Modal>
        <Row align="top" justify="center" className="container explore-wrapper">
          <Col xxl={16} xl={22} lg={23} md={23} xs={24}>
            <Row style={{ paddingBottom: "20px" }}>
              <button
                className="filter-btn"
                // onLoad={() => setFilterData(false)}
                onClick={() => setIsFilterShow(!isFilterShow)}
              >
                <BsGrid /> <span>Menu</span>
              </button>
            </Row>
            <Row
              align="top"
              justify="start"
              gutter={{ xs: 0, sm: 0, md: 10, lg: 15, xl: 15, xxl: 30 }}
              className="row-wrapper-main"
            >
              <Col
                xxl={5}
                xl={5}
                lg={5}
                md={5}
                className={`filter-wrapper ${
                  isFilterShow ? "filter-show" : "filter-hide"
                }`}
                // style={{ display: isFilterShow ? "flex" : "none" }}
              >
                <div
                  style={{
                    borderRadius: "6px",
                    backgroundColor: "white",
                    width: "100%",
                  }}
                >
                  <Row className="filter-main" style={{ padding: "15px 3px" }}>
                    <Col span={24}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={imgDigitalGotok}
                          alt="digital ghotok"
                          style={{
                            maxWidth: "152px",
                            width: "100%",
                            maxHeight: "158px",
                            height: "100%",
                          }}
                        />
                      </div>
                      <div style={{ padding: "20px 15px" }}>
                        <div style={{ paddingBottom: "20px" }}>
                          <button
                            onClick={() => {
                              setShowTabRender(1);
                            }}
                            style={{
                              backgroundColor:
                                showTabRender === 1 ? "#eaa8da" : "#D9D9D9",
                              borderRadius: "10px",
                              padding: "6px 12px",
                              color: "#000000",
                              fontWeight: "bold",
                              fontSize: "16px",
                              marginTop: "15px",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            My Package
                          </button>
                        </div>
                        {/* <Link
                          to="/biodata/explore"
                          style={{
                            backgroundColor: "#D9D9D9",
                            borderRadius: "10px",
                            padding: "6px 12px",
                            color: "#000000",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          Explore
                        </Link> */}
                        <button
                          style={{
                            backgroundColor:
                              showTabRender === 2 ? "#eaa8da" : "#D9D9D9",
                            borderRadius: "10px",
                            padding: "6px 12px",
                            color: "#000000",
                            fontWeight: "bold",
                            fontSize: "16px",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowTabRender(2);
                          }}
                        >
                          Profile Analytics
                        </button>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "center",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor:
                                filteringOption === "" ? "#FFB3B3" : "#D9D9D9",
                              padding: "5px 8px",
                              borderRadius: "10px",
                              marginTop: "8px",
                              cursor: "pointer",
                            }}
                            onClick={() => setFilteringOption("")}
                          >
                            <p>
                              All
                              <sup style={{ color: "#E72346" }}>
                                {analyticsCount.total_received_requests +
                                  analyticsCount.total_connected_requests +
                                  analyticsCount.total_sent_requests +
                                  analyticsCount.total_rejected_requests}
                              </sup>
                            </p>
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                filteringOption === "received"
                                  ? "#FFB3B3"
                                  : "#D9D9D9",
                              padding: "5px 8px",
                              borderRadius: "10px",
                              marginTop: "8px",
                              cursor: "pointer",
                            }}
                            onClick={() => setFilteringOption("received")}
                          >
                            <p>
                              Request{" "}
                              <sup style={{ color: "#E72346" }}>
                                {analyticsCount.total_received_requests}
                              </sup>
                            </p>
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                filteringOption === "sent"
                                  ? "#FFB3B3"
                                  : "#D9D9D9",
                              padding: "5px 8px",
                              borderRadius: "10px",
                              margin: "8px 0",
                              cursor: "pointer",
                            }}
                            onClick={() => setFilteringOption("sent")}
                          >
                            <p>
                              Request Sent{" "}
                              <sup style={{ color: "#E72346" }}>
                                {analyticsCount.total_sent_requests}
                              </sup>
                            </p>
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                filteringOption === "connected"
                                  ? "#FFB3B3"
                                  : "#D9D9D9",
                              padding: "5px 8px",
                              borderRadius: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => setFilteringOption("connected")}
                          >
                            <p>
                              Request Accepted{" "}
                              <sup style={{ color: "#E72346" }}>
                                {analyticsCount.total_connected_requests}
                              </sup>
                            </p>
                          </div>
                          <div
                            style={{
                              backgroundColor:
                                filteringOption === "connected"
                                  ? "#FFB3B3"
                                  : "#D9D9D9",
                              padding: "5px 8px",
                              borderRadius: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() => setFilteringOption("rejected")}
                          >
                            <p>
                              Request Rejected
                              <sup style={{ color: "#E72346" }}>
                                {analyticsCount.total_rejected_requests}
                              </sup>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col
                xxl={19}
                xl={19}
                lg={19}
                md={22}
                sm={23}
                xs={23}
                style={{ margin: "0 auto" }}
              >
                {/*replace location */}
                {isLoading ? (
                  <div style={{ width: "100%" }}>
                    <div
                      style={{ display: "flex", width: "100%" }}
                      className="card-main"
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="spin-wrapper"
                      >
                        <Spin size="large" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <div
                      className="card-wrapper-scroll"
                      // style={{
                      // 	maxHeight: "80vh",
                      // 	overflowY: "auto",
                      // 	paddingRight: "5px",
                      // }}
                    >
                      {isLoading ? (
                        <div style={{ width: "100%" }}>
                          <div
                            style={{ display: "flex", width: "100%" }}
                            className="card-main"
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              className="spin-wrapper"
                            >
                              <Spin size="large" />
                            </div>
                          </div>
                        </div>
                      ) : showTabRender === 1 ? (
                        <div>
                          <h2>My Package details</h2>
                          <div
                            style={{
                              paddingTop: "40px",
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            <h4>
                              Package expiration time :{" "}
                              {
                                bioDataDetails?.package_info
                                  ?.package_expiration_time
                              }
                            </h4>
                            <h4>
                              Total package purchased:{" "}
                              {
                                bioDataDetails?.package_info
                                  ?.total_package_purchased
                              }
                            </h4>
                            <h4>
                              Total request purchased:{" "}
                              {
                                bioDataDetails?.package_info
                                  ?.total_request_purchased
                              }
                            </h4>
                            <h4>
                              Total request unused:{" "}
                              {
                                bioDataDetails?.package_info
                                  ?.total_request_unused
                              }
                            </h4>
                            <h4>
                              Total request used:{" "}
                              {bioDataDetails?.package_info?.total_request_used}
                            </h4>
                          </div>
                        </div>
                      ) : showTabRender === 2 && bioDataList.length > 0 ? (
                        bioDataList.map((bioInfo, index) => (
                          <div
                            key={index}
                            className={`${
                              bioDataDetails?.personal?.gender?.toLowerCase() ===
                              "female"
                                ? "card-wrapper-male"
                                : "card-wrapper-female"
                            }`}
                            style={{
                              width: "100%",
                              padding: "8px 0",
                            }}
                          >
                            <div
                              className="card-main"
                              style={{
                                position: "relative",
                                zIndex: "10",
                              }}
                            >
                              <div className="card-left">
                                <div className="card-img-wrapper">
                                  <img
                                    src={bioInfo?.image}
                                    alt=""
                                    className="card-img"
                                    style={{
                                      borderRadius: "10px",
                                    }}
                                  />
                                  {bioInfo?.is_match_found && (
                                    <div className="card-img-match-found">
                                      <h4>Already Matched</h4>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="card-right">
                                <div className="card-head">
                                  <div className="title">
                                    <h2>{bioInfo?.display_name}</h2>
                                    {bioInfo?.is_verified && (
                                      <BsFillCheckCircleFill className="verify-icon" />
                                    )}
                                  </div>
                                  <div className="managed-by">
                                    <p>
                                      <span>Profile Managed By: </span>
                                      <span>{bioInfo?.managed_by}</span>
                                    </p>
                                  </div>
                                  <div className="icon-item-wrapper">
                                    <div className="top-icon-item">
                                      <img src={imgProfession} alt="" />
                                      <h4>{bioInfo?.profession}</h4>
                                    </div>
                                    <div className="top-icon-item">
                                      <img src={imgHeight} alt="" />
                                      <h4>
                                        {bioInfo?.height_feet}'{" "}
                                        {bioInfo?.height_inch}"
                                      </h4>
                                    </div>
                                    <div className="top-icon-item">
                                      <img src={imgYears} alt="" />
                                      <h4>{bioInfo?.age} Yrs</h4>
                                    </div>
                                    <div className="top-icon-item">
                                      <img src={imgLocation} alt="" />
                                      <h4>{bioInfo?.location}</h4>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <div className="info-wrapper">
                                    <div
                                      className="marital-religion"
                                      style={{ padding: "10px 0" }}
                                    >
                                      <p>
                                        <span>Marital Status:</span>{" "}
                                        <span>{bioInfo?.marital_status}</span>
                                      </p>
                                      <p>
                                        <span>Religion:</span>{" "}
                                        <span>{bioInfo?.religion}</span>
                                      </p>
                                    </div>
                                    <div className="interest-personality-wrapper">
                                      <div className="interest">
                                        <div>
                                          <h4
                                            style={{
                                              textAlign: "start",
                                              width: "75px",
                                            }}
                                          >
                                            Interests:
                                          </h4>
                                        </div>
                                        <div className="interest-items">
                                          {bioInfo?.interests
                                            ?.slice(0, 3)
                                            .map(
                                              (interestItem, interestIndex) => (
                                                <span
                                                  key={interestIndex}
                                                  style={{
                                                    backgroundColor: "#fff265",
                                                    padding: "5px 15px",
                                                    borderRadius: "6px",
                                                  }}
                                                >
                                                  {interestItem}
                                                </span>
                                              )
                                            )}
                                        </div>
                                      </div>
                                      <div className="personality">
                                        <div>
                                          <h4
                                            style={{
                                              textAlign: "start",
                                              width: "75px",
                                            }}
                                          >
                                            Personality:{" "}
                                          </h4>
                                        </div>
                                        <div className="interest-items">
                                          {bioInfo?.personality
                                            ?.slice(0, 3)
                                            .map(
                                              (
                                                personalityItem,
                                                personalityIndex
                                              ) => (
                                                <span
                                                  key={personalityIndex}
                                                  style={{
                                                    backgroundColor: "#fff265",
                                                    padding: "5px 15px",
                                                    borderRadius: "6px",
                                                  }}
                                                >
                                                  {personalityItem}
                                                </span>
                                              )
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="btn-wrapper">
                                    {bioInfo?.request?.status === "SENT" && (
                                      <button
                                        style={{
                                          cursor: "pointer",
                                          backgroundColor: "#20caf9",
                                        }}
                                        // onClick={() => {
                                        //   handleCancelRequest(
                                        //     bioInfo.request.request_id
                                        //   );
                                        // }}
                                        disabled={true}
                                      >
                                        {/* Cancel Request */}
                                        Pending
                                      </button>
                                    )}
                                    {bioInfo?.request?.status ===
                                      "REJECTED" && (
                                      <Button
                                        style={{
                                          cursor: "not-allowed",
                                          backgroundColor: "#1cabff",
                                          color: "#fff",
                                          padding: "8px 20px",
                                          height: "auto",
                                          fontSize: "16px",
                                          borderRadius: "8px",
                                        }}
                                      >
                                        Rejected
                                      </Button>
                                    )}
                                    {bioInfo?.request?.status ===
                                      "RECEIVED" && (
                                      <div>
                                        <div style={{ marginBottom: "10px" }}>
                                          <button
                                            style={{
                                              cursor: "pointer",
                                              backgroundColor: "#25ce00",
                                            }}
                                            onClick={() => {
                                              handleAcceptRequest(
                                                bioInfo.request.request_id
                                              );
                                            }}
                                          >
                                            Accept
                                          </button>
                                        </div>
                                        <div>
                                          <button
                                            style={{
                                              cursor: "pointer",
                                              backgroundColor: "red !important",
                                            }}
                                            onClick={() => {
                                              handleRejectRequest(
                                                bioInfo.request.request_id
                                              );
                                            }}
                                          >
                                            Pass
                                          </button>
                                        </div>
                                      </div>
                                    )}

                                    {bioInfo?.request?.status ===
                                      "ACCEPTED" && (
                                      <Link
                                        style={{
                                          cursor: "pointer",
                                          backgroundColor: "#1cabff",
                                          color: "#fff",
                                          padding: "8px 20px",
                                          height: "auto",
                                          fontSize: "16px",
                                          borderRadius: "8px",
                                        }}
                                        to={`preview/${bioInfo.user_id}`}
                                        // onClick={() => {
                                        // 	handleViewBiodata(bioInfo.user_id);
                                        // }}
                                      >
                                        View Biodata
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div style={{ width: "100%" }}>
                          <div
                            style={{ display: "flex", width: "100%" }}
                            className="card-main"
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              className="spin-wrapper"
                            >
                              <h2>No Data Found !!!</h2>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {showTabRender === 2 && bioDataList.length > 0 && (
                      <div className="pagination">
                        <button
                          disabled={currentPage === 1 ? true : false}
                          onClick={() => {
                            setCurrentPage(currentPage - 1);
                            window.scrollTo(0, 0);
                          }}
                        >
                          Prev
                        </button>

                        {currentPage > 2 && (
                          <button
                            onClick={() => {
                              setCurrentPage(currentPage - 2);
                              window.scrollTo(0, 0);
                            }}
                          >
                            {currentPage - 2}
                          </button>
                        )}
                        {currentPage > 1 && (
                          <button
                            onClick={() => {
                              setCurrentPage(currentPage - 1);
                              window.scrollTo(0, 0);
                            }}
                          >
                            {currentPage - 1}
                          </button>
                        )}
                        <button
                          style={{
                            border: "1px solid #e2286d",
                          }}
                        >
                          {currentPage}
                        </button>
                        {totalPage > currentPage + 1 && (
                          <button
                            onClick={() => {
                              setCurrentPage(currentPage + 1);
                              window.scrollTo(0, 0);
                            }}
                          >
                            {currentPage + 1}
                          </button>
                        )}
                        {totalPage > currentPage + 2 && (
                          <button
                            onClick={() => {
                              setCurrentPage(currentPage + 2);
                              window.scrollTo(0, 0);
                            }}
                          >
                            {currentPage + 2}
                          </button>
                        )}
                        <button
                          disabled={currentPage === totalPage ? true : false}
                          onClick={() => {
                            setCurrentPage(currentPage + 1);
                            window.scrollTo(0, 0);
                          }}
                        >
                          Next
                        </button>
                      </div>
                    )}
                  </Fragment>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Analytics;
