import { Button, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "./TermsCondition.css";

const TermsCondition = () => {
  return (
    <Row className="terms">
      <Col
        xl={18}
        md={22}
        xs={24}
        style={{ margin: "50px auto 0 auto" }}
        className="container py-5"
      >
        <div
          className="terms_content mt-5 mx-auto"
          style={{ margin: "80px  auto 0 auto" }}
        >
          <div style={{ padding: "10px 0 20px 10px" }}>
            <Link to="/">
              <Button type="primary" size="largne">
                Back to Home
              </Button>
            </Link>
          </div>
          {/* ---------------- */}
          <div className="terms_text mt-4">
            <h3
              className="text-gray mb-3 title"
              style={{ margin: "20px 0", fontSize: "20px" }}
            >
              Terms & Conditions
            </h3>
            <p className="p-3" style={{ fontSize: "15px" }}>
              Digitalghotok.com (hereinafter refer as Digital Ghotok) is
              Bangladesh’s 1st AI-Based Matchmaking & Matrimony platform based
              on candidates’ personal choices, preferences, characteristics &
              more. This is a platform for the unmarried / Divorce / widower who
              are interested to visit this site and choose his/her life partner
              as he/she likes the best with his or her own taste <br />
              <br />
              Digital Ghotok will provide all possible assistance to the
              Government law enforcement agency or any other statutory
              investigation agency to tackle fraudulent users of Digital Ghotok,
              on being specifically instructed by the said authorities to do so.{" "}
              <br />
              <br />
              Access to the Digital Ghotok platform is free. However, the
              subscription and browsing fee may be modified from time to time by
              the authority. <br />
              <br />
              To register as a member of Digital Ghotok or use this platform,
              you must be legally competent and of legal marriageable age as per
              the laws of Bangladesh (currently, 18 years or over for females
              and 21 years or over for males). Digital Ghotok aims only to
              facilitate personal advertisement for lawful marriage alliance
              between persons who are legally competent to enter into matrimony
              under the laws to which they are subject. <br />
              <br />
              Users/members are responsible for maintaining the confidentiality
              of your login credentials. You designate during the registration
              process, and you are solely responsible for all the activities
              that occur under your account. You agree to immediately notify us
              of any disclosure or unauthorized use of your account or any other
              breach of security, and ensure that you log out from your account
              at the end of each session.
              <br />
              <br />
              This Agreement will be effective, valid and subsisting as long as
              you use our platform. <br />
              <br />
              You may terminate your profile at any time, for any reason
              whatsoever.
              <br />
              <br />
              This Agreement may be modified by Digital Ghotok authority from
              time to time. The membership and rights of admissions are
              reserved.
            </p>
            <h5 className="p-2" style={{ margin: "20px 0", fontSize: "18px" }}>
              Disclaimer :
            </h5>
            <p className="p-3" style={{ fontSize: "15px" }}>
              Authority is not liable for any false statement given by any user.
              Authority is not liable for any sort of illegal activities done by
              the user if any. Authorities will not take the responsibility for
              any kind of unwanted and unsocial/antisocial activity. Under no
              circumstances Digital Ghotok will be responsible for any loss or
              damage resulting from anyone’s use of the platform or the Service
              and/or any Content posted on the Digital Ghotok platform. You
              should not provide your financial information (for example, your
              credit card or bank account information), or otherwise send money
              to other Members. Digital Ghotok is not responsible for any
              incorrect or inaccurate Content/listing posted on the platform,
              whether caused by Users or Members whether online or offline.
              Authority is not liable for any kind of, any act or omission which
              was not done by them.
            </p>
            <h3
              className="text-gray mb-3 title"
              style={{ margin: "20px 0", fontSize: "20px" }}
            >
              Privacy Policy
            </h3>
            <p className="p-3">
              Digitalghotok.com is an online matchmaking & matrimony platform
              endeavoring/working constantly to provide you with matchmaking &
              matrimony services. For providing the services, we ask for certain
              personal information which is displayed on the site on behalf of
              you to find the perfect life partner. You hereby provide your
              consent to collect, process, and share your personal information
              in order to provide the service. Your privacy is important to us.
            </p>
            <ul className="order_list">
              <li>
                Digital Ghotok provides all possible assistance to the
                Government law enforcement agency or any other statutory
                investigation agency to tackle fraudulent users of Digital
                Ghotok, on being specifically instructed by the said authorities
                to do so.
              </li>
              <li>
                Digital Ghotok is an advertising platform providing targeted
                advertising services for matchmaking alliances and replaces the
                traditional newspaper classified. The service is for the
                personal use of individual members to advertise and promote
                their profiles for the purpose of finding their life partner and
                cannot be used in connection with any commercial endeavors
              </li>
              <li>
                While registering on our platform, you share with us your
                personal details, such as name, your gender, date of birth,
                contact details, educational qualification, employment details,
                photos, marital status and your interests etc.
              </li>
              <li>
                To register as a member/user of Digital Ghotok or use this
                platform, you must be legally competent and of legal
                marriageable age as per the laws of Bangladesh (currently, 18
                years or over for females and 21 years or over for male).
              </li>
              <li>
                When you avail of a paid service, you provide our payment
                service provider with information
              </li>
              <li>
                Testimonials submitted by you including your success story and
                photos.
              </li>
              <li>
                Information submitted by you voluntarily while participating in
                survey contests, promotions or events.
              </li>
              <li>
                Details shared with our team (This information used to monitor
                or for training purposes and to ensure a better service).
              </li>
              <li>
                We collect information about your activity on our services, such
                as date and time you logged in, features you have been using,
                searches, clicks and profiles visited by you
              </li>
              <li>
                We need SMS permission for authenticating transactions, sent by
                the Payment gateway
              </li>
              <li>
                You have an option to use your social media login (such as
                Facebook Login, also gmail login) to create and log into your
                Digital Ghotok account. This saves you from remembering one more
                login credentials and allows you to share some information from
                your social media account with us.
              </li>
              <li>
                We collect information for data analysis, identifying usage
                trends, determining the effectiveness of our promotional
                campaigns and to evaluate
              </li>
              <li>
                Improve our platform, products and services (if any), marketing
                research from our users primarily to ensure that we are able to
                fulfill your requirements and to deliver personalized experience
              </li>
              <li>
                Communicate with you by email, phone, about services, promotions
                or offers that may be of your interest.
              </li>
              <li>
                Recommend relevant matches to you and display your profile to
                other users.
              </li>
              <li>
                This “privacy policy” we do not sell rent share trade or give
                away any of your personal information.
              </li>
              <li>
                We publish the information shared by you with other users to
                provide the services. The information published is provided by
                you and be cautious as to what you share with other users; you
                can always control your privacy setting by visiting the
                “setting/privacy” option page.
              </li>
              <li>
                We will disclose your personally identifiable information as
                required by law and when we believe that disclosure is necessary
                to protect our rights, other members interest and protection
                and/or comply with a judicial proceeding, court order, or legal
                process served on our website/platform.
              </li>
              <li>
                Our website/platform links to other websites/platforms that may
                collect personally identifiable information about you. We are
                not responsible for the privacy policy or the contents of those
                linked websites/platforms.
              </li>
              <li>
                We keep your personal information only as long as you use our
                service and also as permitted/required by applicable law. In
                practice, we delete or anonymize your information upon deletion
                of your account, unless the same is required for to comply with
                legal obligations, fraud prevention, take actions we deem
                necessary to protect the integrity of our platform or our users,
                to resolve disputes, to enforce our agreements, to support
                business operations, and to continue to develop and improve our
                services. We retain information for better services, and we only
                use the information to analyze the use of our services, not to
                specifically analyze personal characteristics about you.
              </li>
              <li>
                While we implement safeguards designed to protect your
                information, no security system is impenetrable and due to the
                inherent nature of the internet, we cannot guarantee that data,
                during transmission through the internet or while stored on our
                systems or otherwise in our care, is absolutely safe from
                intrusion by others. When our registration/order process asks
                you to enter sensitive information (such as a credit card
                number), such information is encrypted and is protected with the
                best encryption software in the industry. We follow generally
                accepted industry standards to be protected with the best
                encryption software in the industry. We follow generally
                accepted industry standards to protect the personal information
                submitted to us. All your information, not just the sensitive
                information mentioned above is restricted in our offices. Only
                employees who need the information to perform a specific job are
                granted access to personally identifiable information.
              </li>
              <li>
                If we make a material change to our privacy policy, we send an
                email communication about the update so you are always aware of
                what information we collect, how we use it, and under what
                circumstances, if any we disclose it. If at any point we decide
                to use personally identifiable information in a manner
                materially different from that stated at the time it was
                collected, we will notify you by way of an email. We will give
                you a choice as to whether or not we can use information in this
                different manner and act accordingly. This agreement may be
                modified by Digitalghotok.com authority from time to time. The
                membership rights of admissions are reserved. If you have any
                question about this privacy statement, the practices of this
                site, or your dealings with this website, please email us or
                contact us. Digitalghotok.com Address
              </li>
            </ul>
          </div>
          {/* ---------------------- */}
        </div>
      </Col>
    </Row>
  );
};

export default TermsCondition;
