import {
  Button,
  Col,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import "./Explore.css";
import React, { Fragment, useEffect, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import { ftList, inchList } from "../Dashboard/Common/HeightInput/HeightInput";
import Navbar from "../Navbar/Navbar";
import imgHeight from "../../assets/dash/height.png";
import imgProfession from "../../assets/dash/profession.png";
import imgYears from "../../assets/dash/year.png";
import imgLocation from "../../assets/dash/location.png";
import districtList from "../../utils/districts";
import axios from "axios";
import { BASE_URL, BIO, BIODATA_REQUEST, PAYMENT } from "../../app/config";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setBioDataDetails } from "../../app/slices/multiForm";
import TimerPopup from "../TimerPopup/TimerPopup";
// import { packageInfo } from "../Payment/packageInfo";
import PaymentModal from "../Payment/PaymentModal";

const Explore = () => {
  const [filterData, setFilterData] = useState({});
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [bioDataList, setBioDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [apiPending, setApiPending] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [showPopUp, setShowPopUp] = useState(false);
  const [bioDataListInfo, setBioDataListInfo] = useState({
    total_biodata: 0,
    total_page: 0,
  });
  const [sendRequestPopup, setSendRequestPopup] = useState(false);
  const [sendReqText, setSendReqText] = useState(false);
  const [freeToShow, setFreeToShow] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { bioDataDetails } = useSelector((state) => state.multiForm);
  useEffect(() => {
    axios
      .get(BIO.GET_BIO_DATA, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        dispatch(setBioDataDetails(res.data));
      })
      .catch((err) => {
        console.log(err);
        navigate("/login", { replace: true });
      });
    fetch(
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/index.json"
    )
      .then((res) => res.json())
      .then((data) => setCountryList(data));
  }, []);

  useEffect(() => {
    if (bioDataDetails?.personal?.is_editable === true) {
      navigate("/biodata");
    } else {
      setIsLoading(true);

      axios
        .get(`${BASE_URL}/bio-data-list`, {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
            "Content-Type": "application/json",
          },
          params: {
            ...filterData,
            limit: 5,
            offset: (currentPage - 1) * 5,
          },
        })
        .then((res) => {
          setIsLoading(false);
          setBioDataList(res.data.data);
          setBioDataListInfo({
            ...bioDataListInfo,
            total_biodata: res.data.total_biodata,
            total_page: res.data.total_page,
          });
          setTotalPage(res.data.total_page);
          setFreeToShow(res.data.free_explore_page_count);
          setShowPopUp(false);
        })
        .catch((err) => {
          console.log(err);
          message.error(err.response.data.message || err.message);
          // setCurrentPage((prev) => prev - 1);
          setShowPopUp(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleFilterDataChange = (name, value) => {
    // console.log(name, value);
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };

  const handleFilterSearch = () => {
    setIsLoading(true);
    setIsLoading(true);

    axios
      .get(`${BASE_URL}/bio-data-list`, {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
          "Content-Type": "application/json",
        },
        params: { ...filterData, limit: 5, offset: (currentPage - 1) * 5 },
      })
      .then((res) => {
        setIsLoading(false);
        setBioDataList(res.data.data);
        setTotalPage(res.data.total_page);
        setCurrentPage(1);
        setBioDataListInfo({
          ...bioDataListInfo,
          total_biodata: res.data.total_biodata,
          total_page: res.data.total_page,
        });
        setIsFilterShow(false);
        setFreeToShow(res.data.free_explore_page_count);
        setShowPopUp(false);
      })
      .catch((err) => {
        console.log(err);
        setShowPopUp(true);
      });
  };

  const handleClearFilter = () => {
    setFilterData({});
    setIsLoading(true);

    axios
      .get(`${BASE_URL}/bio-data-list`, {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
          "Content-Type": "application/json",
        },
        params: { limit: 5, offset: (currentPage - 1) * 5 },
      })
      .then((res) => {
        setIsLoading(false);
        setBioDataList(res.data.data);
        setTotalPage(res.data.total_page);
        setBioDataListInfo({
          ...bioDataListInfo,
          total_biodata: res.data.total_biodata,
          total_page: res.data.total_page,
        });
        setIsFilterShow(false);
        setFreeToShow(res.data.free_explore_page_count);
        setShowPopUp(false);
      })
      .catch((err) => {
        console.log(err);
        setShowPopUp(true);
      });
  };

  const handleSendRequest = (user_id) => {
    setApiPending(true);
    axios
      .post(
        BIODATA_REQUEST.SEND_REQUEST,
        { user_id },
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        // res.data?.bio_data_request?.total_request_sent === 1 &&
        // setSendRequestPopup(true);
        // setSendReqText(false);
        setApiPending(false);
        fetchBioDataList();
      })
      .catch((err) => {
        console.log(err);
        setApiPending(false);
        message.error(err.response.data.error);
        setSendRequestPopup(true);
        setSendReqText(true);
        fetchBioDataList();
        // navigate("/login", { replace: true });
      });
  };
  const handleCancelRequest = (user_id) => {
    axios
      .post(
        BIODATA_REQUEST.CANCEL_SEND_REQUEST(user_id),
        {},
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        fetchBioDataList();
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.error);
        // navigate("/login", { replace: true });
      });
  };
  const handleAcceptRequest = (req_id) => {
    setApiPending(true);
    axios
      .post(
        BIODATA_REQUEST.ACCEPT_REQUEST(req_id),
        {},
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        setApiPending(false);
        fetchBioDataList();
      })
      .catch((err) => {
        console.log(err);
        setApiPending(false);
        setSendRequestPopup(true);
        message.error(err.response.data.error);
        // navigate("/login", { replace: true });
      });
  };
  const handleRejectRequest = (req_id) => {
    setApiPending(true);
    axios
      .post(
        BIODATA_REQUEST.REJECT_REQUEST(req_id),
        {},
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        setApiPending(false);
        fetchBioDataList();
      })
      .catch((err) => {
        console.log(err);
        setApiPending(false);
        message.error(err.response.data.error);
        // navigate("/login", { replace: true });
      });
  };

  const fetchBioDataList = () => {
    axios
      .get(`${BASE_URL}/bio-data-list`, {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
          "Content-Type": "application/json",
        },
        params: {
          ...filterData,
          limit: 5,
          offset: (currentPage - 1) * 5,
        },
      })
      .then((res) => {
        // setIsLoading(false);
        setBioDataList(res.data.data);
        setFreeToShow(res.data.free_explore_page_count);
        // setBioDataListInfo({
        // 	...bioDataListInfo,
        // 	total_biodata: res.data.total_biodata,
        // 	total_page: res.data.total_page,
        // });
        // setTotalPage(res.data.total_page);
        setShowPopUp(false);
      })
      .catch((err) => {
        console.log(err);
        setShowPopUp(true);
      });
  };

  const [verifyClicked, setVerifyClicked] = useState(false);
  const [verifyMessage, setVerifyMessage] = useState("");
  const [verifyLoading, setVerifyLoading] = useState(false);
  const handleVerifyEmail = () => {
    setVerifyLoading(true);
    axios(`${BIO.VERIFY}`, {
      headers: {
        Authorization: `Bearer ${user.access_token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        // console.log(res.data);
        setVerifyLoading(false);
        setVerifyClicked(true);
        setVerifyMessage(res.data.message);
        message.success(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //Packages
  const [packages, setPackages] = useState([]);
  useEffect(() => {
    axios(`${PAYMENT.PACKAGES}`)
      .then((res) => {
        setPackages(res.data.data);
        // console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [sendRequestPopup]);

  return (
    <section
      style={{
        backgroundColor: "white",
        position: "relative",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <Navbar />
      <Modal
        // title="Set Profile Picture"
        open={
          bioDataDetails?.personal?.is_email_verified === false ? true : false
        }
        closable={false}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "10px",
            padding: "20px 0",
          }}
        >
          <h4 style={{ textAlign: "center", fontSize: "18px" }}>
            {verifyClicked
              ? "Go to your email inbox to Verify"
              : "Please Verify account with your Email Address to Explore your partner."}
          </h4>
          {verifyClicked ? (
            <div>
              <h4 style={{ padding: "20px 0", color: "#d30271" }}>
                {verifyMessage}
              </h4>
              <Link to="/biodata" style={{ marginTop: "20px" }}>
                Back to Biodata
              </Link>
            </div>
          ) : (
            <Button
              style={{
                backgroundColor: "green",
                color: "white",
                fontWeight: "600",
              }}
              size="large"
              onClick={handleVerifyEmail}
              disabled={verifyLoading}
            >
              Verify
            </Button>
          )}
        </div>
      </Modal>
      {(currentPage > freeToShow &&
        bioDataDetails?.package_info?.package_expiration_time === null) ||
      showPopUp ? (
        <TimerPopup
          isTutorial="pagination"
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <div
          style={{
            paddingTop: "80px",
            backgroundColor: "#F4F0F0",
            minHeight: "100vh",
            height: "100%",
          }}
        >
          <Row
            align="top"
            justify="center"
            className="container explore-wrapper"
          >
            <Col xxl={16} xl={22} lg={23} md={23} xs={24}>
              <Row style={{ paddingBottom: "20px" }}>
                <button
                  className="filter-btn"
                  // onLoad={() => setFilterData(false)}
                  onClick={() => setIsFilterShow(!isFilterShow)}
                >
                  <FaFilter /> <span>Filter</span>
                </button>
              </Row>
              <Row
                align="top"
                justify="start"
                gutter={{ xs: 0, sm: 0, md: 10, lg: 15, xl: 15, xxl: 30 }}
                className="row-wrapper-main"
              >
                <Col
                  xxl={5}
                  xl={5}
                  lg={5}
                  md={5}
                  className={`filter-wrapper ${
                    isFilterShow ? "filter-show" : "filter-hide"
                  }`}
                  // style={{ display: isFilterShow ? "flex" : "none" }}
                >
                  <div
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "white",
                      width: "100%",
                    }}
                  >
                    <div
                      className="filter-top"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "15px 3px",
                        borderBottom: "1px solid #E0BBBB",
                      }}
                    >
                      <h2
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "400",
                          fontSize: "20px",
                          lineHeight: "24px",
                          color: "#E1136E",
                        }}
                      >
                        Filters By
                      </h2>
                    </div>
                    <Row
                      className="filter-main"
                      style={{ padding: "15px 3px" }}
                    >
                      <Col span={24}>
                        {/*<div
										className="filter-item"
										style={{ paddingBottom: "15px" }}
									>
										<h4>Looking For?</h4>
										<Radio.Group
											name="looking_for"
											value={filterData?.looking_for}
											className="radio-wrapper"
											onChange={(e) =>
												handleFilterDataChange(
													e.target.name,
													e.target.value
												)
											}
										>
											<Radio value="female">Bride</Radio>
											<Radio value="male">Groom</Radio>
										</Radio.Group>
									</div>*/}
                        <div
                          className="filter-item"
                          style={{ paddingBottom: "15px" }}
                        >
                          <h4>Age</h4>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              gap: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                gap: "5px",
                              }}
                            >
                              <label htmlFor="from">From</label>
                              <InputNumber
                                name="age_from"
                                size="medium"
                                value={filterData?.age_from}
                                style={{ maxWidth: "70px", width: "100%" }}
                                onChange={(value) =>
                                  handleFilterDataChange("age_from", value)
                                }
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                gap: "5px",
                              }}
                            >
                              <label htmlFor="to">To</label>
                              <InputNumber
                                name="age_to"
                                value={filterData?.age_to}
                                style={{ maxWidth: "70px", width: "100%" }}
                                onChange={(value) =>
                                  handleFilterDataChange("age_to", value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="filter-item"
                          style={{ paddingBottom: "15px" }}
                        >
                          <h4>Height</h4>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                gap: "8px",
                                paddingBottom: "10px",
                              }}
                            >
                              <label
                                htmlFor="heightFrom"
                                style={{ width: "30px" }}
                              >
                                From
                              </label>
                              <Select
                                placeholder="feet"
                                size="medium"
                                style={{ maxWidth: "70px", width: "100%" }}
                                options={ftList}
                                value={filterData?.height_from_feet}
                                onChange={(value) =>
                                  handleFilterDataChange(
                                    "height_from_feet",
                                    value
                                  )
                                }
                              />
                              <Select
                                placeholder="inch"
                                size="medium"
                                style={{ maxWidth: "70px", width: "100%" }}
                                value={filterData?.height_from_inch}
                                options={inchList}
                                onChange={(value) =>
                                  handleFilterDataChange(
                                    "height_from_inch",
                                    value
                                  )
                                }
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                gap: "8px",
                              }}
                            >
                              <label
                                htmlFor="heightFrom"
                                style={{ width: "30px" }}
                              >
                                To
                              </label>
                              <Select
                                placeholder="feet"
                                size="medium"
                                style={{ maxWidth: "70px", width: "100%" }}
                                value={filterData?.height_to_feet}
                                options={ftList}
                                onChange={(value) =>
                                  handleFilterDataChange(
                                    "height_to_feet",
                                    value
                                  )
                                }
                              />
                              <Select
                                placeholder="inch"
                                size="medium"
                                style={{ maxWidth: "70px", width: "100%" }}
                                options={inchList}
                                value={filterData?.height_to_inch}
                                onChange={(value) =>
                                  handleFilterDataChange(
                                    "height_to_inch",
                                    value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="filter-item"
                          style={{ paddingBottom: "15px" }}
                        >
                          <h4>Marital status</h4>
                          <Select
                            placeholder="Click to select"
                            style={{ textTransform: "capitalize" }}
                            options={[
                              { key: "Never married", value: "Never married" },
                              { key: "Divorced", value: "Divorced" },
                              { key: "Widowed", value: "Widowed" },
                            ]}
                            value={filterData?.marital_status}
                            onChange={(value) =>
                              handleFilterDataChange(
                                "marital_status",
                                value?.toLowerCase()
                              )
                            }
                          />
                        </div>
                        <div
                          className="filter-item"
                          style={{ paddingBottom: "15px" }}
                        >
                          <h4>Religion</h4>
                          <Select
                            placeholder="Click to select"
                            style={{ textTransform: "capitalize" }}
                            options={[
                              { key: "Islam", value: "Islam" },
                              { key: "Hindu", value: "Hindu" },
                              { key: "Christian", value: "Christian" },
                              { key: "Buddhist", value: "Buddhist" },
                              { key: "Other", value: "Other" },
                            ]}
                            value={filterData?.religion}
                            onChange={(value) =>
                              handleFilterDataChange(
                                "religion",
                                value?.toLowerCase()
                              )
                            }
                          />
                        </div>
                        <div
                          className="filter-item"
                          style={{ paddingBottom: "15px" }}
                        >
                          <h4>Open to Move Abroad</h4>
                          <Radio.Group
                            name="radiogroup"
                            // defaultValue={1}
                            className="radio-wrapper"
                            value={filterData?.open_to_move_abroad}
                            onChange={(e) => {
                              handleFilterDataChange(
                                "open_to_move_abroad",
                                e.target.value
                              );
                            }}
                          >
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                            <Radio value="May be">May be</Radio>
                          </Radio.Group>
                        </div>
                        <div
                          className="filter-item"
                          // style={{ paddingBottom: "20px" }}
                        >
                          <h4>Location</h4>
                          <div>
                            <label
                              htmlFor=""
                              style={{ display: "block", fontWeight: "500" }}
                            >
                              Bangladesh
                            </label>
                            <Select
                              placeholder="Select City"
                              style={{ textTransform: "capitalize" }}
                              options={districtList.map((item) => {
                                return {
                                  key: item.name,
                                  value: item.name,
                                };
                              })}
                              value={filterData?.city}
                              onChange={(value) =>
                                handleFilterDataChange(
                                  "city",
                                  value?.toLowerCase()
                                )
                              }
                            />
                          </div>
                          <div>
                            <label
                              htmlFor=""
                              style={{
                                display: "block",
                                fontWeight: "500",
                                paddingTop: "10px",
                              }}
                            >
                              International
                            </label>
                            <Select
                              placeholder="Select Country"
                              style={{ textTransform: "capitalize" }}
                              options={countryList.map((item) => {
                                return {
                                  key: item.name,
                                  value: item.name,
                                };
                              })}
                              value={filterData?.country}
                              onChange={(value) =>
                                handleFilterDataChange(
                                  "country",
                                  value?.toLowerCase()
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="filter-btn-wrapper">
                          <button
                            className="clear-btn"
                            onClick={handleClearFilter}
                          >
                            Clear
                          </button>
                          <button
                            className="search-btn"
                            onClick={handleFilterSearch}
                          >
                            Search
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col
                  xxl={19}
                  xl={19}
                  lg={19}
                  md={22}
                  sm={23}
                  xs={23}
                  style={{ margin: "0 auto" }}
                >
                  {/*replace location */}
                  {isLoading ? (
                    <div style={{ width: "100%" }}>
                      <div
                        style={{ display: "flex", width: "100%" }}
                        className="card-main"
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className="spin-wrapper"
                        >
                          <Spin size="large" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Fragment>
                      <div
                        className="card-wrapper-scroll"
                        // style={{
                        // 	maxHeight: "80vh",
                        // 	overflowY: "auto",
                        // 	paddingRight: "5px",
                        // }}
                      >
                        {bioDataList.length > 0 ? (
                          bioDataList.map((bioInfo, index) => (
                            <div
                              key={index}
                              className={`${
                                bioDataDetails?.personal?.gender?.toLowerCase() ===
                                "female"
                                  ? "card-wrapper-male"
                                  : "card-wrapper-female"
                              }`}
                              style={{
                                width: "100%",
                                padding: "8px 0",
                              }}
                            >
                              <div
                                className="card-main"
                                style={{
                                  position: "relative",
                                  zIndex: "10",
                                }}
                              >
                                <div className="card-left">
                                  <div className="card-img-wrapper">
                                    <img
                                      src={bioInfo?.image}
                                      alt=""
                                      className="card-img"
                                      style={{
                                        borderRadius: "10px",
                                      }}
                                    />
                                    {bioInfo?.is_match_found && (
                                      <div className="card-img-match-found">
                                        <h4>Already Matched</h4>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="card-right">
                                  <div className="card-head">
                                    <div className="title">
                                      <h2>{bioInfo?.display_name}</h2>
                                      {bioInfo?.is_verified && (
                                        <BsFillCheckCircleFill className="verify-icon" />
                                      )}
                                    </div>
                                    <div className="managed-by">
                                      <p>
                                        <span>Profile Managed By: </span>
                                        <span>{bioInfo?.managed_by}</span>
                                      </p>
                                    </div>
                                    <div className="icon-item-wrapper">
                                      <div className="top-icon-item">
                                        <img src={imgProfession} alt="" />
                                        <h4>{bioInfo?.profession}</h4>
                                      </div>
                                      <div className="top-icon-item">
                                        <img src={imgHeight} alt="" />
                                        <h4>
                                          {bioInfo?.height_feet}'{" "}
                                          {bioInfo?.height_inch}"
                                        </h4>
                                      </div>
                                      <div className="top-icon-item">
                                        <img src={imgYears} alt="" />
                                        <h4>{bioInfo?.age} Yrs</h4>
                                      </div>
                                      <div className="top-icon-item">
                                        <img src={imgLocation} alt="" />
                                        <h4>{bioInfo?.location}</h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="info-wrapper">
                                      <div
                                        className="marital-religion"
                                        style={{ padding: "10px 0" }}
                                      >
                                        <p>
                                          <span>Marital Status:</span>{" "}
                                          <span>{bioInfo?.marital_status}</span>
                                        </p>
                                        <p>
                                          <span>Religion:</span>{" "}
                                          <span>{bioInfo?.religion}</span>
                                        </p>
                                      </div>
                                      <div className="interest-personality-wrapper">
                                        <div className="interest">
                                          <div>
                                            <h4
                                              style={{
                                                textAlign: "start",
                                                width: "75px",
                                              }}
                                            >
                                              Interests:
                                            </h4>
                                          </div>
                                          <div className="interest-items">
                                            {bioInfo?.interests
                                              ?.slice(0, 3)
                                              .map(
                                                (
                                                  interestItem,
                                                  interestIndex
                                                ) => (
                                                  <span
                                                    key={interestIndex}
                                                    style={{
                                                      backgroundColor:
                                                        "#fff265",
                                                      padding: "5px 15px",
                                                      borderRadius: "6px",
                                                    }}
                                                  >
                                                    {interestItem}
                                                  </span>
                                                )
                                              )}
                                          </div>
                                        </div>
                                        <div className="personality">
                                          <div>
                                            <h4
                                              style={{
                                                textAlign: "start",
                                                width: "75px",
                                              }}
                                            >
                                              Personality:{" "}
                                            </h4>
                                          </div>
                                          <div className="interest-items">
                                            {bioInfo?.personality
                                              ?.slice(0, 3)
                                              .map(
                                                (
                                                  personalityItem,
                                                  personalityIndex
                                                ) => (
                                                  <span
                                                    key={personalityIndex}
                                                    style={{
                                                      backgroundColor:
                                                        "#fff265",
                                                      padding: "5px 15px",
                                                      borderRadius: "6px",
                                                    }}
                                                  >
                                                    {personalityItem}
                                                  </span>
                                                )
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="btn-wrapper">
                                      {bioInfo?.request?.status === "SENT" && (
                                        <button
                                          style={{
                                            cursor: "pointer",
                                            backgroundColor: "#20caf9",
                                          }}
                                          // onClick={() => {
                                          //   handleCancelRequest(
                                          //     bioInfo.request.request_id
                                          //   );
                                          // }}
                                          disabled={true}
                                        >
                                          Pending
                                        </button>
                                      )}
                                      {bioInfo?.request?.status ===
                                        "RECEIVED" && (
                                        <div>
                                          <div style={{ marginBottom: "10px" }}>
                                            <button
                                              style={{
                                                cursor: apiPending
                                                  ? "not-allowed"
                                                  : "pointer",
                                                backgroundColor: "#25ce00",
                                              }}
                                              onClick={() => {
                                                handleAcceptRequest(
                                                  bioInfo.request.request_id
                                                );
                                              }}
                                              disabled={apiPending}
                                            >
                                              Accept
                                            </button>
                                          </div>
                                          <div>
                                            <button
                                              style={{
                                                cursor: apiPending
                                                  ? "not-allowed"
                                                  : "pointer",
                                                backgroundColor:
                                                  "red !important",
                                              }}
                                              onClick={() => {
                                                handleRejectRequest(
                                                  bioInfo.request.request_id
                                                );
                                              }}
                                              disabled={apiPending}
                                            >
                                              Pass
                                            </button>
                                          </div>
                                        </div>
                                      )}

                                      {bioInfo?.request?.status ===
                                        "ACCEPTED" && (
                                        <Link
                                          style={{
                                            cursor: "pointer",
                                            backgroundColor: "#1cabff",
                                            color: "#fff",
                                            padding: "8px 20px",
                                            height: "auto",
                                            fontSize: "16px",
                                            borderRadius: "8px",
                                          }}
                                          to={`preview/${bioInfo.user_id}`}
                                          // onClick={() => {
                                          // 	handleViewBiodata(bioInfo.user_id);
                                          // }}
                                        >
                                          View Biodata
                                        </Link>
                                      )}
                                      {bioInfo?.request?.status ===
                                        "REJECTED" && (
                                        <Button
                                          style={{
                                            cursor: "not-allowed",
                                            backgroundColor: "#1cabff",
                                            color: "#fff",
                                            padding: "8px 20px",
                                            height: "auto",
                                            fontSize: "16px",
                                            borderRadius: "8px",
                                          }}
                                          // onClick={() => {
                                          // 	handleViewBiodata(bioInfo.user_id);
                                          // }}
                                        >
                                          Rejected
                                        </Button>
                                      )}
                                      {bioInfo?.is_match_found === false &&
                                        bioInfo?.request === null && (
                                          <button
                                            style={{
                                              backgroundColor: "#d60040",
                                              cursor: apiPending
                                                ? "not-allowed"
                                                : "pointer",
                                            }}
                                            onClick={() => {
                                              handleSendRequest(
                                                bioInfo.user_id
                                              );
                                            }}
                                            disabled={apiPending}
                                          >
                                            Send Request
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div style={{ width: "100%" }}>
                            <div
                              style={{ display: "flex", width: "100%" }}
                              className="card-main"
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                className="spin-wrapper"
                              >
                                <h2>No Data Found !!!</h2>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {bioDataList.length > 0 && (
                        <div className="pagination">
                          <button
                            disabled={currentPage === 1 ? true : false}
                            onClick={() => {
                              setCurrentPage(currentPage - 1);
                              window.scrollTo(0, 0);
                            }}
                          >
                            Prev
                          </button>
                          {/*[...Array(totalPage)].map((bioItem, bioIndex) => (
													<button
														key={bioIndex}
														style={{
															border:
																currentPage === bioIndex + 1
																	? "1px solid #e2286d"
																	: "none",
														}}
														onClick={() => {
															setCurrentPage(bioIndex + 1);
															window.scrollTo(0, 0);
														}}
													>
														{bioIndex + 1}
													</button>
												))*/}
                          {currentPage > 2 && (
                            <button
                              onClick={() => {
                                setCurrentPage(currentPage - 2);
                                window.scrollTo(0, 0);
                              }}
                            >
                              {currentPage - 2}
                            </button>
                          )}
                          {currentPage > 1 && (
                            <button
                              onClick={() => {
                                setCurrentPage(currentPage - 1);
                                window.scrollTo(0, 0);
                              }}
                            >
                              {currentPage - 1}
                            </button>
                          )}
                          <button
                            style={{
                              border: "1px solid #e2286d",
                            }}
                            // onClick={() => {
                            // 	setCurrentPage(bioIndex + 1);
                            // 	window.scrollTo(0, 0);
                            // }}
                          >
                            {currentPage}
                          </button>
                          {totalPage > currentPage + 1 && (
                            <button
                              // disabled={currentPage + 1 >= 5 ? true : false}
                              onClick={() => {
                                // currentPage + 1 <= 5 &&
                                setCurrentPage(currentPage + 1);
                                window.scrollTo(0, 0);
                              }}
                            >
                              {currentPage + 1}
                            </button>
                          )}
                          {totalPage > currentPage + 2 && (
                            <button
                              // disabled={currentPage + 2 >= 5 ? true : false}
                              onClick={() => {
                                // currentPage + 2 <= 5 &&
                                setCurrentPage(currentPage + 2);
                                window.scrollTo(0, 0);
                              }}
                            >
                              {currentPage + 2}
                            </button>
                          )}
                          <button
                            disabled={currentPage === totalPage ? true : false}
                            // disabled={currentPage >= 5 ? true : false}
                            onClick={() => {
                              // currentPage + 1 <= 5 &&
                              setCurrentPage(currentPage + 1);
                              window.scrollTo(0, 0);
                            }}
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </Fragment>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Modal
            // title="Send Request"
            open={sendRequestPopup}
            onOk={() => setSendRequestPopup(false)}
            onCancel={() => setSendRequestPopup(false)}
            style={{ width: "80%" }}
            className="send-request-modal"
            width="100%"
          >
            {/* <h4 style={{ fontWeight: "400", fontSize: "25px" }}>
              {sendReqText
                ? "Your Free Plan is Over. Pricing Plan coming soon!"
                : "You can send request upto 5 profiles for free; please use it wisely"}
            </h4> */}
            <PaymentModal />
            {/* <div>
              <h2
                style={{
                  color: "#e2286d",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                ** Please note that there is no refund policy once the payment
                is completed.
              </h2>
            </div> */}
          </Modal>
        </div>
      )}
    </section>
  );
};

export default Explore;
