import { Checkbox, Col, Row } from "antd";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { setRelativesFormData } from "../../../../app/slices/multiForm";
import InputCommon from "../../Common/Input/InputCommon";
import SelectCommon from "../../Common/Select/SelectCommon";
import { AiOutlineCloseCircle, AiOutlinePlus } from "react-icons/ai";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BIO } from "../../../../app/config";

const relativesInitData = {
	number_of_relatives: 1,
	relatives: [
		{
			id: uuidv4(),
			relative_type: "",
			relation: "",
			full_name: "",
			profession: "",
			location: "",
			isMarried: false,
		},
	],
};
const RelativesInformation = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { user } = useSelector((state) => state.auth);
	const { relativesFormData, personalFormData } = useSelector(
		(state) => state.multiForm
	);
	useEffect(() => {
		axios
			.get(BIO.GET_BIO_DATA, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				// dispatch(setEducationFormData(res.data?.education));
				if (res.data.relatives.length > 0) {
					// console.log(res.data.relatives);
					let number_of_siblings = res.data.relatives.length;
					let relatives = res.data.relatives.map((sibItem) => {
						return {
							id: sibItem.id,
							relative_type: sibItem.relative_types,
							full_name: sibItem.full_name,
							profession: sibItem.profession,
							relation: sibItem.relation,
							location: sibItem.location,
							isMarried: sibItem.married ? true : false,
							spouse_name: sibItem.spouse_name,
							spouse_profession: sibItem.spouse_profession,
						};
					});
					dispatch(setRelativesFormData({ number_of_siblings, relatives }));
				} else {
					dispatch(setRelativesFormData(relativesInitData));
				}
			})
			.catch((err) => {
				console.log(err);
				navigate("/login", { replace: true });
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleChangeNumberOfRelative = (value) => {
		let number_of_relatives = value;
		let relatives = [];
		for (let i = 0; i < number_of_relatives; i++) {
			if (relativesFormData.relatives.length > i) {
				relatives.push(relativesFormData.relatives[i]);
			} else {
				relatives.push({
					id: uuidv4(),
					relative_type: "",
					relation: "",
					full_name: "",
					profession: "",
					location: "",
					isMarried: false,
				});
			}
		}
		dispatch(setRelativesFormData({ number_of_relatives, relatives }));
	};

	const handleRelativeAdd = (e) => {
		e.preventDefault();
		dispatch(
			setRelativesFormData({
				number_of_relatives: relativesFormData?.relatives.length + 1,
				relatives: [
					...relativesFormData.relatives,
					{
						id: uuidv4(),
						relative_type: "",
						relation: "",
						full_name: "",
						profession: "",
						location: "",
						isMarried: false,
					},
				],
			})
		);
	};
	const handleRelativeRemove = (id, e) => {
		e.preventDefault();
		dispatch(
			setRelativesFormData({
				number_of_relatives: relativesFormData?.relatives.length - 1,
				relatives: relativesFormData.relatives.filter((i) => i.id !== id),
			})
		);
	};
	const handleMarriedStatus = (item, value) => {
		// console.log({ id, value });
		// let targetSib = siblingFormData?.relatives.filter((i) => i.id === id);
		let totalSibs = [];
		for (let i = 0; i < relativesFormData.relatives.length; i++) {
			const sib = relativesFormData.relatives[i];
			if (sib.id === item.id) {
				if (value === true) {
					console.log(sib);
					totalSibs.push({
						...sib,
						isMarried: true,
						spouse_name: item?.spouse_name,
						spouse_profession: item?.spouse_profession,
					});
				} else {
					totalSibs.push({
						...sib,
						isMarried: false,
					});
				}
			} else {
				totalSibs.push({ ...sib });
			}
		}
		// console.log(totalSibs);
		dispatch(
			setRelativesFormData({
				...relativesFormData,
				relatives: totalSibs,
			})
		);
	};
	const handleChangeData = (key, value, sibId) => {
		let sibList = [];
		for (let i = 0; i < relativesFormData.relatives.length; i++) {
			const sib = relativesFormData.relatives[i];
			if (sib.id === sibId) {
				sibList.push({ ...sib, [key]: value });
			} else {
				sibList.push(sib);
			}
		}
		dispatch(
			setRelativesFormData({ ...relativesFormData, relatives: sibList })
		);
	};

	return (
		<Fragment>
			{/*<Row gutter={[20, 20]} className="row">
				<Col md={6} sm={24}>
					<InputCommon
						name="number_of_relatives"
						label="No of relatives"
						id="relatives"
						handleChange={handleChangeNumberOfSibling}
						value={siblingFormData?.number_of_relatives}
						type="number"
					/>
				</Col>
			</Row> */}

			<Row gutter={[20, 30]}>
				{relativesFormData?.relatives?.map((sib, sibIndex) => (
					<Col key={sibIndex} md={12} sm={12} xs={24}>
						<div
							style={{
								width: "100%",
								padding: "20px 10px",
								borderRadius: "8px",
								boxShadow: "2px 2px 6px gray",
							}}
						>
							<div style={{ display: "flex", justifyContent: "end" }}>
								<button
									onClick={(e) => handleRelativeRemove(sib.id, e)}
									style={{
										border: "none",
										backgroundColor: "transparent",
										color: "red",
										fontSize: "25px",
									}}
								>
									<AiOutlineCloseCircle />
								</button>
							</div>
							<Row gutter={[10, 10]}>
								<Col md={12} sm={12} xs={24}>
									<SelectCommon
										name="relative_type"
										label="Maternal/Paternal"
										parentName={sib.id}
										handleChangeFunc={handleChangeData}
										// onChange={(e) =>
										//   handleChangeData(sib.id, "sibling_type", e.target.value)
										// }
										value={sib?.relative_type}
										// levelIcon={<FaUserCircle style={{ color: "#e2286d" }} />}
										options={[
											{ key: 2, value: "Paternal" },
											{ key: 3, value: "Maternal" },
										]}
									/>
								</Col>
								<Col md={12} sm={12} xs={24}>
									<InputCommon
										name="relation"
										label="Relation"
										parentName={sib.id}
										handleChangeFunc={handleChangeData}
										// onChange={(e) =>
										//   handleChangeData(sib.id, "full_name", e.target.value)
										// }
										value={sib?.relation}
										type="text"
										isRequired={false}
									/>
								</Col>
								<Col md={12} sm={12} xs={24}>
									<InputCommon
										name="full_name"
										label="Full Name"
										parentName={sib.id}
										handleChangeFunc={handleChangeData}
										// onChange={(e) =>
										//   handleChangeData(sib.id, "full_name", e.target.value)
										// }
										value={sib?.full_name}
										type="text"
										isRequired={false}
									/>
								</Col>
								<Col md={12} sm={12} xs={24}>
									<InputCommon
										name="profession"
										label="Profession"
										parentName={sib.id}
										handleChangeFunc={handleChangeData}
										// onChange={(e) =>
										//   handleChangeData(sib.id, "profession", e.target.value)
										// }
										value={sib?.profession}
										type="text"
										isRequired={false}
									/>
								</Col>
								<Col md={12} sm={12} xs={24}>
									<InputCommon
										name="location"
										label="Location"
										parentName={sib.id}
										handleChangeFunc={handleChangeData}
										// onChange={(e) =>
										//   handleChangeData(sib.id, "location", e.target.value)
										// }
										value={sib?.location}
										type="text"
										isRequired={false}
									/>
								</Col>
								<Col
									md={12}
									sm={12}
									xs={24}
									style={{
										paddingLeft: "10px",
										textAlign: "left",
										marginBottom: "20px",
									}}
								>
									<Checkbox
										onChange={(e) => handleMarriedStatus(sib, e.target.checked)}
										defaultChecked={sib?.isMarried}
										style={{ fontSize: "16px" }}
									>
										Married
									</Checkbox>
								</Col>
								{sib?.isMarried && (
									<Fragment>
										<Col md={12} sm={12} xs={24}>
											<InputCommon
												name="spouse_name"
												label="Spouse Name"
												parentName={sib.id}
												handleChangeFunc={handleChangeData}
												// onChange={(e) =>
												//   handleChangeData(sib.id, "spouse_name", e.target.value)
												// }
												value={sib?.spouse_name}
												type="text"
												isRequired={false}
											/>
										</Col>
										<Col md={12} sm={12} xs={24}>
											<InputCommon
												name="spouse_profession"
												label="Profession"
												parentName={sib.id}
												handleChangeFunc={handleChangeData}
												// onChange={(e) =>
												//   handleChangeData(sib.id, "spouse_profession", e.target.value)
												// }
												value={sib?.spouse_profession}
												type="text"
												isRequired={false}
											/>
										</Col>
									</Fragment>
								)}
							</Row>
						</div>
					</Col>
				))}
				<div
					className="inline my-4"
					style={{ width: "140px", paddingLeft: "15px" }}
				>
					<button
						style={{
							padding: "10px 15px",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							gap: "5px",
							border: "1px solid transparent",
							borderRadius: "8px",
							fontSize: "16px",
							fontWeight: "600",
							color: "#fff",
							backgroundColor:
								personalFormData?.gender?.toLowerCase() === "female"
									? "#ce4087"
									: "#1885F2",
						}}
						onClick={(e) => handleRelativeAdd(e)}
					>
						<span>Add Field</span>
						<AiOutlinePlus style={{ fontSize: "20px" }} />
					</button>
				</div>
			</Row>
		</Fragment>
	);
};

export default RelativesInformation;
