import React, { Fragment, useEffect, useState } from "react";
import "./Preview.css";
import professionIcon from "../../assets/dash/profession.jpg";
import yearIcon from "../../assets/dash/year.jpg";
import heightIcon from "../../assets/dash/height.jpg";
import locationIcon from "../../assets/dash/location.jpg";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, BIO } from "../../app/config";
import { Col, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import TimerPopup from "../TimerPopup/TimerPopup";
const PreviewOthers = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [bioDetails, setBioDetails] = useState({});
  const [currentImage, setCurrentImage] = useState();
  const [countriesData, setCountriesData] = useState([]);
  const { userId } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    user &&
      axios
        .get(`${BIO.GET_BIO_DATA}?user_id=${userId}`, {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log(res.data);
          setBioDetails(res.data);
          // dispatch(setBioDataDetails(res.data));
          if (res.data?.images.length > 0) {
            setCurrentImage(res.data.images[0]);
          }
        })
        .catch((err) => {
          console.log(err);
          navigate("/login", { replace: true });
        });

    axios(`${BASE_URL}/countries`)
      .then((res) => setCountriesData(res.data))
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    personal,
    professional,
    partner_expectation,
    siblings,
    relatives,
    interest,
    personality,
    images,
  } = bioDetails;
  //"https://swiperjs.com/demos/images/nature-1.jpg"

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div
      style={{
        backgroundColor: "white",
        position: "relative",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      {<TimerPopup isTutorial="contact" />}
      <Navbar />
      <Row align="bottom" justify="center" className="container ">
        <Col lg={20} md={20} sm={24} xs={24}>
          <div
            style={{ marginTop: "100px", marginBottom: "40px", padding: "5px" }}
          >
            <p style={{ fontWeight: "bold", fontSize: "18px" }}>
              <span style={{ color: "black" }}>Awerness: </span>{" "}
              <span style={{ color: "red" }}>
                Please make sure to do a proper background check with the help
                of your family members before taking any final decision.
              </span>
            </p>
          </div>
        </Col>
      </Row>

      <Row align="bottom" justify="center" className="container ">
        <Col
          lg={20}
          md={20}
          sm={24}
          xs={24}
          className="preview_container"
          style={{
            padding: "40px 10px",
            backgroundColor:
              bioDetails?.personal?.gender.toLowerCase() === "female"
                ? "#ffacd0"
                : "lightblue",
          }}
        >
          <div className="preview_header_info">
            <Row
              align="top"
              justify="center"
              gutter={[20, 10]}
              style={{ padding: "10px 20px" }}
            >
              {bioDetails?.images?.length > 0 && (
                <Col
                  md={10}
                  xs={24}
                  style={
                    {
                      // padding: "10px 30px",
                      // maxHeight: "400px",
                      // height: "100%",
                    }
                  }
                >
                  {
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "90%",
                          margin: "10px auto",
                        }}
                      >
                        <img
                          src={currentImage.image}
                          alt=""
                          className="display-image"
                          style={{
                            maxWidth: "540px",
                            maxHeight: "340px",
                            height: "100%",
                            objectFit: "cover",
                            width: "100%",
                            borderRadius: "12px",
                            boxShadow: "2px 4px 10px gray",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "start",
                          gap: "10px",
                        }}
                      >
                        {images?.map((imgItem) => (
                          <div
                            key={imgItem.id}
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                            onClick={() => setCurrentImage(imgItem)}
                          >
                            <img
                              src={imgItem.image}
                              alt="slide img"
                              style={{
                                maxWidth: "160px",
                                width: "100%",
                                maxHeight: "70px",
                                height: "100%",
                                objectFit: "cover",
                                border:
                                  imgItem.id === currentImage.id
                                    ? "3px solid #e2286d"
                                    : "3px solid #c9c9c9",
                                borderRadius: "10px",
                              }}
                            />
                            {imgItem?.is_profile ? (
                              <span style={{ color: "#e2286d" }}>
                                Active Profile Picture
                              </span>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  }
                </Col>
              )}
              <Col md={14} xs={24}>
                <Row align="bottom">
                  <Col md={24} xs={24} className="mt-5 pt-5 user_name">
                    <h1
                      className="text-white"
                      style={{ fontSize: "26px", textTransform: "capitalize" }}
                    >
                      {personal?.full_name}
                    </h1>
                  </Col>
                  <Col md={24} xs={24} className="user_info">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "10px 20px",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <img
                          className="img-fluid w-25"
                          src={professionIcon}
                          alt="profession icon"
                          style={{ backgroundColor: "red" }}
                        />
                        <p
                          className="mt-2 fw-bold"
                          style={{ fontWeight: "bold" }}
                        >
                          {professional?.occupation}
                        </p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <img
                          className="img-fluid w-25"
                          src={yearIcon}
                          alt="year icon"
                        />
                        <p
                          className="mt-2 fw-bold"
                          style={{ fontWeight: "bold" }}
                        >
                          {personal?.age} Yrs
                        </p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <img
                          className="img-fluid w-25"
                          src={heightIcon}
                          alt="height icon"
                        />
                        <p
                          className="mt-2 fw-bold"
                          style={{ fontWeight: "bold" }}
                        >
                          {personal?.height_ft}' {personal?.height_inch}"
                        </p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <img
                          className="img-fluid w-25"
                          src={locationIcon}
                          alt="location icon"
                        />
                        <p
                          className="mt-2 fw-bold"
                          style={{ fontWeight: "bold" }}
                        >
                          {personal?.present_city}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col
                    span={24}
                    className="user_bio_text"
                    style={{ marginTop: "40px", marginLeft: "20px" }}
                  >
                    {partner_expectation?.about_me
                      ?.split("\n")
                      .map((itemAbout, index) => (
                        <p
                          key={index}
                          style={{ margin: "4px 0", fontSize: "18px" }}
                        >
                          {itemAbout}
                        </p>
                      ))}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div className="personal_information" style={{ margin: "22px 0" }}>
            <div>
              <h3
                className="sec-head title"
                style={{
                  color:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "#ffacd0"
                      : "#00dbe2",
                  borderBottom:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "1px solid #ffacd0"
                      : "1px solid #00dbe2",
                  fontSize: "28px",
                  paddingBottom: "5px",
                  marginBottom: "15px",
                }}
              >
                Personal Information
              </h3>
            </div>
            <Row
              justify={{ xl: "end", lg: "end", md: "start", xs: "start" }}
              style={{
                display: "flex",
                paddingLeft: "20px",
                paddingTop: "10px",
              }}
              gutter={[30, 30]}
              className="row d-flex justify-content-end mt-4"
            >
              <Col xl={8} md={12} sm={24} className="col-md-4 info_box">
                <p>
                  <span className="fw-bold pr-2">Date of Birth : </span>
                  <span>
                    {new Date(personal?.dob).toLocaleDateString("en-US", {
                      // weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </span>
                </p>
                <p>
                  <span className="fw-bold">Religion : </span>
                  <span>{personal?.religion}</span>
                </p>
                {personal?.blood_group && (
                  <p>
                    <span className="fw-bold">Blood Group : </span>
                    <span>{personal?.blood_group}</span>
                  </p>
                )}
                <p>
                  <span className="fw-bold">Marital Status : </span>
                  <span>{personal?.marital_status}</span>
                </p>
                {personal?.home_district && (
                  <p>
                    <span className="fw-bold">Home District :</span>
                    <span>{personal?.home_district}</span>
                  </p>
                )}

                {bioDetails?.personal?.stay_in.toLowerCase() !==
                "bangladesh" ? (
                  <p>
                    <span className="fw-bold">Country Live in :</span>
                    <span>{personal?.country_living_in}</span>
                  </p>
                ) : null}
              </Col>
              <Col xl={8} md={12} sm={24} className="col-md-4 info_box">
                {bioDetails?.personal?.stay_in.toLowerCase() !== "bangladesh" &&
                  personal?.citizenship_status && (
                    <p>
                      <span className="fw-bold"> Citizenship Status :</span>
                      <span>{personal?.citizenship_status}</span>
                    </p>
                  )}
                {personal?.smoking_habit && (
                  <p>
                    <span className="fw-bold"> Smoking Habbit :</span>
                    <span>{personal?.smoking_habit}</span>
                  </p>
                )}
                {personal?.drinking_habit && (
                  <p>
                    <span className="fw-bold">Drinking Habbit :</span>
                    <span>{personal?.drinking_habit}</span>
                  </p>
                )}
                {personal?.open_to_move_abroad && (
                  <p>
                    <span className="fw-bold">Open to Move Abroad :</span>
                    <span>{personal?.open_to_move_abroad}</span>
                  </p>
                )}
              </Col>
            </Row>
          </div>
          <div className="personal_information" style={{ margin: "22px 0" }}>
            <div>
              <h3
                className="sec-head title"
                style={{
                  color:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "#ffacd0"
                      : "#00dbe2",
                  borderBottom:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "1px solid #ffacd0"
                      : "1px solid #00dbe2",
                  fontSize: "28px",
                  paddingBottom: "5px",
                  marginBottom: "15px",
                }}
              >
                Contact Information
              </h3>
            </div>
            <Row
              justify={{ xl: "end", lg: "end", md: "start", xs: "start" }}
              style={{
                display: "flex",
                paddingLeft: "20px",
                paddingTop: "10px",
              }}
              gutter={[30, 30]}
              className="row d-flex justify-content-end mt-4"
            >
              <Col xl={8} md={12} sm={24} className="col-md-4 info_box">
                <p>
                  <span className="fw-bold pr-2">Email : </span>
                  <span>{personal?.email}</span>
                </p>
                <p>
                  <span className="fw-bold pr-2">Present Address : </span>
                  <span>{personal?.present_address}</span>
                </p>
                <p>
                  <span className="fw-bold"> Present City :</span>
                  <span>{personal?.present_city}</span>
                </p>
                <p>
                  <span className="fw-bold"> Profile Managed by :</span>
                  <span>{personal?.managed_by}</span>
                </p>
              </Col>
              <Col xl={8} md={12} sm={24} className="col-md-4 info_box">
                {personal?.show_number && (
                  <p>
                    <span className="fw-bold"> Phone :</span>
                    <Fragment>{personal?.mobile_number}</Fragment>
                  </p>
                )}
                <p>
                  <span className="fw-bold"> Permanent Address :</span>
                  <span>{personal?.permanent_address}</span>
                </p>
                <p>
                  <span className="fw-bold"> Permanent City :</span>
                  <span>{personal?.permanent_city}</span>
                </p>
              </Col>
            </Row>
          </div>
          {/* personal information */}

          {/* professional information */}
          <div
            className="personal_information mb-4"
            style={{ margin: "22px 0" }}
          >
            <div>
              <h3
                className="title"
                style={{
                  color:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "#ffacd0"
                      : "#00dbe2",
                  borderBottom:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "1px solid #ffacd0"
                      : "1px solid #00dbe2",
                  fontSize: "28px",
                  paddingBottom: "5px",
                  marginBottom: "15px",
                }}
              >
                Professional Information
              </h3>
            </div>
            <Row
              justify={{ xl: "end", lg: "end", md: "start" }}
              style={{
                display: "flex",
                paddingLeft: "20px",
                paddingTop: "10px",
              }}
              gutter={[30, 30]}
              className="row d-flex justify-content-end mt-4"
            >
              <Col xl={8} md={12} xs={24} className="col-md-8 info_box">
                <p style={{ width: "100%" }}>
                  <span className="fw-bold">Company/ Institution :</span>
                  <span>{professional?.professional_organization}</span>
                </p>
                <p style={{ width: "100%" }}>
                  <span className="fw-bold">Company Type :</span>
                  <span>{professional?.organization_type}</span>
                </p>
                <p>
                  <span className="fw-bold">Designation :</span>
                  <span>{professional?.designation}</span>
                </p>
                <p>
                  <span className="fw-bold">Occupation : </span>
                  <span>{professional?.occupation}</span>
                </p>
              </Col>
              <Col span={8}></Col>
            </Row>
          </div>
          {/* professional information */}

          {/* education information */}
          <div
            className="personal_information mb-4"
            style={{ margin: "22px 0" }}
          >
            <div>
              <h3
                className="title"
                style={{
                  color:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "#ffacd0"
                      : "#00dbe2",
                  borderBottom:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "1px solid #ffacd0"
                      : "1px solid #00dbe2",
                  fontSize: "28px",
                  paddingBottom: "5px",
                  marginBottom: "15px",
                }}
              >
                Educational Information
              </h3>
            </div>
            <Row
              justify={{ xl: "end", lg: "end", md: "start" }}
              style={{
                display: "flex",
                paddingLeft: "20px",
                paddingTop: "10px",
              }}
              gutter={[30, 30]}
              className="row d-flex justify-content-end mt-4"
            >
              <Col lg={8} sm={24} xs={24}>
                {bioDetails?.education?.map((eduItem, index) =>
                  eduItem?.education_degree ? (
                    <div
                      className="col-md-8 info_box mt-4"
                      key={index}
                      style={{ marginTop: "20px" }}
                    >
                      <h5
                        className="mb-4 fw-bold"
                        style={{ fontSize: "20px", color: "#494949" }}
                      >
                        {eduItem?.education_type}
                      </h5>
                      <p>
                        <span className="fw-bold">Degree :</span>
                        <span>{eduItem?.education_degree}</span>
                      </p>
                      <p>
                        <span className="fw-bold">Major Subject :</span>
                        <span>{eduItem?.education_subject}</span>
                      </p>
                      <p>
                        <span className="fw-bold">Institute :</span>
                        <span>{eduItem?.education_institution}</span>
                      </p>
                      <p>
                        <span className="fw-bold">Passing Year :</span>
                        <span>{eduItem?.education_passing_year}</span>
                      </p>
                    </div>
                  ) : null
                )}
              </Col>
              <Col md={8}></Col>
            </Row>
          </div>
          {/* education information */}

          {/* interest information */}
          <div className="personal_information mb-5">
            <div>
              <h3
                className="title"
                style={{
                  color:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "#ffacd0"
                      : "#00dbe2",
                  borderBottom:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "1px solid #ffacd0"
                      : "1px solid #00dbe2",
                  fontSize: "28px",
                  paddingBottom: "5px",
                  marginBottom: "15px",
                }}
              >
                Interest
              </h3>
            </div>
            <Row
              align="center"
              gutter={[30, 30]}
              justify="center"
              className="row interest_button"
              style={{ padding: "30px 40px" }}
            >
              {interest?.map((inItem, index) => (
                <Col xl={3} lg={4} md={5} sm={8} xs={12} key={index}>
                  <button
                    key={inItem.id}
                    className="btn mx-2"
                    style={{
                      backgroundColor:
                        bioDetails?.personal?.gender.toLowerCase() === "female"
                          ? "#C90C5B"
                          : "#00dbe2",
                      border: "none",
                      borderRadius: "10px",
                      padding: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      fontSize: "14px",
                      dropShadow: "2px 5px 2px gray",
                      boxShadow: "0 4px 9px -4px rgba(0,0,0,.35)",
                      height: "70px",
                    }}
                  >
                    {inItem?.name}
                  </button>
                </Col>
              ))}
            </Row>
          </div>
          {/* interest information */}

          {/* Personality information  */}
          <div className="personal_information mb-5">
            <div>
              <h3
                className="title"
                style={{
                  color:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "#ffacd0"
                      : "#00dbe2",
                  borderBottom:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "1px solid #ffacd0"
                      : "1px solid #00dbe2",
                  fontSize: "28px",
                  paddingBottom: "5px",
                  marginBottom: "15px",
                }}
              >
                Personality
              </h3>
            </div>
            <Row
              align="center"
              gutter={[30, 30]}
              justify="center"
              className="row interest_button"
              style={{ padding: "30px 40px" }}
            >
              {personality?.map((inItem, index) => (
                <Col xl={3} lg={4} md={5} sm={8} xs={12} key={index}>
                  <button
                    key={inItem?.id}
                    className="btn mx-2"
                    style={{
                      backgroundColor:
                        bioDetails?.personal?.gender.toLowerCase() === "female"
                          ? "#C90C5B"
                          : "#00dbe2",
                      border: "none",
                      borderRadius: "10px",
                      padding: "15px",
                      width: "100%",
                      fontSize: "14px",
                      dropShadow: "2px 5px 2px gray",
                      boxShadow: "0 4px 9px -4px rgba(0,0,0,.35)",
                      height: "70px",
                    }}
                  >
                    {inItem?.name}
                  </button>
                </Col>
              ))}
            </Row>
          </div>
          {/* Personality information  */}

          {/* Partners expectation  */}
          <div className="personal_information mb-4">
            <div>
              <h3
                className="title"
                style={{
                  color:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "#ffacd0"
                      : "#00dbe2",
                  borderBottom:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "1px solid #ffacd0"
                      : "1px solid #00dbe2",
                  fontSize: "28px",
                  paddingBottom: "5px",
                  marginBottom: "15px",
                }}
              >
                Partners Expectation
              </h3>
            </div>
            <div>
              <div
                className="px-5 pt-2"
                style={{ paddingLeft: "20px", paddingTop: "10px" }}
              >
                {partner_expectation?.partner_expectation
                  ?.split("\n")
                  .map((itemAbout, index) => (
                    <p
                      key={index}
                      style={{ margin: "4px 0", fontSize: "18px" }}
                    >
                      {itemAbout}
                    </p>
                  ))}
              </div>
            </div>
          </div>
          {/* Partners expectation  */}

          {/* Parents information  */}
          <div className="personal_information mb-4">
            <div>
              <h3
                className="title"
                style={{
                  color:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "#ffacd0"
                      : "#00dbe2",
                  borderBottom:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "1px solid #ffacd0"
                      : "1px solid #00dbe2",
                  fontSize: "28px",
                  paddingBottom: "5px",
                  marginBottom: "15px",
                }}
              >
                Parents Information
              </h3>
            </div>
            <Row
              justify={{ xl: "end", lg: "end", md: "start", xs: "start" }}
              style={{
                display: "flex",
                paddingLeft: "20px",
                paddingTop: "10px",
              }}
              gutter={[30, 30]}
              className="row d-flex justify-content-end mt-4"
            >
              <Col
                md={8}
                className="col-md-8 info_box"
                style={{ paddingTop: "12px" }}
              >
                <p>
                  <span className="fw-bold">Fathers Name :</span>
                  <span>{personal?.father_name}</span>
                </p>
                <p>
                  <span className="fw-bold">Occupation :</span>
                  <span>{personal?.father_occupation}</span>
                </p>
                <p>
                  <span className="fw-bold">Mothers Name :</span>
                  <span>{personal?.mother_name}</span>
                </p>
                <p>
                  <span className="fw-bold">Occupation : </span>
                  <span>{personal?.mother_occupation}</span>
                </p>
              </Col>
              <Col span={8}></Col>
            </Row>
          </div>
          {/* Parents information  */}

          {/* Siblings information  */}
          <div className="personal_information mb-4">
            <div>
              <h3
                className="title"
                style={{
                  color:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "#ffacd0"
                      : "#00dbe2",
                  borderBottom:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "1px solid #ffacd0"
                      : "1px solid #00dbe2",
                  fontSize: "28px",
                  paddingBottom: "5px",
                  marginBottom: "15px",
                }}
              >
                Siblings Information
              </h3>
            </div>
            <Row
              justify={{ xl: "end", lg: "end", md: "start" }}
              style={{
                display: "flex",
                paddingLeft: "20px",
                paddingTop: "10px",
              }}
              gutter={[30, 30]}
              className="row d-flex justify-content-end mt-4"
            >
              <Col md={8} xs={24}>
                {siblings?.length > 0 &&
                  siblings?.map((sibItem) => (
                    <div
                      className="col-md-8 info_box mt-4"
                      key={sibItem.id}
                      style={{ marginTop: "15px" }}
                    >
                      <h5 className="mb-4 fw-bold" style={{ fontSize: "18px" }}>
                        {sibItem?.siblings_type}
                      </h5>
                      <p>
                        <span className="fw-bold">Name :</span>
                        <span>{sibItem?.full_name}</span>
                      </p>
                      <p>
                        <span className="fw-bold">profession :</span>
                        <span>{sibItem?.profession}</span>
                      </p>
                      <p>
                        <span className="fw-bold">Location :</span>
                        <span>{sibItem?.location}</span>
                      </p>
                      <p>
                        <span className="fw-bold">Marital Status :</span>
                        <span>
                          {sibItem?.married ? "Married" : "Unmarried"}
                        </span>
                      </p>
                      {sibItem?.married === 1 && (
                        <Fragment>
                          <p>
                            <span className="fw-bold">Spouse Name :</span>
                            <span>{sibItem?.spouse_name}</span>
                          </p>
                          <p>
                            <span className="fw-bold">Spouse Profession :</span>
                            <span>{sibItem?.spouse_profession}</span>
                          </p>
                        </Fragment>
                      )}
                    </div>
                  ))}
              </Col>
              <Col span={8}></Col>
            </Row>
          </div>
          {/* Siblings information  */}

          {/* Relatives information  */}
          <div className="personal_information mb-4">
            <div>
              <h3
                className="title"
                style={{
                  color:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "#ffacd0"
                      : "#00dbe2",
                  borderBottom:
                    bioDetails?.personal?.gender.toLowerCase() === "female"
                      ? "1px solid #ffacd0"
                      : "1px solid #00dbe2",
                  fontSize: "28px",
                  paddingBottom: "5px",
                  marginBottom: "15px",
                }}
              >
                Relatives Information
              </h3>
            </div>
            <Row
              justify={{ xl: "end", lg: "end", md: "start" }}
              style={{
                display: "flex",
                paddingLeft: "20px",
                paddingTop: "10px",
              }}
              gutter={[30, 30]}
              className="row d-flex justify-content-end mt-4"
            >
              <Col md={8} xs={24} style={{ paddingBottom: "40px" }}>
                {relatives?.length > 0 &&
                  relatives?.map((sibItem) => (
                    <div
                      style={{ marginTop: "15px" }}
                      className="col-md-8 info_box mt-4"
                      key={sibItem.id}
                    >
                      <h5 className="mb-4 fw-bold" style={{ fontSize: "18px" }}>
                        {sibItem?.relative_types}
                      </h5>
                      <p>
                        <span className="fw-bold">Relation :</span>
                        <span>{sibItem?.relation}</span>
                      </p>
                      <p>
                        <span className="fw-bold">Name :</span>
                        <span>{sibItem?.full_name}</span>
                      </p>
                      <p>
                        <span className="fw-bold">Profassion :</span>
                        <span>{sibItem?.profession}</span>
                      </p>
                      <p>
                        <span className="fw-bold">Location :</span>
                        <span>{sibItem?.location}</span>
                      </p>
                      <p>
                        <span className="fw-bold">Marital Status :</span>
                        <span>
                          {sibItem?.married ? "Married" : "Unmarried"}
                        </span>
                      </p>
                      {sibItem?.married === 1 && (
                        <Fragment>
                          <p>
                            <span className="fw-bold">Spouse Name :</span>
                            <span>{sibItem?.spouse_name}</span>
                          </p>
                          <p>
                            <span className="fw-bold">Spouse Profession :</span>
                            <span>{sibItem?.spouse_profession}</span>
                          </p>
                        </Fragment>
                      )}
                    </div>
                  ))}
              </Col>
              <Col span={8}></Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PreviewOthers;
