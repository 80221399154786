import React, { useEffect } from "react";
import "./thankyou.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Row } from "antd";
import axios from "axios";
import { BIO } from "../../app/config";
import { useDispatch, useSelector } from "react-redux";
import { setBioDataDetails } from "../../app/slices/multiForm";

const ThankYou = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    axios
      .get(BIO.GET_BIO_DATA, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        dispatch(setBioDataDetails(res.data));
      })
      .catch((err) => {
        console.log(err);
        navigate("/login", { replace: true });
      });
  }, []);
  return (
    <Row align="top" justify="center" className="thank-full">
      <Col lg={7} xs={22} className="text-center mt-5 card">
        <h1
          className="text-danger"
          style={{ fontSize: "30px", color: "#dc4c64" }}
        >
          Thank You for Registering
        </h1>
        {/* <h5
					className="my-4"
					style={{
						padding: "40px 0",
						fontSize: "17px",
						maxWidth: "500px",
						textAlign: "center",
						color: "#666055",
					}}
				>
					We will get back to you soon, once we match your Interest, Personality
					& Expectation
				</h5> */}
        <div>
          <Link to="/biodata/preview">
            <Button type="primary" size="medium">
              PREVIEW YOUR PROFILE
            </Button>
          </Link>
        </div>
        <div>
          <Link to="/biodata/explore">
            <Button type="primary" size="medium">
              Explore
            </Button>
          </Link>
        </div>
      </Col>
    </Row>
  );
};

export default ThankYou;
