import { Badge, Col, Dropdown, Row, Tooltip } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setActiveTab } from "../../app/slices/activeFormTab";
import { setClearAuth } from "../../app/slices/auth";
import {
  setBioDataDetails,
  setClearMultiForm,
} from "../../app/slices/multiForm";
import { setClearQuiz, setQuizStep } from "../../app/slices/quiz";
import "./Navbar.css";
import imgLogo from "../../assets/img/logo.png";
// import imgRoundedLogo from "../../assets/rounded-logo.png";
// import imgRoundedLogo from "../../assets/roundedLogo.jpg";
import { IoMdNotificationsOutline } from "react-icons/io";
import axios from "axios";
import { BIODATA_REQUEST, NOTFICATION } from "../../app/config";
import useComponentVisible from "../../utils/useComponentVisible";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { bioDataDetails } = useSelector((state) => state.multiForm);
  const { user } = useSelector((state) => state.auth);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const { visibleRef, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  useEffect(() => {
    // fetchBioDataList();
    fetchNotificationList();
  }, []);

  const handleLogout = () => {
    dispatch(setClearAuth());
    dispatch(setQuizStep(1));
    dispatch(setClearQuiz());
    dispatch(setClearMultiForm());
    dispatch(setBioDataDetails({}));
    dispatch(setActiveTab(0));
    navigate("/login", { replace: true });
  };

  const fetchBioDataList = () => {
    axios
      .get(BIODATA_REQUEST.GET_ANALYTICS_BIODATA("RECEIVED"), {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setNotificationCount(res.data.total_received_requests);
        setNotificationData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchNotificationList = () => {
    axios
      .get(`${NOTFICATION.GET_NOTIFICATION}`, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // console.log(res.data.notifications);
        setNotificationCount(res.data.total_unseen_notifications);
        setNotificationData(res.data.notifications);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNotificationSeen = (notificationId) => {
    axios
      .post(
        `${NOTFICATION.MAKE_NOTIFICATION_SEEN(notificationId)}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res.data.notifications);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <nav
      style={{
        zIndex: "100",
        position: "fixed",
        top: 0,
        width: "100%",
        padding: "10px 0",
        backgroundColor: "#fff",
        boxShadow: "0px 1px 10px #b5b5b5",
        // backgroundColor:
        // 	bioDataDetails?.personal?.gender === "female"
        // 		? "rgba(255, 163, 206, 0.4)"
        // 		: "rgba(201, 238, 239, 0.5)",
      }}
    >
      <Row align="center" justify="end">
        <Col xl={24} lg={24} md={22} xs={24}>
          <Row
            // align="center"
            // justify="end"
            gutter={[20, 20]}
            className="navbar-row"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "enter",
              justifyContent: "space-between",
            }}
          >
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
              md={0}
              xs={6}
            >
              <ul
                style={{
                  listStyle: "none",
                  display: "flex",
                  gap: "15px",
                  alignItems: "center",
                  // paddingLeft: "20px",
                }}
              >
                <li style={{ fontSize: "22px" }}>
                  <Link to="/" style={{ color: "#e2286d" }}>
                    <img src={imgLogo} alt="logo" className="nav-logo" />
                    {/* <img src={imgRoundedLogo} alt="logo" width="70px" /> */}
                  </Link>
                </li>
              </ul>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
              className="nav-right"
            >
              <div>
                {bioDataDetails?.personal?.is_editable === false &&
                bioDataDetails?.personal?.is_email_verified === true ? (
                  <div>
                    <Link
                      to="/biodata/explore"
                      className={`${
                        bioDataDetails?.personal?.is_editable !== true &&
                        "explore-btn"
                      }`}
                    >
                      Explore
                    </Link>
                  </div>
                ) : bioDataDetails?.personal?.is_email_verified === false &&
                  bioDataDetails?.personal?.is_editable === false ? (
                  <div>
                    <Link to="/biodata/explore" className="verify-btn">
                      Verify to Explore
                    </Link>
                  </div>
                ) : (
                  <Tooltip
                    placement="leftTop"
                    title={`${
                      // bioDataDetails?.personal?.is_email_verified === false
                      //   ? "Please verified your profile."
                      //   :
                      "Please Complete required form steps"
                    }`}
                  >
                    <span
                      className="explore-btn"
                      // style={{
                      // 	backgroundColor: "#e2286d",
                      // 	fontSize: "20px",
                      // 	color: "white",
                      // 	fontWeight: "600",
                      // 	padding: "7px 15px",
                      // 	borderRadius: "10px",
                      // 	boxShadow: "3px 3px 8px gray",
                      // }}
                    >
                      Explore
                    </span>
                  </Tooltip>
                )}
              </div>
              <div
                style={{
                  color: "#e2286d",
                  fontSize: "28px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "bold",
                  position: "relative",
                }}
                ref={visibleRef}
              >
                <Badge
                  count={notificationCount}
                  onClick={() => setIsComponentVisible(true)}
                >
                  <IoMdNotificationsOutline size={30} />
                </Badge>
                {isComponentVisible && (
                  <div
                    style={{
                      position: "absolute",
                      top: "40px",
                      right: "-50px",
                      width: "250px",
                      maxHeight: "200px",
                      overflowY: "scroll",
                      height: "200px",
                      color: "#e2286d",
                      borderRadius: "8px",
                      backgroundColor: "white",
                      boxShadow: "5px 3px 8px #ededed",
                      padding: "15px 10px",
                    }}
                  >
                    {notificationData.length > 0 ? (
                      notificationData.map((notData, index) => (
                        <Link
                          to={
                            notData.element_type === "accept_request"
                              ? `/biodata/analytics/preview/${notData.navigate_id}`
                              : `/biodata/analytics`
                          }
                          onClick={() =>
                            handleNotificationSeen(notData.notification_id)
                          }
                          style={{
                            backgroundColor:
                              notData?.is_seen === false
                                ? "#fff2f2"
                                : "transparent",
                            padding: "6px 4px",
                            marginBottom: "8px",
                            display: "block",
                            color: "#e2286d",
                            fontWeight:
                              notData?.is_seen === false ? "600" : "400",
                          }}
                        >
                          <p style={{ fontSize: "14px" }}>
                            {/* <span style={{ fontWeight: "bold" }}>
                              {notData.display_name}
                            </span>{" "}
                            sent you request. */}
                            {notData.message}
                          </p>
                        </Link>
                      ))
                    ) : (
                      <p style={{ fontSize: "14px" }}>
                        Incoming friend request will display here.
                      </p>
                    )}
                  </div>
                )}
              </div>
              <Dropdown
                className="dropdown-wrapper"
                menu={{
                  items: [
                    {
                      key: "0",
                      label: (
                        <Fragment>
                          {bioDataDetails?.personal?.is_editable === false ? (
                            <Link
                              to="/biodata/explore"
                              style={
                                bioDataDetails?.images?.length > 0
                                  ? null
                                  : {
                                      pointerEvents: "none",
                                      background: "gray",
                                    }
                              }
                            >
                              Explore
                            </Link>
                          ) : (
                            <Tooltip
                              placement="leftTop"
                              title={`${
                                bioDataDetails?.personal?.is_email_verified ===
                                false
                                  ? "Please verified your profile."
                                  : "Please Complete required form steps"
                              }`}
                            >
                              <span>Explore</span>
                            </Tooltip>
                          )}
                        </Fragment>
                      ),
                    },
                    {
                      key: "1",
                      label: (
                        <Fragment>
                          {bioDataDetails?.personal?.is_editable === false ? (
                            <Link
                              to="/biodata/preview"
                              style={
                                bioDataDetails?.images?.length > 0
                                  ? null
                                  : {
                                      pointerEvents: "none",
                                      background: "gray",
                                    }
                              }
                            >
                              Preview Biodata
                            </Link>
                          ) : (
                            <Tooltip
                              placement="leftTop"
                              title="Please Complete required form steps"
                            >
                              <span>Preview Biodata</span>
                            </Tooltip>
                          )}
                        </Fragment>
                      ),
                    },
                    {
                      key: "2",
                      label: <Link to="/biodata">Edit Biodata</Link>,
                    },
                    {
                      key: "3",
                      label: (
                        <Fragment>
                          {bioDataDetails?.personal?.is_editable === false ? (
                            <Link
                              to="/biodata/analytics"
                              style={
                                bioDataDetails?.images?.length > 0
                                  ? null
                                  : {
                                      pointerEvents: "none",
                                      background: "gray",
                                    }
                              }
                            >
                              Biodata Analytics
                            </Link>
                          ) : (
                            <Tooltip
                              placement="leftTop"
                              title="Please Complete required form steps"
                            >
                              <span>Biodata Analytics</span>
                            </Tooltip>
                          )}
                        </Fragment>
                      ),
                    },
                    {
                      key: "4",
                      label: <Link to="/pricing">Packages</Link>,
                    },
                    {
                      key: "4",
                      label: <div onClick={handleLogout}>Logout</div>,
                    },
                  ],
                }}
                trigger={["click"]}
              >
                <div
                  className="nav-round"
                  style={{
                    backgroundColor:
                      bioDataDetails?.personal?.gender === "female"
                        ? "#e2286d"
                        : "#e2286d",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    textTransform: "uppercase",
                    borderRadius: "50%",
                    boxShadow: "1px 2px 6px gray",
                    cursor: "pointer",
                    fontWeight: "600",
                  }}
                  onClick={(e) => e.preventDefault()}
                >
                  {bioDataDetails?.personal?.full_name?.slice(0, 1)}
                </div>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
    </nav>
  );
};

export default Navbar;
