import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import Register from "../components/Register/Register";

import imgGhotokFullLogo from "../assets/img/logo.png";
import imgRegisterRight from "../assets/img/landing_page_img_mini.jpg";
import imgRegisterPopup from "../assets/registrationPopup.jpeg";
import Footer from "../components/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./RegisterPopup.css";
import { MdClose } from "react-icons/md";

const RegisterPage = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [popup, setPopup] = useState(true);
  useEffect(() => {
    if (user) {
      navigate("/biodata");
    }
  }, []);
  return (
    <div style={{ paddingTop: "40px", position: "relative" }}>
      {popup && (
        <div className="modal-wrapper">
          <div className="modal-main">
            <button className="reg-close-btn" onClick={() => setPopup(false)}>
              <MdClose />
            </button>
            <div>
              <img
                src={imgRegisterPopup}
                alt="Welcome popup"
                style={{ maxWidth: "500px", width: "100%" }}
              />
            </div>
          </div>
        </div>
      )}
      <Row align="middle" justify="center">
        <Col xl={18} lg={20} style={{ padding: "0 15px" }}>
          <Row align="top" justify="space-between">
            <Col xl={12} xs={12}>
              <img
                src={imgGhotokFullLogo}
                alt="Ghotok Logo"
                style={{ width: "180px" }}
              />
            </Col>
            <Col xl={12} xs={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "top",
                  justifyContent: "end",
                }}
              >
                <Button
                  type="primary"
                  size="large"
                  style={{
                    // marginRight: "40px",
                    padding: "10px 25px",
                    boxShadow: "3px 3px 10px #adadad",
                    height: "auto",
                  }}
                >
                  <Link to="/login">Sign In</Link>
                </Button>
              </div>
            </Col>
          </Row>
          <Row
            style={{ paddingTop: "20px" }}
            align="top"
            justify={"space-between"}
          >
            <Col
              xl={8}
              lg={12}
              md={10}
              sm={24}
              xs={24}
              style={{ paddingTop: "30px" }}
            >
              <Register />
            </Col>
            <Col xl={12} lg={10} md={10} sm={24} xs={24}>
              <img
                src={imgRegisterRight}
                alt="Register Right"
                style={{
                  width: "100%",
                  marginBottom: "15%",
                  borderRadius: "10px",
                  boxShadow: "4px 6px 12px gray",
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer />
      {/* added */}
    </div>
  );
};

export default RegisterPage;
