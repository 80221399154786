import { Form } from "antd";
import React, { Fragment, useState } from "react";
import "./DateCommon.css";

const DateCommon = ({
	name,
	value,
	label,
	handleChangeFunc,
	isDisabled = false,
}) => {
	const [focus, setFocus] = useState(false);
	const labelClass =
		focus || (value && value.length !== 0) ? "label label-float" : "label";

	// const onChange = (date) => handleChangeFunc(name, date);

	return (
		<Form.Item
			name={name}
			className="input-wrapper"
			onBlur={() => setFocus(false)}
			onFocus={() => setFocus(true)}
			style={{ width: "100%", margin: "15px auto", background: "transparent" }}
		>
			<Fragment>
				{/*<DatePicker
					className="date-root"
					onChange={onChange}
					value={value ? dayjs(value, "YYYY-MM-DD") : ""}
				/> */}
				<input
					style={{ background: "transparent" }}
					disabled={isDisabled}
					className="date-input"
					inputprops={{ style: { fontSize: 30 } }}
					type="date"
					label="Date desktop"
					// inputformat="MM/DD/YYYY"
					// variant="outlined"
					// defaultValue={value ? dayjs(value, "YYYY-MM-DD") : ""}
					defaultValue={value}
					onChange={(e) => handleChangeFunc(name, e.target.value)}
					max={new Date(
						new Date().getFullYear() - 18,
						new Date().getMonth(),
						new Date().getDate()
					)
						.toISOString()
						.substr(0, 10)}
				/>
				<label htmlFor={name} className="input-label">
					<label
						className={labelClass}
						style={{
							fontSize: "17px",
							color: value ? "rgb(117, 117, 117" : "#ea6689",
						}}
					>
						{label}
					</label>
				</label>
			</Fragment>
		</Form.Item>
	);
};

export default DateCommon;
