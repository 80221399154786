import axios from "axios";
import { BIO, MULTI_FORM, QUIZ } from "../config";

const UseAuthHeader = (token) => {
	return {
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
	};
};

const submitQuiz = (token, data) => {
	return axios
		.post(QUIZ.POST_QUIZ, data, UseAuthHeader(token))
		.then((response) => {
			return response.data;
		});
};

//Get BioData:::
const getBioData = (token) => {
	return axios.get(BIO.GET_BIO_DATA, UseAuthHeader(token)).then((response) => {
		return response.data;
	});
};

//Multi Form Sumbit post api:

//Personal Submission:::
const submitPersonalForm = (token, data) => {
	return axios
		.post(MULTI_FORM.POST_PERSONAL, data, UseAuthHeader(token))
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
};

//Education Submission:::
const submitEducationForm = (token, data) => {
	return axios
		.post(MULTI_FORM.POST_EDUCATION, data, UseAuthHeader(token))
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
};

//Professional Submission:::
const submitProfessionalForm = (token, data) => {
	return axios
		.post(MULTI_FORM.POST_PROFESSIONAL, data, UseAuthHeader(token))
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
};

//Interest PersonalityForm Submission:::
const submitInterestPersonalityForm = (token, data) => {
	return axios
		.post(MULTI_FORM.POST_INTEREST_PERSONALITY, data, UseAuthHeader(token))
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
};

//Partner Expectation:::
const submitExpectation = (token, data) => {
	return axios
		.post(MULTI_FORM.POST_PARTNER_EXPECTATION, data, UseAuthHeader(token))
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
};

//Siblings Form Submission:::
const submitSiblingsForm = (token, data) => {
	return axios
		.post(MULTI_FORM.POST_SIBLINGS, data, UseAuthHeader(token))
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
};
//Relatives Submission:::
const submitRelativesForm = (token, data) => {
	return axios
		.post(MULTI_FORM.POST_RELATIVES, data, UseAuthHeader(token))
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
};

const bioDataService = {
	submitQuiz,
	getBioData,
	submitPersonalForm,
	submitEducationForm,
	submitProfessionalForm,
	submitInterestPersonalityForm,
	submitExpectation,
	submitSiblingsForm,
	submitRelativesForm,
};
export default bioDataService;
