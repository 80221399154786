import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	fileListStore: [],
};

const messageSlice = createSlice({
	name: "upload",
	initialState,
	reducers: {
		setFileListStore: (state, action) => {
			state.fileList = action.payload;
		},
		clearFileListStore: (state) => {
			state.fileList = [];
		},
	},
});

const { reducer, actions } = messageSlice;

export const { setFileListStore, clearFileListStore } = actions;
export default reducer;
