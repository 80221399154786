import React from "react";
import PaymentModal from "../components/Payment/PaymentModal";
import { Button, Col, Row } from "antd";
import "../components/Faq/Faq.css";
import { Link } from "react-router-dom";

const PricingPage = () => {
  return (
    <Row align="top" justify="center" className="faq">
      <Col
        xl={16}
        lg={16}
        md={18}
        xs={22}
        className="container py-5"
        style={{ paddingTop: "60px" }}
      >
        <h1
          className="text-center text-white"
          style={{ textAlign: "center", color: "#fff", fontSize: "40px" }}
        >
          Pricing
        </h1>
        <div style={{ padding: "40px 0 10px 10px" }}>
          <Link to="/">
            <Button type="primary" size="large">
              Back to Home
            </Button>
          </Link>
        </div>
        <PaymentModal />

        {/* <h2
          style={{ color: "#e2286d", textAlign: "center", paddingTop: "20px" }}
        >
          ** Please note that there is no refund policy once the payment is
          completed.
        </h2> */}
      </Col>
    </Row>
  );
};

export default PricingPage;
