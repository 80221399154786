import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Progress,
  Row,
  Tooltip,
  Upload,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./MultiForm/UploadPhoto/UploadPhoto.css";
import {
  AiOutlineCloseCircle,
  AiOutlineLoading,
  AiOutlinePlus,
} from "react-icons/ai";

import { FaUserCircle } from "react-icons/fa";
import { MdDeleteForever, MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { interests, personalities } from "../../utils/interestsPersonalityList";
import TimerPopup from "../TimerPopup/TimerPopup";
import DateCommon from "./Common/Date/DateCommon";
import HeightInput from "./Common/HeightInput/HeightInput";
import InputCommon from "./Common/Input/InputCommon";
import PhoneInput from "./Common/PhoneInput/PhoneInput";
import SelectCommon from "./Common/Select/SelectCommon";
import dayjs from "dayjs";
import axios from "axios";
import districtList from "../../utils/districts";
import { setBioDataDetails } from "../../app/slices/multiForm";
import { BASE_URL, BIO } from "../../app/config";
import YearPickerCommon from "./Common/YearPickerCommon/YearPickerCommon";
import SelectMultiple from "./Common/SelectMultiple/SelectMultiple";
import Navbar from "../Navbar/Navbar";
import "./EditBioData.css";
import AntdImgCrop from "antd-img-crop";
import { RiImageAddLine } from "react-icons/ri";
import {
  collegeOptions,
  postGraduationOptions,
  schoolOptions,
  universityOptions,
} from "../../utils/educationOption";

let eduInit = [
  {
    education_degree: "",
    education_subject: "",
    education_institution: "",
    education_passing_year: null,
    education_type: "Post Graduation",
  },
  {
    education_degree: "",
    education_subject: "",
    education_institution: "",
    education_passing_year: null,
    education_type: "University",
  },
  {
    education_degree: "",
    education_subject: "",
    education_institution: "",
    education_passing_year: null,
    education_type: "College",
  },
  {
    education_degree: "",
    education_subject: "",
    education_institution: "",
    education_passing_year: null,
    education_type: "School",
  },
];

const EditBioData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [eduBtnArr, setEduBtnArr] = useState(eduInit);
  const { user } = useSelector((state) => state.auth);
  const { bioDataDetails } = useSelector((state) => state.multiForm);
  useEffect(() => {
    fetch(
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/index.json"
    )
      .then((res) => res.json())
      .then((data) => setCountryList(data));
  }, []);

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [fileList, setFileList] = useState([]);
  const [changePictureModal, setChangePictureModal] = useState(false);

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  useEffect(() => {
    if (changePictureModal) {
      // let proPicGot = false;
      // bioDataDetails?.images.map((img) =>
      // 	img.is_profile === 1 ? (proPicGot = true) : false
      // );

      !bioDataDetails?.images.find((img) => img.is_profile === 1) &&
        handleChangeProfilePicture(bioDataDetails?.images[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePictureModal]);

  const handleNeedInfoSubmit = async () => {
    // e.preventDefault();
    let isUploaded =
      fileList?.length > 0
        ? false
        : bioDataDetails?.images?.length > 0
        ? true
        : false;
    const formData = new FormData();
    for (let i = 0; i < fileList?.length; i++) {
      const element = fileList[i].originFileObj;
      // console.log({ element });
      // totalImgSize += element.size;
      formData.append("images[]", element);
    }
    const url = `${BASE_URL}/bio-data-image`;
    if (!isUploaded) {
      if (fileList.length > 3) {
        message.warning("You can upload Maximum 3 photos.");
      } else {
        await axios
          .post(url, formData, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
            },
          })
          .then((result) => {
            isUploaded = true;
            setFileList([]);
            message.success(result.data.message);
            axios
              .get(BIO.GET_BIO_DATA, {
                headers: {
                  Authorization: `Bearer ${user.access_token}`,
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                dispatch(
                  setBioDataDetails({
                    ...bioDataDetails,
                    images: res.data.images,
                  })
                );
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => {
            isUploaded = false;
            console.log(err.response);
            Object.values(err.response.data.errors).map((errMgs) =>
              message.error(errMgs)
            );
          });
      }
    }

    isUploaded &&
      axios
        .post(
          `${BASE_URL}/create-bio-data`,
          {
            ...bioDataDetails.personal,
            educations: bioDataDetails.education,
            // images: bioDataDetails.images,
            interests: bioDataDetails.interest,
            personality: bioDataDetails.personality,
            // images: fileList?.map((file) => file.originFileObj),
            // images: formData,
            siblings: bioDataDetails.siblings,
            relatives: bioDataDetails.relatives,
            ...bioDataDetails.partner_expectation,
            ...bioDataDetails.professional,
          },
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          // message.success("Processing complete!");
          fileList.length > 0
            ? setChangePictureModal(true)
            : navigate("/thankyou", {
                replace: true,
              });

          setFileList([]);
        })
        .catch((err) => {
          Object.values(err.response.data.errors).map((errMgs) =>
            message.error(errMgs)
          );
        });
  };
  const handleDeletePhoto = (fileInfo) => {
    console.log({ fileInfo, token: user.access_token });
    axios
      .post(
        `${BASE_URL}/delete-bio-data-image`,
        {
          image: fileInfo.id,
        },
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      )
      .then((result) => {
        console.log(result);
        message.success(result.data.message);
        axios
          .get(BIO.GET_BIO_DATA, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            dispatch(
              setBioDataDetails({
                // ...res.data,
                // interest: res?.data.interest?.map((i) => i.id),
                // personality: res?.data.personality?.map((i) => i.id),
                ...bioDataDetails,
                images: res.data.images,
              })
            );
          })
          .catch((err) => {
            console.log(err);
            navigate("/login", { replace: true });
          });
      })
      .catch((err) => {
        console.log(err);
        message.error("Image Delete Failed");
      });
  };

  //Get Bio Data
  // const [bioDataDetails, setBioDataDetails] = useState({});
  useEffect(() => {
    axios
      .get(BIO.GET_BIO_DATA, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data?.personal?.is_editable === false) {
          dispatch(
            setBioDataDetails({
              ...res.data,
              interest: res?.data.interest?.map((i) => i.id),
              personality: res?.data.personality?.map((i) => i.id),
            })
          );
        } else {
          if (Object.keys(bioDataDetails || {}).length === 0) {
            if (res.data.personal?.gender?.toLowerCase() === "female") {
              dispatch(
                setBioDataDetails({
                  ...res.data,
                  images: res.data.images,
                  education: [
                    {
                      education_degree: "",
                      education_subject: "",
                      education_institution: "",
                      education_passing_year: null,
                      education_type: "College",
                    },
                  ],
                  interest: res?.data.interest?.map((i) => i.id),
                  personality: res?.data.personality?.map((i) => i.id),
                })
              );
            } else {
              dispatch(
                setBioDataDetails({
                  ...res.data,
                  images: res.data.images,
                  education: [
                    {
                      education_degree: "",
                      education_subject: "",
                      education_institution: "",
                      education_passing_year: null,
                      education_type: "University",
                    },
                  ],
                  interest: res?.data.interest?.map((i) => i.id),
                  personality: res?.data.personality?.map((i) => i.id),
                })
              );
            }
          } else {
            dispatch(setBioDataDetails(bioDataDetails));
          }
        }
        // if (res.data.education?.length > 0) {
        // 	dispatch(
        // 		setBioDataDetails({
        // 			...res.data,
        // 			interest: res.data?.interest?.map((el) => el?.id),
        // 			personality: res.data?.personality?.map((el) => el?.id),
        // 		})
        // 	);
        // } else {

        // 	if(bioDataDetails?.education?.length > 0){
        // 		dispatch(setBioDataDetails({...bioDataDetails}))
        // 	}else{
        // 		if (res.data.personal?.gender === "female") {
        // 			// setEduBtnArr([
        // 			// 	...eduInit.filter((edu) => edu.education_type !== "College"),
        // 			// ]);
        // 			dispatch(
        // 				setBioDataDetails({
        // 					...bioDataDetails,
        // 					personal: {...bioDataDetails?.personal, full_name: res?.data?.personal?.full_name, dob: res?.data?.personal?.dob, gender: res?.data?.personal?.gender, age: res?.data?.personal?.age, email: res?.data?.personal?.email, is_editable: res?.data?.personal?.is_editable, stay_in: res?.data?.personal?.stay_in},
        // 					images: res.data.images,
        // 					education: [
        // 						{
        // 							education_degree: "",
        // 							education_subject: "",
        // 							education_institution: "",
        // 							education_passing_year: null,
        // 							education_type: "College",
        // 						},
        // 					],
        // 				})
        // 			);

        // 		} else {
        // 			dispatch(
        // 				setBioDataDetails({
        // 					...bioDataDetails,
        // 					personal: {...bioDataDetails?.personal, full_name: res?.data?.personal?.full_name, dob: res?.data?.personal?.dob, gender: res?.data?.personal?.gender, age: res?.data?.personal?.age, email: res?.data?.personal?.email, is_editable: res?.data?.personal?.is_editable, stay_in: res?.data?.personal?.stay_in},
        // 					images: res.data.images,
        // 					education: [
        // 						{
        // 							education_degree: "",
        // 							education_subject: "",
        // 							education_institution: "",
        // 							education_passing_year: null,
        // 							education_type: "University",
        // 						},
        // 					],
        // 				})
        // 			);
        // 		}
        // 	}
        // 	}
      })
      .catch((err) => {
        console.log(err);
        navigate("/login", { replace: true });
      });
    fetch(
      "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/index.json"
    )
      .then((res) => res.json())
      .then((data) => setCountryList(data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Images Area
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    // dispatch(setFileListStore([...newFileList]));
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleChangePersonal = (key, value) => {
    if (key === "dob") {
      console.log(dayjs(value).fromNow(true));
      if (Math.floor(dayjs().diff(dayjs(value), "year", true)) < 18) {
        message.error("Hey, Kid, You are under 18");
      } else {
        // dispatch(
        // 	setbioDataDetails?.personal({
        // 		...bioDataDetails?.personal,
        // 		[key]: value,
        // 		age: Math.floor(dayjs().diff(dayjs(value), "year", true)),
        // 	})
        // );
        dispatch(
          setBioDataDetails({
            ...bioDataDetails,
            personal: {
              ...bioDataDetails.personal,
              [key]: value,
              age: Math.floor(dayjs().diff(dayjs(value), "year", true)),
            },
          })
        );
      }
    } else {
      // dispatch(setbioDataDetails?.personal({ ...bioDataDetails?.personal, [key]: value }));
      // console.log({ key, value });
      dispatch(
        setBioDataDetails({
          ...bioDataDetails,
          personal: { ...bioDataDetails.personal, [key]: value },
        })
      );
    }
  };
  const handleChangeProfessional = (key, value) => {
    // console.log({ key, value });
    dispatch(
      setBioDataDetails({
        ...bioDataDetails,
        professional: { ...bioDataDetails.professional, [key]: value },
      })
    );
  };
  //Education Section Functionalities :::

  const handleAddEducation = (e, eduItem) => {
    e.preventDefault();
    dispatch(
      setBioDataDetails({
        ...bioDataDetails,
        education: [...bioDataDetails?.education, eduItem],
      })
    );
    setEduBtnArr([
      ...eduBtnArr.filter(
        (edu) => edu.education_type !== eduItem.education_type
      ),
    ]);
  };
  const handleRemoveEducation = (eduItem) => {
    dispatch(
      setBioDataDetails({
        ...bioDataDetails,
        education: bioDataDetails?.education.filter(
          (i) => i.education_type !== eduItem.education_type
        ),
      })
    );
    setEduBtnArr([...eduBtnArr, eduItem]);
  };
  const handleChangeEducation = (key, value, type) => {
    // console.log(key, value, sibId);
    let eduList = [];
    for (let i = 0; i < bioDataDetails?.education?.length; i++) {
      const edu = bioDataDetails?.education[i];
      if (edu.education_type === type) {
        eduList.push({ ...edu, [key]: value });
      } else {
        eduList.push(edu);
      }
    }
    dispatch(setBioDataDetails({ ...bioDataDetails, education: eduList }));
  };
  //Handle change interest & personality:::
  const handleChangeInterestPersonality = (key, value) => {
    // console.log({ key, value });
    if (key === "interest" && value?.length > 15) {
      message.warning("Interest items Maximum length: 15");
    } else if (key === "personality" && value?.length > 10) {
      message.warning("Personality items Maximum length: 10");
    } else {
      dispatch(setBioDataDetails({ ...bioDataDetails, [key]: value }));
    }
  };

  //handle Expectation
  const handleChangeExpectation = (key, value) => {
    dispatch(
      setBioDataDetails({
        ...bioDataDetails,
        partner_expectation: {
          ...bioDataDetails?.partner_expectation,
          [key]: value,
        },
      })
    );
  };

  //Siblings Related func:::
  const handleSiblingAdd = (e) => {
    e.preventDefault();
    dispatch(
      setBioDataDetails({
        ...bioDataDetails,
        siblings: [
          ...bioDataDetails?.siblings,
          {
            id: uuidv4(),
            siblings_type: null,
            full_name: null,
            profession: null,
            location: null,
            married: 0,
          },
        ],
      })
    );
  };
  const handleSiblingRemove = (id, e) => {
    e.preventDefault();
    dispatch(
      setBioDataDetails({
        ...bioDataDetails,
        siblings: bioDataDetails?.siblings.filter((i) => i.id !== id),
      })
    );
  };
  const handleChangeSiblingsData = (key, value, sibId) => {
    // console.log(key, value, sibId);
    let sibList = [];
    for (let i = 0; i < bioDataDetails?.siblings?.length; i++) {
      const sib = bioDataDetails?.siblings[i];
      if (sib.id === sibId) {
        sibList.push({ ...sib, [key]: value });
      } else {
        sibList.push(sib);
      }
    }
    dispatch(setBioDataDetails({ ...bioDataDetails, siblings: sibList }));
  };
  const handleMarriedStatus = (item, value) => {
    // console.log({ value });
    // let targetSib = siblingFormData?.siblings.filter((i) => i.id === id);
    let totalSibs = [];
    for (let i = 0; i < bioDataDetails.siblings?.length; i++) {
      const sib = bioDataDetails.siblings[i];
      if (sib.id === item?.id) {
        if (value === true) {
          console.log(sib);
          totalSibs.push({
            ...sib,
            married: 1,
            spouse_name: item?.spouse_name || null,
            spouse_profession: item?.spouse_profession || null,
          });
        } else {
          totalSibs.push({
            ...sib,
            married: 0,
          });
        }
      } else {
        totalSibs.push({ ...sib });
      }
    }
    // console.log(totalSibs);
    dispatch(
      setBioDataDetails({
        ...bioDataDetails,
        siblings: totalSibs,
      })
    );
  };

  //Relative
  const handleRelativeAdd = (e) => {
    e.preventDefault();
    dispatch(
      setBioDataDetails({
        ...bioDataDetails,
        relatives: [
          ...bioDataDetails?.relatives,
          {
            id: uuidv4(),
            relative_types: null,
            relation: null,
            full_name: null,
            profession: null,
            location: null,
            married: 0,
          },
        ],
      })
    );
  };
  const handleRelativeRemove = (id, e) => {
    e.preventDefault();
    dispatch(
      setBioDataDetails({
        ...bioDataDetails,
        relatives: bioDataDetails?.relatives.filter((i) => i.id !== id),
      })
    );
  };
  const handleChangeRelativesData = (key, value, sibId) => {
    // console.log(key, value, sibId);
    let sibList = [];
    for (let i = 0; i < bioDataDetails?.relatives?.length; i++) {
      const sib = bioDataDetails?.relatives[i];
      if (sib.id === sibId) {
        sibList.push({ ...sib, [key]: value });
      } else {
        sibList.push(sib);
      }
    }
    dispatch(setBioDataDetails({ ...bioDataDetails, relatives: sibList }));
  };
  const handleRelativesMarriedStatus = (item, value) => {
    console.log({ value });
    // let targetSib = siblingFormData?.siblings.filter((i) => i.id === id);
    let totalSibs = [];
    for (let i = 0; i < bioDataDetails.relatives.length; i++) {
      const sib = bioDataDetails.relatives[i];
      if (sib.id === item?.id) {
        if (value === true) {
          console.log(sib);
          totalSibs.push({
            ...sib,
            married: 1,
            spouse_name: item?.spouse_name || null,
            spouse_profession: item?.spouse_profession || null,
          });
        } else {
          totalSibs.push({
            ...sib,
            married: 0,
          });
        }
      } else {
        totalSibs.push({ ...sib });
      }
    }
    // console.log(totalSibs);
    dispatch(
      setBioDataDetails({
        ...bioDataDetails,
        relatives: totalSibs,
      })
    );
  };

  const uploadButton = (
    <div>
      {loading ? <AiOutlineLoading /> : <AiOutlinePlus />}
      <div
        style={{
          marginTop: "8px",
        }}
      >
        Upload
      </div>
    </div>
  );

  const handleChangeProfilePicture = (imgId) => {
    axios
      .post(
        `${BIO.SET_PROFILE_PICTURE}`,
        { image_id: imgId },
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        message.success("Profile picture changed successfully");
        axios
          .get(BIO.GET_BIO_DATA, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            dispatch(
              setBioDataDetails({
                ...bioDataDetails,
                images: res.data.images,
              })
            );
          })
          .catch((err) => {
            console.log(err);
            navigate("/login", { replace: true });
          });
      })
      .catch((err) => {
        console.log(err);
        message.error("Something went wrong");
      });
  };

  // navigate("/thankyou", {
  // 	replace: true,
  // })

  return (
    <div
      style={{
        // backgroundColor: "#F4F0F0",
        // width: "100%",
        // backgroundColor: "#E22B6D",
        // backgroundColor: "#fbda61",
        // backgroundImage: "-webkit-gradient(45deg, #fbda61 0% , #ff5acd 100%)",
        // // background: "-webkit-linear-gradient(90deg, red , #ff5acd)",
        // background: "-webkit-linear-gradient(left, #fbda61 , #ff5acd)",
        // backgroundImage: "-o-linear-gradient(45deg, #fbda61 0% , #ff5acd 100%)",
        // backgroundImage: "linear-gradient(to right, #fbda61 , #ff5acd)",

        position: "relative",
        // minHeight: "100vh",
        // height: "100%",
        // backgroundAttachment: "fixed",
      }}
      className="edit-page-bg"
    >
      <Navbar />
      {<TimerPopup isTutorial="tutorial" />}
      <Row
        align="middle"
        justify="center"
        style={{
          // margin: "0 auto",
          padding: "100px 10px",
        }}
      >
        <Col xxl={14} xl={16} lg={18} md={22} xs={24}>
          {
            <h2 style={styleTitle}>
              Hi {bioDataDetails?.personal?.full_name},
            </h2>
          }
          <p style={styleHelpLine}>
            Please complete this form to explore your Life Partner...
          </p>
          <div
            className="edit-profile-progress"
            style={{ maxWidth: "400px", width: "100%", color: "#fff" }}
          >
            <Progress
              percent={bioDataDetails?.completion_percentage}
              status="active"
              // strokeColor="#e2286d"
              strokeColor="#fff"
              size="large"
              strokeWidth={12}
              trailColor="gray"
              style={{ color: "#fff" }}
            />
          </div>
          <Form
            action=""
            // style={{
            // 	display: "flex",
            // 	alignItems: "center",
            // 	justifyContent: "space-between",
            // 	gap: "5px",
            // 	width: "100%",
            // }}
            initialValues={{
              remember: true,
            }}
            onFinish={handleNeedInfoSubmit}
            style={{
              backgroundColor: "#fff",

              border: "1px solid #c2c2c2",
              borderRadius: "6px",
              padding: "5px",
              marginTop: "40px",
            }}
          >
            <Row
              align="middle"
              justify="center"
              gutter={[15, 15]}
              style={{
                // margin: "0 auto",
                paddingBottom: "10px",
                paddingTop: "20px",
                position: "relative",
              }}
            >
              {bioDataDetails?.images?.map((bioImg) => (
                <Col
                  key={bioImg.id}
                  md={5}
                  xs={8}
                  style={{
                    position: "relative",
                  }}
                  className="uploaded-item-1"
                >
                  <img
                    src={bioImg?.image}
                    style={{
                      width: "100%",
                      borderRadius: "4px",
                      maxHeight: "130px",
                      objectFit: "cover",
                      height: "100%",
                      border: bioImg.is_profile ? "2px solid #E2286D" : "none",
                    }}
                    alt="img icon"
                  />
                  <div
                    className="hover-style-1"
                    style={{
                      position: "absolute",
                      top: "0%",
                      left: "0%",
                      height: "100%",
                      width: "100%",
                      borderRadius: "4px",
                      backgroundColor: "rgba(33, 33, 33, 0.6)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Tooltip
                      placement="top"
                      title={
                        bioImg.is_profile
                          ? "Cannot delete active profile picture"
                          : "Click to delete photo"
                      }
                    >
                      <Button
                        type="primary"
                        danger
                        disabled={bioImg?.is_profile === 0 ? false : true}
                        onClick={() => handleDeletePhoto(bioImg)}
                        style={{
                          fontSize: "20px",
                          height: "auto",
                          padding: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                        }}
                      >
                        <MdDeleteForever />
                      </Button>
                    </Tooltip>
                    {
                      <Button
                        onClick={() => handleChangeProfilePicture(bioImg?.id)}
                        disabled={bioImg?.is_profile === 0 ? false : true}
                        style={{
                          background: "#e2286d",
                          color: "#fff",
                          border: "none",
                        }}
                      >
                        {bioImg?.is_profile === 0
                          ? "Set as Profile Picture"
                          : "Active Profile Picture"}
                      </Button>
                    }
                  </div>
                </Col>
              ))}
            </Row>
            <Row
              align="middle"
              justify="center"
              style={{
                // margin: "0 auto !important",
                padding: "10px 5px",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Col md={10} sm={24} xs={24}>
                {bioDataDetails?.images?.length < 4 ? (
                  <Form.Item name="">
                    <AntdImgCrop rotate quality={0.6} aspect={4 / 3}>
                      <Upload
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        // onRemove={onChange}
                        onPreview={onPreview}
                        className={`upload-wrapper ${
                          fileList?.length > 2 && "upload-not-allowed"
                        }`}
                        multiple
                        // disabled={fileList?.length === 3 ? true : false}
                      >
                        <RiImageAddLine
                          style={{
                            fontSize: "50px",
                            color:
                              bioDataDetails?.personal?.gender.toLowerCase() ===
                              "female"
                                ? "1px solid #ffacd0"
                                : "1px solid #00dbe2",
                          }}
                        />
                        <h4>Upload Photo</h4>
                        <small>Min: 1, Max: 3</small>
                      </Upload>
                    </AntdImgCrop>
                  </Form.Item>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "20px",
                      height: "160px",
                      width: "80%",
                      border: "1px solid gray",
                      borderRadius: "12px",
                    }}
                  >
                    <div>
                      <h4>You can upload maximum 3 photos</h4>
                      <small>
                        Want to upload new photos? please delete 1 first
                      </small>
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            {/* <h4 style={styleFormHeading}>Personal</h4> */}
            <div
              style={{
                ...styleFormHeading,
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <h4>Personal</h4>
              <small
                style={{
                  fontSize: "12px",
                  color: "rgb(234, 102, 137)",
                  fontWeight: "bold",
                }}
              >
                (Mandatory)
              </small>
            </div>
            <Row
              align="middle"
              justify="center"
              style={{
                // margin: "0 auto",
                padding: "10px 5px",
              }}
              gutter={[15, 20]}
            >
              <Col md={8} sm={24} xs={24}>
                <SelectCommon
                  isDisabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  name="display_name"
                  label="Display Name"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.display_name}
                  // levelIcon={<FaUserCircle style={{ color: "#e2286d" }} />}
                  // levelIcon={
                  // 	bioDataDetails?.personal?.gender?.toLowerCase() ===
                  // 	"male"
                  // 		? "👨🏻"
                  // 		: "👩🏻"
                  // }
                  options={bioDataDetails?.personal?.full_name
                    ?.split(" ")
                    .map((nameItem) => {
                      return {
                        key: nameItem,
                        value: nameItem,
                      };
                    })}
                />
              </Col>

              <Col md={8} sm={24} xs={24}>
                <HeightInput
                  isDisabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  nameOne="height_ft"
                  nameTwo="height_inch"
                  label="Height"
                  valOne={bioDataDetails?.personal?.height_ft}
                  valTwo={bioDataDetails?.personal?.height_inch}
                  type="text"
                  handleChangeFunc={handleChangePersonal}
                />
              </Col>
              <Col md={8} sm={24} xs={24}>
                <SelectCommon
                  isDisabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  name="marital_status"
                  label="Marital Status"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.marital_status}
                  // levelIcon={<FaUserCircle style={{ color: "#e2286d" }} />}
                  options={[
                    // { key: "Unmarried", value: "Unmarried" },
                    { key: "Never married", value: "Never married" },
                    { key: "Divorced", value: "Divorced" },
                    { key: "Widowed", value: "Widowed" },
                  ]}
                />
              </Col>
              <Col md={8} sm={24} xs={24}>
                <DateCommon
                  isDisabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  name="dob"
                  label="Date Of Birth"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.dob}
                />
              </Col>

              <Col md={8} sm={24} xs={24}>
                <SelectCommon
                  isDisabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  name="religion"
                  label="Religion"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.religion}
                  // levelIcon={<FaUserCircle style={{ color: "#e2286d" }} />}
                  options={[
                    { key: "Islam", value: "Islam" },
                    { key: "Hindu", value: "Hindu" },
                    { key: "Christian", value: "Christian" },
                    { key: "Buddhist", value: "Buddhist" },
                    { key: "Other", value: "Other" },
                  ]}
                />
              </Col>

              <Col md={8} sm={24} xs={24}>
                <SelectCommon
                  name="blood_group"
                  label="Blood Group"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.blood_group}
                  // levelIcon={<FaUserCircle style={{ color: "#e2286d" }} />}
                  options={[
                    { key: "A+", value: "A+" },
                    { key: "A-", value: "A-" },
                    { key: "B+", value: "B+" },
                    { key: "B-", value: "B-" },
                    { key: "O+", value: "O+" },
                    { key: "O-", value: "O-" },
                    { key: "AB+", value: "AB+" },
                    { key: "AB-", value: "AB-" },
                  ]}
                />
              </Col>
              <Col md={8} sm={24} xs={24}>
                <SelectCommon
                  name="open_to_move_abroad"
                  label="Open To Move Abroad"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.open_to_move_abroad}
                  options={[
                    { key: "Yes", value: "Yes" },
                    { key: "No", value: "No" },
                    { key: "Maybe", value: "Maybe" },
                  ]}
                />
              </Col>
              <Col md={8} sm={24} xs={24}>
                <SelectCommon
                  name="smoking_habit"
                  label="Smoking Habit"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.smoking_habit}
                  levelIcon={"🚭"}
                  options={[
                    { key: "Smoker", value: "Smoker" },
                    { key: "Non-smoker", value: "Non-smoker" },
                    { key: "Social smoker", value: "Social smoker" },
                  ]}
                />
              </Col>
              <Col md={8} sm={24} xs={24}>
                <SelectCommon
                  name="drinking_habit"
                  label="Drinking Habit"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.drinking_habit}
                  options={[
                    { key: "Drinker", value: "Drinker" },
                    { key: "Non-Drinker", value: "Non-Drinker" },
                    { key: "Social Drinker", value: "Social Drinker" },
                  ]}
                  levelIcon={"🚱"}
                />
              </Col>
              {bioDataDetails?.personal?.stay_in?.toLowerCase() !==
                "bangladesh" && (
                <Fragment>
                  <Col md={8} sm={24} xs={24}>
                    <SelectCommon
                      name="country_living_in"
                      label="Country Living In"
                      value={bioDataDetails?.personal?.country_living_in}
                      handleChangeFunc={handleChangePersonal}
                      // levelIcon={<MdNoDrinks style={{ color: "#e2286d" }} />}
                      options={countryList.map((item) => {
                        return {
                          key: item.name,
                          value: item.name,
                        };
                      })}
                    />
                  </Col>
                  <Col md={8} sm={24} xs={24}>
                    <SelectCommon
                      name="citizenship_status"
                      label="Citizenship Status"
                      value={bioDataDetails?.personal?.citizenship_status}
                      handleChangeFunc={handleChangePersonal}
                      // levelIcon={<MdNoDrinks style={{ color: "#e2286d" }} />}
                      options={[
                        { key: "Citizen", value: "Citizen" },
                        {
                          key: "Permanent resident",
                          value: "Permanent resident",
                        },
                        { key: "Non-citizen", value: "Non-citizen" },
                      ]}
                    />
                  </Col>
                </Fragment>
              )}
            </Row>
            {/* <h4 style={styleFormHeading}>Contact Information</h4> */}
            <div
              style={{
                ...styleFormHeading,
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <h4>Contact Information</h4>
              <small
                style={{
                  fontSize: "12px",
                  color: "rgb(234, 102, 137)",
                  fontWeight: "bold",
                }}
              >
                (Mandatory)
              </small>
            </div>
            <Row
              align="middle"
              justify="center"
              style={{
                // margin: "0 auto",
                padding: "10px 5px",
              }}
              gutter={[15, 20]}
            >
              <Col md={8} sm={24} xs={24}>
                <InputCommon
                  isDisabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  name="email"
                  label="Email"
                  type="email"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.email}
                  isEmailVerified={bioDataDetails?.personal?.is_email_verified}
                />
              </Col>
              <Col md={8} sm={24} xs={24}>
                <PhoneInput
                  isDisabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  name="mobile_number"
                  label="Phone"
                  // phoneCode={bioDataDetails?.personal?.mobile_code}
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.mobile_number}
                />
              </Col>
              <Col md={8} sm={24} xs={24}>
                <SelectCommon
                  isDisabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  name="home_district"
                  label="Home District"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.home_district}
                  levelIcon={<FaUserCircle style={{ color: "#e2286d" }} />}
                  options={districtList.map((item) => {
                    return {
                      key: item.name,
                      value: item.name,
                    };
                  })}
                />
              </Col>
              <Col span={24}>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      dispatch(
                        setBioDataDetails({
                          ...bioDataDetails,
                          personal: {
                            ...bioDataDetails?.personal,
                            show_number: true,
                          },
                        })
                      );
                    } else {
                      dispatch(
                        setBioDataDetails({
                          ...bioDataDetails,
                          personal: {
                            ...bioDataDetails?.personal,
                            show_number: false,
                          },
                        })
                      );
                    }
                  }}
                  defaultChecked={bioDataDetails?.personal?.show_number}
                  style={{
                    fontSize: "18px",
                    backgroundColor: "transparent",
                    paddingBottom: "20px",
                  }}
                >
                  Show phone number in preview biodata
                </Checkbox>
              </Col>
              <Col md={12} sm={24} xs={24}>
                <InputCommon
                  isDisabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  name="present_address"
                  label="Present Address"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.present_address}
                  type="text"
                />
              </Col>
              <Col md={12} sm={24} xs={24}>
                <InputCommon
                  isDisabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  name="present_city"
                  label="Present City"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.present_city}
                  type="text"
                />
              </Col>
              <Col span={24}>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      dispatch(
                        setBioDataDetails({
                          ...bioDataDetails,
                          personal: {
                            ...bioDataDetails?.personal,
                            is_same_as_present_address: true,
                            permanent_address:
                              bioDataDetails?.personal?.present_address,
                            permanent_city:
                              bioDataDetails?.personal?.present_city,
                          },
                        })
                      );
                    } else {
                      dispatch(
                        setBioDataDetails({
                          ...bioDataDetails,
                          personal: {
                            ...bioDataDetails?.personal,
                            is_same_as_present_address: false,
                            permanent_address: null,
                            permanent_city: null,
                          },
                        })
                      );
                    }
                  }}
                  defaultChecked={
                    bioDataDetails?.personal?.is_same_as_present_address
                  }
                  style={{ fontSize: "18px", backgroundColor: "transparent" }}
                >
                  Same as present address
                </Checkbox>
              </Col>
              <Col md={12} sm={24} xs={24}>
                <InputCommon
                  isDisabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  name="permanent_address"
                  label="Permanent Address"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.permanent_address}
                  type="text"
                />
              </Col>
              <Col md={12} sm={24} xs={24}>
                <InputCommon
                  isDisabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  name="permanent_city"
                  label="Permanent City"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.permanent_city}
                  type="text"
                />
              </Col>
            </Row>
            {/* <h4 style={styleFormHeading}>Professional Information</h4> */}
            <div
              style={{
                ...styleFormHeading,
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <h4>Professional Information</h4>
              <small
                style={{
                  fontSize: "12px",
                  color: "rgb(234, 102, 137)",
                  fontWeight: "bold",
                }}
              >
                (Mandatory)
              </small>
            </div>
            <Row
              align="middle"
              justify="center"
              style={{
                // margin: "0 auto",
                padding: "10px 5px",
              }}
              gutter={[15, 20]}
            >
              <Col md={12} sm={24} xs={24}>
                <InputCommon
                  name="professional_organization"
                  label="Name of Company/ Organization/ Institution"
                  handleChangeFunc={handleChangeProfessional}
                  value={
                    bioDataDetails?.professional?.professional_organization
                  }
                  type="text"
                  maxLength={50}
                />
              </Col>
              <Col md={12} sm={24} xs={24}>
                <SelectCommon
                  name="organization_type"
                  label="Company type/Organization type"
                  handleChangeFunc={handleChangeProfessional}
                  value={bioDataDetails?.professional?.organization_type}
                  // levelIcon={<FaUserCircle style={{ color: "#e2286d" }} />}
                  options={[
                    { key: "Govt", value: "Govt" },
                    { key: "Semi Govt", value: "Semi Govt" },
                    { key: "NGO", value: "NGO" },
                    { key: "Private Company", value: "Private Company" },
                    {
                      key: "Multinational Company",
                      value: "Multinational Company",
                    },
                    {
                      key: "Student",
                      value: "Student",
                    },
                    {
                      key: "Business",
                      value: "Business",
                    },
                  ]}
                />
              </Col>
              <Col md={12} sm={24} xs={24}>
                <InputCommon
                  name="designation"
                  label="Designation"
                  handleChangeFunc={handleChangeProfessional}
                  value={bioDataDetails?.professional?.designation}
                  type="text"
                  maxLength={30}
                />
              </Col>
              <Col md={12} sm={24} xs={24}>
                <InputCommon
                  name="occupation"
                  label="Occupation"
                  handleChangeFunc={handleChangeProfessional}
                  value={bioDataDetails?.professional?.occupation}
                  type="text"
                  maxLength={30}
                />
              </Col>
            </Row>
            {/* <h4 style={styleFormHeading}>Educational Information </h4> */}
            <div
              style={{
                ...styleFormHeading,
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <h4>Educational Information </h4>
              <small
                style={{
                  fontSize: "12px",
                  color: "rgb(234, 102, 137)",
                  fontWeight: "bold",
                }}
              >
                (Mandatory)
              </small>
            </div>
            <Row
              align="middle"
              justify="center"
              style={{
                // margin: "0 auto",
                padding: "10px 5px",
              }}
              gutter={[15, 20]}
            >
              <Col span={24}>
                {bioDataDetails?.education?.map((edu) => (
                  <div key={edu.education_type}>
                    <div
                      style={{
                        textAlign: "start",
                        fontSize: "18px",
                        backgroundColor: "#ffe0eb",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "5px",
                        marginBottom: "5px",
                        borderRadius: "6px",
                      }}
                    >
                      <span>{edu.education_type}</span>
                      {bioDataDetails?.personal?.gender?.toLowerCase() ===
                      "female"
                        ? edu.education_type !== "College" && (
                            <button
                              onClick={() => handleRemoveEducation(edu)}
                              style={{
                                color: "#e22b6d",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "none",
                                cursor: "pointer",
                              }}
                              disabled={
                                bioDataDetails?.personal?.is_editable === true
                                  ? false
                                  : true
                              }
                            >
                              <MdDeleteOutline size={25} />
                            </button>
                          )
                        : edu.education_type !== "University" && (
                            <button
                              onClick={() => handleRemoveEducation(edu)}
                              style={{
                                color: "#e22b6d",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "none",
                                cursor: "pointer",
                              }}
                              disabled={
                                bioDataDetails?.personal?.is_editable === true
                                  ? false
                                  : true
                              }
                            >
                              <MdDeleteOutline size={25} />
                            </button>
                          )}
                    </div>
                    <Row
                      align="middle"
                      justify="center"
                      style={{
                        // margin: "0 auto",
                        padding: "10px 5px",
                      }}
                      gutter={[15, 20]}
                    >
                      <Col md={6} sm={24} xs={24}>
                        <SelectCommon
                          isDisabled={
                            bioDataDetails?.personal?.is_editable ? false : true
                          }
                          name="education_degree"
                          parentName={edu.education_type}
                          label="Degree"
                          handleChangeFunc={handleChangeEducation}
                          value={edu?.education_degree}
                          // levelIcon={<FaUserCircle style={{ color: "#e2286d" }} />}
                          options={
                            edu.education_type === "School"
                              ? schoolOptions
                              : edu.education_type === "College"
                              ? collegeOptions
                              : edu.education_type === "University"
                              ? universityOptions
                              : postGraduationOptions
                          }
                        />
                      </Col>
                      <Col md={6} sm={24} xs={24}>
                        <InputCommon
                          isDisabled={
                            bioDataDetails?.personal?.is_editable ? false : true
                          }
                          name="education_subject"
                          label="Major Subject"
                          parentName={edu.education_type}
                          handleChangeFunc={handleChangeEducation}
                          value={edu?.education_subject}
                          type="text"
                          isRequired={true}
                          maxLength={30}
                        />
                      </Col>
                      <Col md={6} sm={24} xs={24}>
                        <InputCommon
                          isDisabled={
                            bioDataDetails?.personal?.is_editable ? false : true
                          }
                          name="education_institution"
                          label="Name of Institution"
                          parentName={edu.education_type}
                          handleChangeFunc={handleChangeEducation}
                          value={edu?.education_institution}
                          type="text"
                          isRequired={true}
                          maxLength={50}
                        />
                      </Col>
                      <Col md={6} sm={24} xs={24}>
                        <YearPickerCommon
                          isDisabled={
                            bioDataDetails?.personal?.is_editable ? false : true
                          }
                          name="education_passing_year"
                          label="Passing/Expected Year"
                          parentName={edu.education_type}
                          handleChangeFunc={handleChangeEducation}
                          value={edu?.education_passing_year}
                          type="text"
                          isRequired={true}
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                    padding: "5px",
                  }}
                >
                  {bioDataDetails?.personal?.is_editable
                    ? eduBtnArr
                        ?.filter((edu) =>
                          bioDataDetails?.personal?.gender?.toLowerCase() ===
                          "female"
                            ? edu.education_type !== "College"
                            : edu.education_type !== "University"
                        )
                        ?.map((eduRender, i) => (
                          <button
                            key={i}
                            onClick={(e) => handleAddEducation(e, eduRender)}
                            style={{
                              padding: "10px 15px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "5px",
                              border: "1px solid transparent",
                              borderRadius: "8px",
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#fff",
                              cursor: "pointer",
                              backgroundColor:
                                bioDataDetails?.personal?.gender?.toLowerCase() ===
                                "female"
                                  ? "#ce4087"
                                  : "#1885F2",
                            }}
                          >
                            {eduRender.education_type}
                          </button>
                        ))
                    : null}
                </div>
              </Col>
            </Row>
            {/* <h4 style={styleFormHeading}>Parents Information</h4> */}
            <div
              style={{
                ...styleFormHeading,
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <h4>Parents Information </h4>
              <small
                style={{
                  fontSize: "12px",
                  color: "rgb(234, 102, 137)",
                  fontWeight: "bold",
                }}
              >
                (Mandatory)
              </small>
            </div>
            <Row
              align="middle"
              justify="center"
              style={{
                // margin: "0 auto",
                padding: "10px 5px",
              }}
              gutter={[15, 20]}
            >
              <Col md={12} sm={24} xs={24}>
                <InputCommon
                  isDisabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  name="father_name"
                  label="Father Name"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.father_name}
                  type="text"
                />
              </Col>
              <Col md={12} sm={24} xs={24}>
                <InputCommon
                  isDisabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  name="father_occupation"
                  label="Occupation"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.father_occupation}
                  type="text"
                  maxLength={30}
                />
              </Col>
              <Col md={12} sm={24} xs={24}>
                <InputCommon
                  isDisabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  name="mother_name"
                  label="Mother Name"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.mother_name}
                  type="text"
                />
              </Col>
              <Col md={12} sm={24} xs={24}>
                <InputCommon
                  isDisabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  name="mother_occupation"
                  label="Occupation"
                  handleChangeFunc={handleChangePersonal}
                  value={bioDataDetails?.personal?.mother_occupation}
                  type="text"
                  maxLength={30}
                />
              </Col>
            </Row>
            <div
              style={{
                ...styleFormHeading,
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <h4>Interest & Personality</h4>
              <small
                style={{
                  fontSize: "12px",
                  color: "rgb(234, 102, 137)",
                  fontWeight: "bold",
                }}
              >
                (Min: 3, Max: 15)
              </small>
            </div>
            <Row
              align="middle"
              justify="center"
              style={{
                padding: "10px 5px",
              }}
              gutter={[15, 20]}
            >
              <Col md={12} sm={24} xs={24}>
                {/* <SelectCommon
									name="interests"
									label="Interest"
									isMultiple={true}
									
									options={interests.map((item) => {
										return {
											key: item.name,
											value: item.name,
										};
									})}
								/> */}
                <SelectMultiple
                  name="interest"
                  value={bioDataDetails?.interest}
                  label="Interest"
                  handleChangeFunc={handleChangeInterestPersonality}
                  options={interests.map((item) => {
                    return {
                      key: item.id,
                      value: item.name,
                    };
                  })}
                />
              </Col>
              <Col md={12} sm={24} xs={24}>
                <SelectMultiple
                  name="personality"
                  value={bioDataDetails?.personality}
                  label="Personality"
                  handleChangeFunc={handleChangeInterestPersonality}
                  options={personalities.map((item) => {
                    return {
                      key: item.id,
                      value: item.name,
                    };
                  })}
                />
              </Col>
            </Row>
            {/* <h4 style={styleFormHeading}>About Me & Expectation</h4> */}
            <div
              style={{
                ...styleFormHeading,
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <h4>About Me & Expectation </h4>
              <small
                style={{
                  fontSize: "12px",
                  color: "rgb(234, 102, 137)",
                  fontWeight: "bold",
                }}
              >
                (Optional)
              </small>
            </div>
            <Row
              align="middle"
              justify="center"
              style={{
                // margin: "0 auto",
                padding: "10px 5px",
              }}
              gutter={[15, 20]}
            >
              <Col md={12} sm={24} xs={24} className="col-md-6">
                <div className="about_me">
                  <h5
                    className="mb-4"
                    style={{
                      textAlign: "start",
                      fontSize: "18px",
                      color: "#ea6689",
                      // margin: "0 0 10px 0",
                      marginBottom: "10px",
                      padding: "0",
                      paddingBottom: "15px",
                      borderBottom: "1px dashed gray",
                    }}
                  >
                    About Me :{" "}
                    <small style={{ fontSize: "13px" }}>
                      Maximum: 150 word
                    </small>
                  </h5>
                  <Form.Item
                    style={
                      {
                        //margin: "20px 0"
                      }
                    }
                  >
                    <Input.TextArea
                      style={{ backgroundColor: "transparent" }}
                      maxLength={(e) =>
                        e.target.value.trim().split(" ")?.length <= 150
                      }
                      value={bioDataDetails?.partner_expectation?.about_me}
                      onChange={(e) => {
                        e.target.value.split(" ")?.length <= 150
                          ? handleChangeExpectation("about_me", e.target.value)
                          : message.warning("You can write maximum 150 words.");
                      }}
                      rows={4}
                      placeholder="Write something about your self"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col md={12} sm={24} xs={24} className="col-md-6">
                <div className="expectation">
                  <h5
                    className="mb-4"
                    style={{
                      textAlign: "start",
                      fontSize: "18px",
                      color: "#ea6689",
                      // margin: "0 0 10px 0",
                      marginBottom: "10px",
                      paddingBottom: "15px",
                      borderBottom: "1px dashed gray",
                    }}
                  >
                    Partner Expectation :{" "}
                    <small style={{ fontSize: "13px" }}>
                      Maximum: 150 word
                    </small>
                  </h5>
                  <Form.Item
                    style={
                      {
                        // margin: "20px 0"
                      }
                    }
                  >
                    <Input.TextArea
                      style={{ backgroundColor: "transparent" }}
                      rows={4}
                      placeholder="Tell us about your expectations from your better half ...!"
                      maxLength={(e) =>
                        e.target.value.trim().split(" ")?.length <= 150
                      }
                      value={
                        bioDataDetails?.partner_expectation?.partner_expectation
                      }
                      onChange={(e) => {
                        e.target.value.split(" ")?.length <= 150
                          ? handleChangeExpectation(
                              "partner_expectation",
                              e.target.value
                            )
                          : message.warning("You can write maximum 150 words.");
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            {/* <h4 style={styleFormHeading}>Siblings Information</h4> */}
            <div
              style={{
                ...styleFormHeading,
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <h4>Siblings Information</h4>
              <small
                style={{
                  fontSize: "12px",
                  color: "rgb(234, 102, 137)",
                  fontWeight: "bold",
                }}
              >
                (Optional)
              </small>
            </div>
            <Row
              align="middle"
              justify="center"
              style={{
                // margin: "0 auto",
                padding: "10px 5px",
              }}
              gutter={[15, 20]}
            >
              {bioDataDetails?.siblings?.map((sib, sibIndex) => (
                <Col key={sibIndex} md={12} sm={24} xs={24}>
                  <div
                    style={{
                      width: "100%",
                      padding: "20px 10px",
                      borderRadius: "8px",
                      boxShadow: "2px 2px 6px gray",
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <button
                        onClick={(e) => handleSiblingRemove(sib.id, e)}
                        style={{
                          border: "none",
                          color: "red",
                          backgroundColor: "transparent",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      >
                        <AiOutlineCloseCircle />
                      </button>
                    </div>
                    <Row gutter={[10, 10]}>
                      <Col md={12} sm={12} xs={24}>
                        <SelectCommon
                          isDisabled={
                            bioDataDetails?.personal?.is_editable ? false : true
                          }
                          name="siblings_type"
                          label="Siblings Type"
                          parentName={sib.id}
                          handleChangeFunc={handleChangeSiblingsData}
                          value={sib?.siblings_type}
                          // levelIcon={<FaUserCircle style={{ color: "#e2286d" }} />}
                          options={[
                            { key: 2, value: "Brother" },
                            { key: 3, value: "Sister" },
                          ]}
                        />
                      </Col>
                      <Col md={12} sm={12} xs={24}>
                        <InputCommon
                          isDisabled={
                            bioDataDetails?.personal?.is_editable ? false : true
                          }
                          name="full_name"
                          label="Full Name"
                          value={sib?.full_name}
                          parentName={sib.id}
                          handleChangeFunc={handleChangeSiblingsData}
                          type="text"
                          isRequired={false}
                          maxLength={40}
                        />
                      </Col>
                      <Col md={12} sm={12} xs={24}>
                        <InputCommon
                          isDisabled={
                            bioDataDetails?.personal?.is_editable ? false : true
                          }
                          name="profession"
                          label="Profession"
                          value={sib?.profession}
                          parentName={sib.id}
                          handleChangeFunc={handleChangeSiblingsData}
                          type="text"
                          isRequired={false}
                          maxLength={30}
                        />
                      </Col>
                      <Col md={12} sm={12} xs={24}>
                        <InputCommon
                          isDisabled={
                            bioDataDetails?.personal?.is_editable ? false : true
                          }
                          name="location"
                          label="Location"
                          value={sib?.location}
                          parentName={sib.id}
                          handleChangeFunc={handleChangeSiblingsData}
                          type="text"
                          isRequired={false}
                          maxLength={30}
                        />
                      </Col>
                      <Col
                        md={12}
                        sm={12}
                        xs={24}
                        style={{
                          paddingLeft: "10px",
                          textAlign: "left",
                          marginBottom: "20px",
                        }}
                      >
                        <Checkbox
                          onChange={(e) =>
                            handleMarriedStatus(sib, e.target.checked)
                          }
                          defaultChecked={sib?.married}
                          style={{
                            fontSize: "16px",
                            backgroundColor: "transparent",
                          }}
                        >
                          Married
                        </Checkbox>
                      </Col>
                      {sib?.married ? (
                        <Fragment>
                          <Col md={12} sm={12} xs={24}>
                            <InputCommon
                              isDisabled={
                                bioDataDetails?.personal?.is_editable
                                  ? false
                                  : true
                              }
                              name="spouse_name"
                              label="Spouse Name"
                              value={sib?.spouse_name}
                              parentName={sib.id}
                              handleChangeFunc={handleChangeSiblingsData}
                              type="text"
                              isRequired={false}
                              maxLength={30}
                            />
                          </Col>
                          <Col md={12} sm={12} xs={24}>
                            <InputCommon
                              isDisabled={
                                bioDataDetails?.personal?.is_editable
                                  ? false
                                  : true
                              }
                              name="spouse_profession"
                              label="Profession"
                              value={sib?.spouse_profession}
                              parentName={sib.id}
                              handleChangeFunc={handleChangeSiblingsData}
                              type="text"
                              isRequired={false}
                              maxLength={30}
                            />
                          </Col>
                        </Fragment>
                      ) : null}
                    </Row>
                  </div>
                </Col>
              ))}
              <div
                className="inline my-4"
                style={{ width: "140px", paddingLeft: "15px" }}
              >
                <button
                  disabled={
                    bioDataDetails?.personal?.is_editable ? false : true
                  }
                  style={{
                    padding: "10px 15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    border: "1px solid transparent",
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#fff",
                    cursor: "pointer",
                    backgroundColor:
                      bioDataDetails?.personal?.gender?.toLowerCase() ===
                      "female"
                        ? "#ce4087"
                        : "#1885F2",
                  }}
                  onClick={(e) => handleSiblingAdd(e)}
                >
                  <span>Add Field</span>
                  <AiOutlinePlus style={{ fontSize: "20px" }} />
                </button>
              </div>
            </Row>
            {/* <h4 style={styleFormHeading}>Relatives Information</h4> */}
            <div
              style={{
                ...styleFormHeading,
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "10px",
              }}
            >
              <h4>Relatives Information</h4>
              <small
                style={{
                  fontSize: "12px",
                  color: "rgb(234, 102, 137)",
                  fontWeight: "bold",
                }}
              >
                (Optional)
              </small>
            </div>
            <Row
              align="middle"
              justify="center"
              style={{
                // margin: "0 auto",
                padding: "10px 5px",
              }}
              gutter={[15, 20]}
            >
              {bioDataDetails?.relatives?.map((sib, sibIndex) => (
                <Col key={sibIndex} md={12} sm={12} xs={24}>
                  <div
                    style={{
                      width: "100%",
                      padding: "20px 10px",
                      borderRadius: "8px",
                      boxShadow: "2px 2px 6px gray",
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <button
                        onClick={(e) => handleRelativeRemove(sib.id, e)}
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          color: "red",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      >
                        <AiOutlineCloseCircle />
                      </button>
                    </div>
                    <Row gutter={[10, 10]}>
                      <Col md={12} sm={12} xs={24}>
                        <SelectCommon
                          name="relative_types"
                          label="Maternal/Paternal"
                          parentName={sib.id}
                          handleChangeFunc={handleChangeRelativesData}
                          value={sib?.relative_types}
                          // levelIcon={<FaUserCircle style={{ color: "#e2286d" }} />}
                          options={[
                            { key: 2, value: "Paternal" },
                            { key: 3, value: "Maternal" },
                          ]}
                        />
                      </Col>
                      <Col md={12} sm={12} xs={24}>
                        <InputCommon
                          name="relation"
                          label="Relation"
                          parentName={sib.id}
                          handleChangeFunc={handleChangeRelativesData}
                          value={sib?.relation}
                          type="text"
                          isRequired={false}
                          maxLength={30}
                        />
                      </Col>
                      <Col md={12} sm={12} xs={24}>
                        <InputCommon
                          name="full_name"
                          label="Full Name"
                          parentName={sib.id}
                          handleChangeFunc={handleChangeRelativesData}
                          value={sib?.full_name}
                          type="text"
                          isRequired={false}
                          maxLength={30}
                        />
                      </Col>
                      <Col md={12} sm={12} xs={24}>
                        <InputCommon
                          name="profession"
                          label="Profession"
                          parentName={sib.id}
                          handleChangeFunc={handleChangeRelativesData}
                          value={sib?.profession}
                          type="text"
                          isRequired={false}
                          maxLength={30}
                        />
                      </Col>
                      <Col md={12} sm={12} xs={24}>
                        <InputCommon
                          name="location"
                          label="Location"
                          parentName={sib.id}
                          handleChangeFunc={handleChangeRelativesData}
                          // onChange={(e) =>
                          //   handleChangeData(sib.id, "location", e.target.value)
                          // }
                          value={sib?.location}
                          type="text"
                          isRequired={false}
                          maxLength={30}
                        />
                      </Col>
                      <Col
                        md={12}
                        sm={12}
                        xs={24}
                        style={{
                          paddingLeft: "10px",
                          textAlign: "left",
                          marginBottom: "20px",
                        }}
                      >
                        <Checkbox
                          onChange={(e) =>
                            handleRelativesMarriedStatus(sib, e.target.checked)
                          }
                          defaultChecked={sib?.married === 1 ? true : false}
                          style={{
                            fontSize: "16px",
                            backgroundColor: "transparent",
                          }}
                        >
                          Married
                        </Checkbox>
                      </Col>
                      {sib?.married === 1 ? (
                        <Fragment>
                          <Col md={12} sm={12} xs={24}>
                            <InputCommon
                              name="spouse_name"
                              label="Spouse Name"
                              parentName={sib.id}
                              handleChangeFunc={handleChangeRelativesData}
                              value={sib?.spouse_name}
                              type="text"
                              isRequired={false}
                              maxLength={30}
                            />
                          </Col>
                          <Col md={12} sm={12} xs={24}>
                            <InputCommon
                              name="spouse_profession"
                              label="Profession"
                              parentName={sib.id}
                              handleChangeFunc={handleChangeRelativesData}
                              value={sib?.spouse_profession}
                              type="text"
                              isRequired={false}
                              maxLength={30}
                            />
                          </Col>
                        </Fragment>
                      ) : null}
                    </Row>
                  </div>
                </Col>
              ))}
              <div
                className="inline my-4"
                style={{ width: "140px", paddingLeft: "15px" }}
              >
                <button
                  style={{
                    padding: "10px 15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    border: "1px solid transparent",
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#fff",
                    cursor: "pointer",
                    backgroundColor:
                      bioDataDetails?.personal?.gender?.toLowerCase() ===
                      "female"
                        ? "#ce4087"
                        : "#1885F2",
                  }}
                  onClick={(e) => handleRelativeAdd(e)}
                >
                  <span>Add Field</span>
                  <AiOutlinePlus style={{ fontSize: "20px" }} />
                </button>
              </div>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px 0",
              }}
            >
              <button
                type="submit"
                // onClick={handleNeedInfoSubmit}
                style={{
                  padding: "8px 15px",
                  fontSize: "20px",
                  backgroundColor: "#e2286d",
                  color: "#fff",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Submit
              </button>
            </div>
          </Form>
        </Col>
        <Modal
          title="Set Profile Picture"
          open={changePictureModal}
          onOk={() => {
            setChangePictureModal(false);
            navigate("/thankyou", {
              replace: true,
            });
          }}
          onCancel={() => {
            setChangePictureModal(false);
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            {bioDataDetails?.images?.map((imgItem) => (
              <div
                key={imgItem.id}
                style={{
                  maxWidth: "200px",
                  width: "100%",
                  // height: "450px",
                  // position: "relative",
                  border: imgItem.is_profile ? "2px solid #ff0043" : "none",
                  lineHeight: "0",
                  height: "100%",
                }}
                className="modal-photo"
              >
                <img src={imgItem.image} alt="User" style={{ width: "100%" }} />
                <div
                  className="modal-photo-absolute"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    // background: "#ff0043",
                    backgroundColor: "rgba(0, 0, 0, 0.4)",
                  }}
                >
                  <Button
                    onClick={() => handleChangeProfilePicture(imgItem.id)}
                    disabled={imgItem?.is_profile === 1 ? true : false}
                    style={{
                      padding: "7px 12px",
                      border: "none",
                      borderRadius: "6px",
                      // cursor: "pointer",
                      background: "#ff0043",
                      color: "#fff",
                    }}
                  >
                    {imgItem.is_profile
                      ? "Active profile picture"
                      : "set as profile picture"}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </Modal>
      </Row>
    </div>
  );
};

export default EditBioData;
const styleTitle = {
  // color: "#E22B6D",
  color: "#fff",
  fontSize: "35px",
  marginBottom: "10px",
  lineHeight: "40px",
  fontWeight: "600",
};
const styleHelpLine = {
  // color: "#808080",
  color: "#fff",
  fontSize: "22px",
  marginBottom: "8px",
  lineHeight: "25px",
  fontWeight: "500",
};

const styleFormHeading = {
  textAlign: "start",
  fontSize: "20px",
  color: "#e2286d",
  margin: "15px 0",
  padding: "0",
  paddingBottom: "5px",
  borderBottom: "1px dashed gray",
};
