import { useSelector } from "react-redux";
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";

import "./App.css";
// import { BASE_URL } from "./app/config";
// import { setActiveTab } from "./app/slices/activeFormTab";
// import { setClearAuth } from "./app/slices/auth";
// import { setBioDataDetails, setClearMultiForm } from "./app/slices/multiForm";
// import { setClearQuiz } from "./app/slices/quiz";
import Forget from "./components/Forget/Forget";
import ThankYou from "./components/ThankYou/ThankYou";
import DashboardPage from "./pages/DashboardPage";
import ExplorePage from "./pages/ExplorePage";
import FaqPage from "./pages/FaqPage";
import LoginPage from "./pages/LoginPage";
import PreviewPage from "./pages/PreviewPage";
import QuizPage from "./pages/QuizPage";
import RegisterPage from "./pages/RegisterPage";
import TermsConditionPage from "./pages/TermsConditionPage";
import { jsonLdData } from "./utils/JSON-LD";
import AnalyticsPage from "./pages/AnalyticsPage";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import PreviewOthers from "./components/Preview/PreviewOthers";

import PaymentSuccessPage from "./pages/PaymentSuccessPage";
import PaymentFailedPage from "./pages/PaymentFailedPage";

import PricingPage from "./pages/PricingPage";
import RefundPolicy from "./pages/RefundPolicy";
import ContactPage from "./pages/ContactPage";
// import ReactGA from "react-ga";
// import ReactPixel from "react-facebook-pixel";

function PrivateOutlet({ children }) {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  // axios
  // 	.get(`${BASE_URL}/me`, {
  // 		headers: {
  // 			Authorization: `Bearer ${user.access_token}`,
  // 			"Content-Type": "application/json",
  // 		},
  // 	})
  // 	.then((res) => {
  // 		console.log("Quiz Compiled");
  // 		return children ? children : <Outlet />;
  // 	})
  // 	.catch((err) => {
  // 		dispatch(setClearAuth());
  // 		dispatch(setClearMultiForm());
  // 		dispatch(setClearQuiz());
  // 		dispatch(setBioDataDetails({}));
  // 		dispatch(setActiveTab(0));
  // 		return navigate("/login", { replace: true });
  // 		// return <Navigate to="/login" replace />;
  // 	});

  return user ? <Outlet /> : <Navigate to="/login" replace={true} />;
}

//Flag + Call Code
//https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/index.json

function App() {
  const location = useLocation();
  const advancedMatching = {};
  const options = {
    autoConfig: true,
    debug: false,
  };

  // ReactPixel.init(
  // 	process.env.REACT_APP_FB_PIXEL_KEY,
  // 	advancedMatching,
  // 	options
  // );

  // ReactGA.initialize(process.env.REACT_APP_G_TAG_KEY_OLD);
  // ReactGA.initialize(process.env.REACT_APP_G_TAG_KEY_NEW);
  // ReactGA.initialize(
  // 	[
  // 		{
  // 			trackingId: process.env.REACT_APP_G_TAG_KEY_OLD,
  // 			// gaOptions: { name: "tracker1" },
  // 		},
  // 		{
  // 			trackingId: process.env.REACT_APP_G_TAG_KEY_NEW,
  // 			// gaOptions: { name: "tracker2" },
  // 		},
  // 	],
  // 	{ debug: true, alwaysSendToDefaultTracker: false }
  // );

  // useEffect(() => {
  // 	ReactGA.pageview(window.location.pathname + window.location.search);

  // 	ReactPixel.pageView();
  // }, [location.pathname]);
  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(jsonLdData.organization),
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(jsonLdData.localBusiness),
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(jsonLdData.videoObjects),
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData.faqPage) }}
      />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<RegisterPage />} />

        <Route path="/forgot" element={<Forget />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/terms" element={<TermsConditionPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/payment-success" element={<PaymentSuccessPage />} />
        <Route path="/payment-failed" element={<PaymentFailedPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/contact" element={<ContactPage />} />

        <Route path="/biodata" element={<PrivateOutlet />}>
          <Route path="" element={<DashboardPage />} />
          <Route path="quiz" element={<QuizPage />} />
          <Route path="explore" element={<ExplorePage />} />
          <Route path="preview" element={<PreviewPage />} />
          <Route path="analytics" element={<AnalyticsPage />} />
          <Route path="analytics/preview/:userId" element={<PreviewOthers />} />
          <Route path="explore/preview/:userId" element={<PreviewOthers />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
