import React, { useEffect, useState } from "react";
import { AiOutlinePlayCircle } from "react-icons/ai";
import "./TimerPopup.css";
import { TUTORIAL_URL } from "../../app/config";
import { Link } from "react-router-dom";

const TimerPopup = ({ isTutorial, currentPage, setCurrentPage }) => {
  const [timer, setTimer] = useState(4);
  const [isClosed, setIsClosed] = useState(false);
  const [showCloseBtn, setShowCloseBtn] = useState(false);
  // useEffect(() => {
  // 	close();
  // }, []);

  // function close() {
  // 	setInterval(() => {
  // 		setTimer(timer - 1);
  // 	}, 1000);
  // 	setTimeout(() => closeModal(), timer * 1000);
  // }
  // const closeModal = () => {
  // 	setShowCloseBtn(true);
  // };
  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => setTimer(timer - 1), 1000);
    } else {
      setShowCloseBtn(true);
    }
  }, [timer]);

  return isClosed ? null : (
    <div className="modal-wrapper">
      <div className="modal-main">
        <div className="modal-title">
          {isTutorial === "tutorial" ? (
            <h2>
              <span style={{ color: "#e22b6d" }}>Please note</span> once you
              submit the biodata, you cannot change the following:
            </h2>
          ) : isTutorial === "contact" ? (
            <h3 style={{ color: "#e22b6d" }}>
              Under "Contact Information", use the email to communicate with
              your prospect.
            </h3>
          ) : (
            isTutorial === "pagination" && (
              <h3>
                Only <span style={{ color: "#e22b6d" }}>"Paid Users" </span> can
                view unlimited profiles.
              </h3>
            )
          )}
          {
            showCloseBtn ? (
              <button
                className="close-btn"
                onClick={() => {
                  setIsClosed(true);
                  setCurrentPage((prev) => prev - 1);
                }}
              >
                Close
              </button>
            ) : (
              <span
                style={{
                  padding: "10px 14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor: "#E22B6D",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {timer}
              </span>
            )

            // <div
            // 	className="timer-indicator"
            // 	style={{
            // 		border: "1px solid #e22b6d",
            // 		backgroundColor: "#ffa9c8",
            // 	}}
            // >
            // 	<span>{timer}</span>
            // </div>
          }
        </div>
        <div className="model-body">
          {isTutorial === "tutorial" ? (
            <>
              <h4>
                Personal Information, Contact Information, Educational
                Information, Parents & Siblings Information
              </h4>
              <a
                href={TUTORIAL_URL}
                target="_blank"
                rel="noreferrer"
                className="tutorial-btn"
              >
                <span>Tutorial</span>{" "}
                <AiOutlinePlayCircle style={{ fontSize: "30px" }} />
              </a>
            </>
          ) : isTutorial === "contact" ? (
            <h4 style={{ padding: "20px", color: "blue" }}>
              Check your email inbox on a regular basis to hear from your
              prospect.
            </h4>
          ) : (
            isTutorial === "pagination" && (
              <div>
                <h2>If you want to explore more. please purchase a package.</h2>
                <div style={{ marginTop: "20px" }}>
                  <Link to="/pricing" style={{ textDecoration: "none" }}>
                    Go to Packages
                  </Link>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default TimerPopup;
