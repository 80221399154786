import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	activeTab: 0,
};

const activeFormSlice = createSlice({
	name: "activeFormTab",
	initialState,
	reducers: {
		setActiveTab: (state, action) => {
			state.activeTab = action.payload;
		},
	},
});

const { reducer, actions } = activeFormSlice;

export const { setActiveTab } = actions;
export default reducer;
