import React from "react";
import "../Register/Register.css";
import "../Forget/Forget.css";

import forgotInLogo from "../../assets/img/digiGhotok.png";
import { Button, Col, Form, Input, Row } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AUTH } from "../../app/config";
import axios from "axios";

const ResetPassword = () => {
  const [getParams, setGetParams] = useSearchParams();
  const email = getParams.get("email");
  const token = getParams.get("token");
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    // e.preventDefault();
    if (values.password === values.confirmPassword) {
      console.log("Success:", values);
      axios
        .post(`${AUTH.RESET_PASSWORD}`, {
          email: email,
          token: token,
          password: values.password,
          password_confirmation: values.confirmPassword,
        })
        .then((res) => {
          console.log(res.data);
          navigate("/biodata", {
            replace: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Row className="forget" align="top" justify="center">
      <Col
        xl={6}
        md={12}
        xs={22}
        className="container py-5"
        style={{ paddingTop: "120px" }}
      >
        <Row
          align="middle"
          justify="center"
          className="wrapper_box"
          style={{
            width: "100%",
            backgroundColor: "#fff",
            textAlign: "center",
            padding: "50px 30px",
          }}
        >
          <Col
            span={24}
            className="col-md-12 text-center"
            style={{ textAlign: "center" }}
          >
            <img
              className="img-fluid"
              src={forgotInLogo}
              alt=""
              style={{ maxWidth: "200px", width: "90%" }}
            />
            <h2
              className="text-danger mt-4"
              style={{ margin: "20px 0", color: "#e91e63", fontSize: "25px" }}
            >
              Reset Password
            </h2>
          </Col>
          <Col span={24} className="col-md-12 mt-4">
            <Form
              name="basic"
              className="forgot_form register_form"
              wrapperCol={{
                span: 24,
              }}
              style={{
                maxWidth: 600,
                width: "100%",
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={handleSubmit}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Type New Password!",
                  },
                ]}
                style={{ marginBottom: "30px" }}
              >
                <Input.Password placeholder="Password" className="input" />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Re-type New Password!",
                  },
                ]}
                style={{ marginBottom: "30px" }}
              >
                <Input.Password
                  placeholder="Confirm Password"
                  className="input"
                />
              </Form.Item>

              <Form.Item
                // wrapperCol={{
                // 	offset: 8,
                // 	span: 16,
                // }}
                style={{ textAlign: "center" }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-btn"
                  style={{ backgroundColor: "#4096ff" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
            <div style={{ marginTop: "40px" }}>
              <Link to="/" style={{ color: "#e2286d" }}>
                Back to Home
              </Link>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ResetPassword;
