import React, { useState } from "react";
import "./Forget.css";
import forgotInLogo from "../../assets/img/digiGhotok.png";
import { Button, Col, Input, Row, message } from "antd";
import { Link } from "react-router-dom";
import { AUTH } from "../../app/config";
import axios from "axios";

const Forget = () => {
  const [email, setEmail] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    //api response exist to
    axios
      .post(`${AUTH.FORGET_PASSWORD}`, { email })
      .then((res) => {
        console.log(res.data);
        message.success("Please Check your Email");
      })
      .catch((err) => {
        console.log(err);
      });
    setSubmitted(true);
  };

  return (
    <Row className="forget" align="top" justify="center">
      <Col
        xl={6}
        md={12}
        xs={22}
        className="container py-5"
        style={{ paddingTop: "120px" }}
      >
        <Row
          align="middle"
          justify="center"
          className="wrapper_box"
          style={{
            width: "100%",
            backgroundColor: "#fff",
            textAlign: "center",
            padding: "50px 30px",
          }}
        >
          <Col
            span={24}
            className="col-md-12 text-center"
            style={{ textAlign: "center" }}
          >
            <img
              className="img-fluid"
              src={forgotInLogo}
              alt=""
              style={{ maxWidth: "200px", width: "90%" }}
            />
            <h2
              className="text-danger mt-4"
              style={{ margin: "20px 0", color: "#e91e63", fontSize: "25px" }}
            >
              Forgot Password
            </h2>
          </Col>
          <Col span={24} className="col-md-12 mt-4">
            {submitted ? (
              <div style={{ paddingTop: "20px" }}>
                <h3 style={{ color: "#494949" }}>
                  Please Check your Given Email
                </h3>
              </div>
            ) : (
              <form
                className="forgot_form"
                style={{ width: "100%" }}
                onSubmit={handleSubmit}
              >
                <div
                  className="mb-3"
                  style={{
                    width: "100%",
                    marginBottom: "15px",
                    padding: "10px 10px",
                  }}
                >
                  <Input
                    required
                    type="email"
                    className="form-control forget-form-input"
                    id="exampleInputEmail1"
                    placeholder="Email"
                    style={{ width: "90%", margin: "0 auto" }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div
                  className="text-center mt-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    style={{
                      padding: "10px 25px",
                      boxShadow: "3px 3px 10px #adadad",
                      height: "auto",
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            )}
            <div style={{ marginTop: "40px" }}>
              <Link to="/" style={{ color: "#e2286d" }}>
                Back to Home
              </Link>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Forget;
