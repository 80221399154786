import React from "react";
import Analytics from "../components/Analytics/Analytics";

const AnalyticsPage = () => {
	return (
		<div>
			<Analytics />
		</div>
	);
};

export default AnalyticsPage;
